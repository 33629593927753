import React from 'react'
import { Button, Card, Col, DatePicker, Descriptions, Form, Input, Modal, Progress, Row, Select, Typography } from 'antd'
import { DataTable, Spinner, Uploader } from '../../../../components'
import moment from 'moment'

const { Option } = Select
const { Text, Title } = Typography

const ModalEditProgressTask = (props) => {
    const {
        title,
        visible,
        onCancel,
        initialValues,
        onFinish,
        ticketInfo,
        isTicketPresales,
        changeCategory,
        defaultStat,
        attachmentInfo,
        taskProgressData,
        onClickAddProgress,
        onClickShowModalAddProgress,
        columnTaskProgress,
        percentageTask,
        myProgress,
        uploading,
        saving,
        showAttachmentFinished,
        multipleTaskTo,
    } = props

    const columns = [
        {
            title: 'Employee Name',
            render: (row) => (
                row?.task_to?.name
            ),
            key: 'employee'
        }
    ]

    return (
        <Modal centered title={title} width={1000} visible={visible} onCancel={onCancel} footer={null}>
            <Form layout='vertical' initialValues={initialValues} onFinish={onFinish}>
                {
                    isTicketPresales ?
                        <div className="mb-3">
                            <Descriptions title="Information" bordered>
                                <Descriptions.Item label="Ticket From">{ticketInfo.task_from?.name}</Descriptions.Item>
                                <Descriptions.Item label="Category Request">{ticketInfo?.category_request}</Descriptions.Item>
                                <Descriptions.Item label="Company">{ticketInfo?.company_name ?? "-"}</Descriptions.Item>
                                <Descriptions.Item label="SLA">{ticketInfo?.sla_level ?? "-"}</Descriptions.Item>
                                <Descriptions.Item label="Presales">{ticketInfo?.multiple_task_to[0]?.task_to.name ?? "-"}</Descriptions.Item>
                            </Descriptions>
                        </div> : null
                }
                <Form.Item name="all_app_task_id" hidden>
                    <Input placeholder="all_app_task_id" />
                </Form.Item>
                <Form.Item name="category_task_code" hidden>
                    <Input placeholder="category_task_code" />
                </Form.Item>
                <Form.Item name="emp_task_id" hidden>
                    <Input placeholder="ID" />
                </Form.Item>
                <Form.Item name="employee_code" hidden>
                    <Input placeholder="Employee Code" />
                </Form.Item>
                <Form.Item name="data_from" hidden>
                    <Input placeholder="Data From" />
                </Form.Item>
                <Form.Item name="milestone_id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="milestone_task_id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="start_date" hidden>
                    <DatePicker />
                </Form.Item>
                {
                    !isTicketPresales ?
                        <Form.Item name="category" label="Category" rules={[{ required: true }]}>
                            <Select placeholder="Select Category" disabled onChange={changeCategory}>
                                <Option value="WORKING LOCATION">WORKING LOCATION</Option>
                                <Option value="TASK/DAILY ACTIVITY">TASK/DAILY ACTIVITY</Option>
                            </Select>
                        </Form.Item> : null
                }
                {
                    isTicketPresales ?
                        <Form.Item name="description" label="Description Ticket">
                            <Input.TextArea rows={4} disabled />
                        </Form.Item> : null
                }
                {
                    !isTicketPresales ?
                        <Form.Item name="description" label="Description">
                            <Input.TextArea rows={4} disabled />
                        </Form.Item>
                        :
                        <Form.Item name="remarks_presales" label="Remarks Presales">
                            <Input.TextArea rows={4} disabled={defaultStat === 'show' ? true : false} />
                        </Form.Item>
                }
                <Form.Item label="Confirm Finished" name="confirm_finished">
                    <Select disabled>
                        <Option value="confirm">Only Confirm</Option>
                        <Option value="confirm_with_attachment">Confirm and Attachment</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Attachment Clause" name="attachment_clause" >
                    <Input.TextArea rows={4} disabled placeholder="Attachment Clause" />
                </Form.Item>
                {
                    defaultStat === 'show' ?
                        <Form.Item label="Remark Finished" name="remark_finished">
                            <Input.TextArea rows={4} disabled />
                        </Form.Item>
                        : null
                }

                <Form.Item>
                    {
                        attachmentInfo === null ?
                            <Button disabled onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${attachmentInfo}`)}>No Attachment</Button>
                            :
                            <Button onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${attachmentInfo}`)}>Show Attachment</Button>
                    }
                </Form.Item>

                <div className='mb-3'>
                    <div className='mb-2'>
                        <Title level={5}>Assign To</Title>
                    </div>
                    <DataTable bordered size="small" dataSource={multipleTaskTo} columns={columns} pagination={false} search={false} />
                </div>

                {
                    !isTicketPresales ?
                        <Card style={{ marginBottom: 10 }} title={
                            <Row justify='space-between'>
                                <Col>
                                    <Text>Task Progress</Text>
                                </Col>
                                <Col>
                                    {
                                        defaultStat === 'show' ?
                                            showAttachmentFinished === null ? null :
                                                <Button type='primary' onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${showAttachmentFinished}`)}>Show Attachment</Button> :
                                            (taskProgressData.filter((el) => moment(el.progress_date).format("YYYY-MM-DD") === moment(Date.now()).format("YYYY-MM-DD"))).length === 0 ?
                                                <Button type="primary" onClick={onClickAddProgress}
                                                >Add Progress</Button>
                                                :
                                                myProgress === 100 ?
                                                    <Button disabled type="primary" onClick={onClickShowModalAddProgress}>Add Progress</Button>
                                                    :
                                                    <Button type="primary" onClick={onClickShowModalAddProgress}>Add Progress</Button>
                                    }
                                </Col>
                            </Row>
                        }>
                            <div className='mb-3'>
                                <DataTable size="small" columns={columnTaskProgress} dataSource={taskProgressData} bordered search={false} pagination={false} />
                            </div>
                            <div>
                                <Row>
                                    <Col span={4}>
                                        <Title level={5}>
                                            My Progress:
                                        </Title>
                                    </Col>
                                    {
                                        myProgress > 100 ?
                                            <Col span={6}>
                                                <Title level={5} style={{ color: "red" }}>
                                                    {myProgress}%
                                                </Title>
                                            </Col>
                                            :
                                            <Col span={8}>
                                                <Progress percent={myProgress} size="large" />
                                            </Col>
                                    }
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col span={4}>
                                        <Title level={5}>
                                            Total Progress:
                                        </Title>
                                    </Col>
                                    {
                                        percentageTask > 100 ?
                                            <Col span={6}>
                                                <Title level={5} style={{ color: "red" }}>
                                                    {percentageTask}%
                                                </Title>
                                            </Col>
                                            :
                                            <Col span={8}>
                                                <Progress percent={percentageTask} size="large" />
                                            </Col>
                                    }
                                </Row>
                            </div>
                        </Card> : null
                }
                {
                    (percentageTask === 100 || isTicketPresales) && defaultStat !== "show" ?
                        <Form.Item label="Attachment" name="attachment">
                            <Uploader folder={isTicketPresales ? "attachment-ticket-presales" : "attachment-progress"} onUploaded={(v) => props.fileUploaded(v)} isUploading={(v) => props.setUploading(v)} view={true} />
                        </Form.Item>
                        : null
                }
                <Row justify='end'>
                    <Col>
                        {
                            defaultStat === 'show' ? null :
                                uploading || !saving ?
                                    <Form.Item>
                                        <Button type='primary' htmlType='submit'>Save</Button>
                                    </Form.Item>
                                    :
                                    <Form.Item>
                                        <Button disabled type='primary' htmlType='submit'><Spinner /></Button>
                                    </Form.Item>
                        }
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default ModalEditProgressTask