import React, {useEffect, useState} from 'react';
import {Card, Typography,Button, message, Descriptions, Form, Select} from 'antd'
import {useParams} from 'react-router-dom'
import {PageTitle, Spinner, FormModal, DeleteButton} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {CalendarOutlined} from '@ant-design/icons'
import {toFullDate} from '../../../utils/dateHandler'

const {Text} = Typography
const Evaluator = () => {
    const {appraisalId} = useParams()
    const [appraisal, setAppraisal] = useState([])
    const [employees, setEmployees] = useState([])
    const [searching, setSearching] = useState(false)
    const [time, setTime] = useState(0)
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)

    const fetchAppraisal = (appraisalId) =>{
        setLoaded(false)
        SentralModel.action('Appraisal','getEvaluator',{'appraisal_id':appraisalId}, 0).then(res=>{
            setAppraisal(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchAppraisal(appraisalId)
    }, [appraisalId]);

    const addEvaluator = () =>{
        setDefaultValues({evaluator_code:''})
        setModalTitle('Tambah Evaluator')
        setFormModal(true)
    }

    const saveEvaluator = (v) => {
        setSaving(true)
        SentralModel.save('KpiAppraisalEvaluator',{kpi_appraisal_id:appraisalId, evaluator_code:v.evaluator_code}, 0).then(()=>{
            message.success('Evaluator berhasil ditambahkan', 2)
            setSaving(false)
            fetchAppraisal(appraisalId)
            setFormModal(false)
        }).catch(() => {
            setSaving(false)
        })
    }

    const searchEmployee = (v) =>{
        if(time) clearTimeout(time)
        if(v){
            setSearching(true)
            setTime(setTimeout(() => {
                SentralModel.list('Employee', {filter:[['name','like','%'+v+'%']],fields:'employee_code,name'}).then((res)=>{
                    setEmployees(res.data)
                    setSearching(false)
                })
            }, 500))
        }else{
            setEmployees([])
        }
    }

    const removeEvaluator = (id) => {
        SentralModel.action('Appraisal','removeEvaluator', {id:id}).then((res)=>{
            message.success('Evaluator berhasil dihapus')
            fetchAppraisal(appraisalId)
        })
    }
    
    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Evaluator" breadcrumbs={[['Appraisal','/management/appraisal'],['Setup Evaluator']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Evaluator List</Text>
                        <Button style={{float:'right'}} onClick={addEvaluator} type="primary" icon={<CalendarOutlined />}>
                            Add Evaluator
                        </Button>
                    </div> }>

                    {
                        (loaded) ?
                        <div>
                            <Descriptions className="mb-3" layout="horizontal" column={1} bordered size="small" style={{width:"500px"}}> 
                                <Descriptions.Item label="Name">{appraisal.employee.name}</Descriptions.Item>
                                <Descriptions.Item label="Division">{appraisal.employee.division.division_name}</Descriptions.Item>
                                <Descriptions.Item label="Position">{appraisal.employee.position.position_name || ''}</Descriptions.Item>
                                <Descriptions.Item label="Join Date">{toFullDate(appraisal.employee.joinDate)}</Descriptions.Item>
                            </Descriptions>
                            <table className="table-collapse table-default" style={{width:'100%'}}>
                                <thead>
                                    <tr>
                                        <th style={{width:'50px'}} className="text-center">No</th>
                                        <th>Name</th>
                                        <th className="text-center" style={{width:'20%'}}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        appraisal.evaluators.map((el, i)=>(
                                            <tr key={i}>
                                                <td className="text-center">{i + 1}</td>
                                                <td>
                                                    {el.employee.name}
                                                </td>
                                                <td className="text-center">
                                                    <DeleteButton onConfirm={() => removeEvaluator(el.kpi_appraisal_evaluator_id)}/>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </table>
                        </div>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveEvaluator(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item label="Evaluator" name="evaluator_code" required rules={[{required:true, message:'Evaluator harus dipilih'}]}>
                        <Select
                            showSearch
                            placeholder="Ketik untuk mencari"
                            defaultActiveFirstOption={false}
                            showArrow={true}
                            loading={searching}
                            filterOption={false}
                            onSearch={searchEmployee}
                            notFoundContent={<span>Employee Not Found</span>}>
                                {
                                    employees.map((el,i) =>(
                                        <Select.Option key={i} value={el.employee_code}>{el.name}</Select.Option>
                                    ))
                                }
                        </Select>
                        </Form.Item>
                    </FormModal>
                    : null
                }
            </Card>
        </div>
    );
}

export default Evaluator;
