import { FormModal } from '../../../../components'
import { Col, Form, Input, Row } from 'antd'

const ModalCompany = (props) => {
    const { form, titleModalCompany, submitAddCompany, showModalCompany, showSetModalCompany } = props

    return (
        <FormModal form={form} title={titleModalCompany} submitForm={(v) => submitAddCompany(v)} width={600} showModal={showModalCompany} onCloseModal={() => showSetModalCompany(false)} >
            <Form.Item label="Company Name" name="company_name" required rules={[{ required: true }]}>
                <Input placeholder='Company Name' />
            </Form.Item>
            <Form.Item label="Company Email" name="email" required rules={[{ required: true }]}>
                <Input type='email' placeholder='Company Email' />
            </Form.Item>
            <Row justify='space-between'>
                <Col span={11}>
                    <Form.Item label="Phone 1" name="phone_no">
                        <Input placeholder='Phone 1' />
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Form.Item label="Phone 2" name="phone_no_2">
                        <Input placeholder='Phone 2' />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label="Address" name="address" required rules={[{ required: true }]}>
                <Input placeholder='Address' />
            </Form.Item>
        </FormModal>
    )
}

export default ModalCompany