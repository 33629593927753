import React, { useEffect, useState } from 'react';
import { Card, Typography, Descriptions, Row, Select, Space, message } from 'antd'
import { PageTitle, Spinner, DataTable, NewButton, EditButton, DeleteButton } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { checkRole } from '../../../helpers/roleData'

const { Text } = Typography
const { Option } = Select

const Index = () => {
    const [isos, setIsos] = useState([]);
    const [title, setTitle] = useState([]);
    const [count, setCount] = useState([]);
    const [company, setCompany] = useState([]);
    const [division, setDivision] = useState([]);
    const [category, setCategory] = useState([]);
    const [classification, setClassification] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [companyName, setCompanyName] = useState(null);
    const [divisionName, setDivisionName] = useState(null);
    const [isoClassification, setIsoClassification] = useState(null);
    const [isoCategory, setIsoCategory] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(0);
    const [selectedDivision, setSelectedDivision] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedClassification, setSelectedClassification] = useState(0);
    const [selectedTitle, setSelectedTitle] = useState(null);

    const fetchCompany = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ISOP']], orderBy: 'order_key', order: 'asc' }).then(res => {
            setCompany(res.data)
        }).finally(() => {
            // setLoaded(true)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ISOD']], orderBy: 'order_key', order: 'asc' }).then(res => {
            setDivision(res.data)
        }).finally(() => {
            // setLoaded(true)
        })
    }

    const fetchCategory = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ISOC']], orderBy: 'order_key', order: 'asc' }).then(res => {
            setCategory(res.data)
        }).finally(() => {
            // setLoaded(true)
        })
    }

    const fetchClassification = () => {
        SentralModel.list('DocumentIsoClassification', { orderBy: 'document_iso_classification_name', order: 'asc' }).then(res => {
            setClassification(res.data)
        }).finally(() => {
            // setLoaded(true)
        })
    }

    const fetchIsos = (selectedDivision, selectedCategory, selectedCompany, selectedClassification) => {
        SentralModel.action('Documentation', 'getIso', { iso_division: selectedDivision, iso_category: selectedCategory, iso_company: selectedCompany, iso_classification: selectedClassification, doc_iso_id: null }, 0).then(res => {
            setIsos(res.message.isos)
            setTitle(res.message.isos)
            setCount(res.message.count)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchCompany()
        fetchDivision()
        fetchCategory()
        fetchClassification()
        fetchIsos(selectedDivision, selectedCategory, selectedCompany, selectedClassification)
    }, [selectedDivision, selectedCategory, selectedCompany, selectedClassification]);

    const dataSource = [
        ...isos.map(el => {
            return {
                id: el.doc_iso_id,
                iso_company: el.iso_company,
                iso_division: el.iso_division,
                iso_category: el.iso_category,
                document_no: el.document_no,
                iso_classification: el.iso_classification,
                title: el.title,
                issued_date: el.issued_date,
                iso: el.iso,
                attachment: el.attachment
            }
        })
    ];

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        key: 'name',
    },
    {
        title: 'Company',
        dataIndex: 'iso_company',
        key: 'iso_company'
    },
    {
        title: 'Department',
        dataIndex: 'iso_division',
        key: 'iso_division'
    },
    {
        title: 'Category',
        dataIndex: 'iso_category',
        key: 'iso_category'
    },
    {
        title: 'Document No',
        dataIndex: 'document_no',
        key: 'document_no'
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title'
    },
    {
        title: 'Issued Date',
        dataIndex: 'issued_date',
        key: 'issued_date'
    },
    {
        title: 'ISO',
        dataIndex: 'iso',
        key: 'iso'
    },
    {
        title: 'Classification',
        dataIndex: 'iso_classification',
        key: 'iso_classification'
    },
    {
        title: 'Attachment',
        render: (row) => (
            row.attachment ?
                <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + row.attachment}>
                    Show Attachment
                </a>
                : '-'
            // <div className="text-center">
            //     {text}
            // </div>
        )
    },
    ];

    if (checkRole('ADMIN') || checkRole('DOC')) {
        columns.push({
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton link to={"/documentation/sop/detail/" + row.id} />
                        <DeleteButton onConfirm={() => deleteSop(row.id)} />
                    </Space>
                </div>
            )
        })
    }

    const deleteSop = (id) => {
        message.loading('deleting sop')
        SentralModel.deleteById('DocumentIso', id).then((res) => {
            message.destroy()
            message.success('Sop deleted', 2)
            let tmp = isos
            let index = tmp.findIndex(el => el.doc_iso_id === id)
            tmp.splice(index, 1);
            setIsos([])
            setIsos(tmp)
        })
    }

    const changeCompany = e => {
        if (!e) {
            e = 0;
        }

        SentralModel.action('Documentation', 'getIso', { iso_company: e, iso_division: selectedDivision, iso_category: selectedCategory, iso_classification: selectedClassification, doc_iso_id: null }, 0).then(res => {
            // console.log(data)
            setIsos(res.message.isos)
            setTitle(res.message.isos)
            setCount(res.message.count)

            setLoaded(true)
            setSelectedCompany(e);
            e !== 0 ? setCompanyName(company.find(el => el.detail_code === e).description) : setCompanyName(null);
        }).catch((err) => {
            console.log(err)
        })
    }

    const changeDivision = e => {
        if (!e) {
            e = 0;
        }
        SentralModel.action('Documentation', 'getIso', { iso_company: selectedCompany, iso_division: e, iso_category: selectedCategory, iso_classification: selectedClassification, doc_iso_id: null }, 0).then(res => {
            setIsos(res.message.isos)
            setTitle(res.message.isos)
            setCount(res.message.count)
            setLoaded(true)
            setSelectedDivision(e);
            e !== 0 ? setDivisionName(division.find(el => el.detail_code === e).description) : setDivisionName(null);
        }).catch((err) => {
            console.log(err)
        })
    }

    const changeCategory = e => {
        if (!e) {
            e = 0;
        }
        SentralModel.action('Documentation', 'getIso', { iso_company: selectedCompany, iso_division: selectedDivision, iso_category: e, iso_classification: selectedClassification, doc_iso_id: null }, 0).then(res => {
            setIsos(res.message.isos)
            setTitle(res.message.isos)
            setCount(res.message.count)

            setLoaded(true)
            setSelectedCategory(e);
            e !== 0 ? setIsoCategory(category.find(el => el.detail_code === e).description) : setIsoCategory(null);
        }).catch((err) => {
            console.log(err)
        })
    }

    const changeClassification = e => {
        if (!e) {
            e = 0;
        }
        SentralModel.action('Documentation', 'getIso', { iso_company: selectedCompany, iso_division: selectedDivision, iso_category: selectedCategory, iso_classification: e, doc_iso_id: null }, 0).then(res => {
            setIsos(res.message.isos)
            setTitle(res.message.isos)
            setCount(res.message.count)

            setLoaded(true)
            setSelectedClassification(e);
            e !== 0 ? setIsoClassification(classification.find(el => el.document_iso_classification_id === e).document_iso_classification_name) : setIsoClassification(null);
        }).catch((err) => {
            console.log(err)
        })
    }

    const changeTitle = e => {
        if (!e) {
            e = 0;
        }
        SentralModel.action('Documentation', 'getIso', { iso_company: selectedCompany, iso_division: selectedDivision, iso_category: selectedCategory, iso_classification: selectedClassification, doc_iso_id: e }, 0).then(res => {
            setIsos(res.message.isos)
            setCount(res.message.count)
        }).finally(() => {
            setLoaded(true)
        })
        e !== 0 ? setSelectedTitle(title.find(el => el.doc_iso_id === e).title) : setSelectedTitle(null);
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="SOP" breadcrumbs={[['SOP']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>SOP List</Text>
                        {
                            checkRole('ADMIN') || checkRole('DOC') ?
                                <NewButton right to="/documentation/sop/detail/0" title="New SOP" />
                                : null
                        }
                    </div>
                }>

                    {
                        (loaded) ?
                            <div>
                                <Card style={{ verticalAlign: 'center', marginLeft: 100, marginRight: 100 }} bordered={false}>
                                    <Row justify="space-around">
                                        <Select showSearch allowClear size="large" style={{ width: "15%" }} placeholder="Select Company" optionFilterProp="children" onChange={changeCompany} value={companyName} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                company.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                        <Select showSearch allowClear size="large" style={{ width: "15%" }} placeholder="Select Department" optionFilterProp="children" onChange={changeDivision} value={divisionName} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                division.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                        <Select showSearch allowClear size="large" style={{ width: "15%" }} placeholder="Select Category" optionFilterProp="children" onChange={changeCategory} value={isoCategory} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                category.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                        <Select showSearch allowClear size="large" style={{ width: "15%" }} placeholder="Select Classification" optionFilterProp="children" onChange={changeClassification} value={isoClassification} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                classification.map((el, key) => (
                                                    <Option key={key} value={el.document_iso_classification_id}>{el.document_iso_classification_name}</Option>
                                                ))
                                            }
                                        </Select>
                                        <Select showSearch allowClear size="large" style={{ width: "15%" }} placeholder="Select Title" optionFilterProp="children" onChange={changeTitle} value={selectedTitle} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                title.map((el, key) => (
                                                    <Option key={key} value={el.doc_iso_id}>{el.title}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Row>
                                </Card>

                                <Card style={{ verticalAlign: 'center', marginLeft: 200, marginRight: 200 }} bordered={false}>
                                    <Descriptions className="mb-4" size="middle" column={2} bordered layout="vertical">
                                        <Descriptions.Item label={<div className="text-center"><Text strong>Department Name</Text></div>}>
                                            <div className="text-center">{selectedDivision === 0 ? 'All Department' : divisionName}</div>
                                        </Descriptions.Item>
                                        <Descriptions.Item label={<div className="text-center"><Text strong>Document</Text></div>}>
                                            <Row justify="space-around">
                                                {
                                                    title.length === 0 ?
                                                        <>
                                                            <div className="text-center">SOP : 0</div>
                                                            <div className="text-center">WI : 0</div>
                                                            <div className="text-center">FORM : 0</div>
                                                        </> :
                                                        <>
                                                            {count.find(element => element.iso_category === 'SOP') ? <div className="text-center">SOP : {count.find(element => element.iso_category === 'SOP').total}</div> : null}
                                                            {count.find(element => element.iso_category === 'WI') ? <div className="text-center">WI : {count.find(element => element.iso_category === 'WI').total}</div> : null}
                                                            {count.find(element => element.iso_category === 'FORM') ? <div className="text-center">FORM : {count.find(element => element.iso_category === 'FORM').total}</div> : null}
                                                        </>
                                                }
                                            </Row>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Card>

                                <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            </div>
                            : <Card bordered={false}><Spinner /></Card>
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
