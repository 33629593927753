import React, {useEffect, useState} from 'react';
import {Card, Tabs, /*Space, message*/} from 'antd'
import {PageTitle, Spinner, /*EditButton, DeleteButton, DataTable*/} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
// import moment from 'moment'
// import authData from '../../../helpers/authData'

import Objective from './objective/Index'
import SupportTask from './support/Index'

const {TabPane} = Tabs;

const Index = () => {
    // const [crfs, setCRF] = useState([])
    const [supports, setSupports] = useState([])
    const [objectives, setObjectives] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [activeTab, setActiveTab] = useState('project');

    // const fetchChangeRequestForms = () => {
    //     SentralModel.list('ChangeRequestForm', {filter: [authData.division_id !== 24 ? ['division_id', authData.division_id] : []], orderBy: 'change_request_form_code', order: 'desc'}).then(res=>{
    //         setLoaded(false)
    //         setCRF(res.data)
    //     }).finally(()=>{
    //         setLoaded(true)
    //     })
    // }

    const fetchSupportTasks = () => {
        SentralModel.list('SupportTask', {orderBy: 'support_task_id', order: 'desc'}).then(res => {
            setLoaded(false)
            setSupports(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchObjectives = () => {
        SentralModel.list('Objective', {orderBy: 'year', order: 'asc'}).then(res => {
            setLoaded(false)
            setObjectives(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        // fetchChangeRequestForms()
        fetchSupportTasks()
        fetchObjectives()
    }, []);
    
    // const dataSourceCrf = [
    //     ...crfs.map( el => {
    //         return {
    //             id: el.change_request_form_id,
    //             requester_name: el.employee.name,
    //             crf_code: el.change_request_form_code,
    //             email: el.employee.work_email,
    //             extension_no: el.employee.extension_no,
    //             request_date: el.request_date,
    //             status: el.status
    //         }
    //     })
    // ];

    // const columnCrf = [
    //     {
    //         title: 'No',
    //         dataIndex: 'no',
    //         align:'center',
    //         key: 'no',
    //     },
    //     {
    //         title: 'Requester Name',
    //         dataIndex: 'requester_name',
    //         key: 'requester_name',
    //     },
    //     {
    //         title: 'CRF Code',
    //         dataIndex: 'crf_code',
    //         key: 'crf_code',
    //     },
    //     {
    //         title: 'Email / Ext',
    //         render: (row) => (
    //             <div>
    //                 {row.email} / {row.extension_no}
    //             </div>
    //         )
    //     },

    //     {
    //         title: 'Request Date',
    //         dataIndex: 'request_date',
    //         key: 'request_date',
    //         render: (text) => (
    //             <div className="text-center">
    //                 {moment(text).format('DD MMMM YYYY')}
    //             </div>
    //         )
    //     },
    //     {
    //         title: 'Action',
    //         width:120,
    //         render:(row) =>(
    //             <div className="text-center">
    //                 <Space>
    //                     <EditButton link to={"/crf/detail/" + row.id}/>
    //                     <DeleteButton onConfirm={() => deleteCRF(row.id)}/>
    //                 </Space>
    //             </div>
    //         )
    //     },
    // ];

    // const deleteCRF = (id) =>{
    //     message.loading('deleting crf')
    //     SentralModel.deleteById('ChangeRequestForm', id).then((res)=>{
    //         message.destroy()
    //         message.success('crf deleted', 2)
    //         let tmp = crfs
    //         let index = tmp.findIndex(el => el.change_request_form_id===id)
    //         tmp.splice(index, 1);
    //         setCRF([])
    //         setCRF(tmp)
    //     })
    // }

    const changeTab = e => {
        setActiveTab(e)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Software Development" breadcrumbs={[['Software Development']]}></PageTitle>
                    {
                        loaded ?
                        <Tabs defaultActiveKey={activeTab} type="card" onChange={changeTab}>
                            {/* <TabPane tab={<span>Projects</span>} key="project">
                                <DataTable size="small" columns={columnCrf} dataSource={dataSourceCrf.filter(el => el.status === 'WAITING')} bordered/>
                            </TabPane> */}
                            <TabPane tab={<span>Support Tasks</span>} key="support">
                                <SupportTask data={supports} onFetch={() => fetchSupportTasks()}/>
                            </TabPane>
                            {/* <TabPane tab={<span>Complainments</span>} key="complaint">
                                <DataTable size="small" columns={columnCrf} dataSource={dataSourceCrf.filter(el => el.status === 'ONPROGRESS')} bordered/>
                            </TabPane> */}
                            <TabPane tab={<span>Objectives</span>} key="objective">
                                <Objective data={objectives} onFetch={() => fetchObjectives()}/>
                            </TabPane>
                            {/* <TabPane tab={<span>Daily Progress</span>} key="daily">
                                <DataTable size="small" columns={columnCrf} dataSource={dataSourceCrf.filter(el => el.status === 'FINISH')} bordered/>
                            </TabPane>
                            <TabPane tab={<span>Dashboard</span>} key="dashboard">
                                <DataTable size="small" columns={columnCrf} dataSource={dataSourceCrf.filter(el => el.status === 'CLOSE')} bordered/>
                            </TabPane> */}
                        </Tabs>
                    : <Spinner />
                    }
            </Card>
        </div>
    );
}

export default Index;
