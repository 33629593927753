import React, {useEffect, useState} from 'react';
import {Card, Form, Row, Col, Input, DatePicker, Select, message} from 'antd';
import {PageTitle, SubmitButton, Uploader, Spinner} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {useHistory, useParams} from 'react-router-dom'
import moment from 'moment'

const {Option} = Select;

const Detail = () => {
    const history = useHistory();
    const {sopId} = useParams();
    const [loaded, setLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [formSop] = Form.useForm();
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [uploading, setUploading] = useState(false);
    const [classification, setClassification] = useState([]);
    const [company, setCompany] = useState([]);
    const [division, setDivision] = useState([]);
    const [category, setCategory] = useState([]);
    const [standard, setStandard] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedClassification, setSelectedClassification] = useState(null);
    const [selectedIso, setSelectedIso] = useState([]);

    const fetchIsos = (sopId, formSop) => {
        setLoaded(false)
        if (sopId === '0') {
            formSop.resetFields();
        } else {
            SentralModel.list('DocumentIso', {filter: [['doc_iso_id', sopId]]}).then(res=>{
                formSop.setFieldsValue({
                    doc_iso_id: res.data[0].doc_iso_id,
                    iso_classification: res.data[0].doc_iso_classification_id,
                    iso_company: res.data[0].iso_company,
                    iso_division: res.data[0].iso_division,
                    iso_category: res.data[0].iso_category,
                    document_no: res.data[0].document_no,
                    title: res.data[0].title,
                    iso: res.data[0].standards.map((el) => el.standard),
                    issued_date: moment(res.data[0].issued_date, 'YYYY-MM-DD'),
                });
                setSelectedCompany(res.data[0].iso_company);
                setSelectedClassification(res.data[0].doc_iso_classification_id);
                setSelectedDivision(res.data[0].iso_division);
                setSelectedCategory(res.data[0].iso_category);
                setSelectedIso(res.data[0].standards.map((el) => el.standard));
            })
        }
        setLoaded(true)
    }

    const fetchClassification = () =>{
        SentralModel.list('DocumentIsoClassification', {orderBy:'document_iso_classification_name', order:'asc'}).then(res=>{
            setClassification(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchCompany = () =>{
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'ISOP']], orderBy:'order_key', order:'asc'}).then(res=>{
            setCompany(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }
    
    const fetchDivision = () =>{
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'ISOD']], orderBy:'order_key', order:'asc'}).then(res=>{
            setDivision(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchCategory = () =>{
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'ISOC']], orderBy:'order_key', order:'asc'}).then(res=>{
            setCategory(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchStandard = () =>{
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'ISO']], orderBy:'order_key', order:'asc'}).then(res=>{
            setStandard(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchIsos(sopId, formSop);
        fetchClassification();
        fetchCompany();
        fetchDivision();
        fetchCategory();
        fetchStandard();
    }, [sopId, formSop]);
    
    const saveIso = (v) => {
        setSaving(true)

        let data = {
            doc_iso_id: sopId,
            iso_company: selectedCompany,
            iso_division: selectedDivision,
            iso_category: selectedCategory,
            iso_classification: selectedClassification,
            document_no: v.document_no,
            title: v.title,
            issued_date: moment(v.issued_date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            attachment: attachmentFile,
            standards: selectedIso
        }

        SentralModel.action('Documentation', 'saveIso', data, sopId).then((res)=>{
            message.success('Sop saved', 2);
            setSaving(false)
            setTimeout(() => {
                history.push('/documentation/sop');
            }, 1000);
        })
    }

    const fileUploaded = (v) => {
        setAttachmentFile(v.message);
    }

    const changeClassification = e => {
        setSelectedClassification(e);
    }

    const changeCompany = e => {
        setSelectedCompany(e);
    }

    const changeDivision = e => {
        setSelectedDivision(e);
    }

    const changeCategory = e => {
        setSelectedCategory(e);
    }

    const changeStandard = e => {
        setSelectedIso(e);
    }
    
    return (
        <Card className="content-container">
            <PageTitle title="SOP" breadcrumbs={[['SOP','/documentation/sop'],['Detail']]}></PageTitle>
            {
                loaded ?
                <Form size="middle" form={formSop} layout="vertical" onFinish={saveIso}>
                    <Form.Item name="sop_id" hidden>
                        <Input placeholder="SOP ID" />
                    </Form.Item>
                    <Row justify="center">
                        <Col span={20}>
                            <Row justify="space-around">
                                <Col span={10}>
                                    <Form.Item label="Classification" name="iso_classification" required rules={[{required:true}]}>
                                        <Select showSearch style={{ width: "100%" }} placeholder="Select Classification" optionFilterProp="children" onChange={changeClassification} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                classification.map((el, key) => (
                                                    <Option key={key} value={el.document_iso_classification_id}>{el.document_iso_classification_name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item label="Company" name="iso_company" required rules={[{required:true}]}>
                                        <Select showSearch style={{ width: "100%" }} placeholder="Select Company" optionFilterProp="children" onChange={changeCompany} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                company.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="space-around">
                                <Col span={10}>
                                    <Form.Item label="Department" name="iso_division" required rules={[{required:true}]}>
                                        <Select showSearch style={{ width: "100%" }} placeholder="Select Department" optionFilterProp="children" onChange={changeDivision} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                division.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item label="Category" name="iso_category" required rules={[{required:true}]}>
                                        <Select showSearch style={{ width: "100%" }} placeholder="Select Category" optionFilterProp="children" onChange={changeCategory} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                category.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="space-around">
                                <Col span={10}>
                                    <Form.Item label="Document No" name="document_no" required rules={[{required:true}]}>
                                        <Input placeholder="Document No" />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item label="Title" name="title" required rules={[{required:true}]}>
                                        <Input placeholder="Title" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-around'>
                                <Col span={10}>
                                    <Form.Item label="Issued Date" name="issued_date" required rules={[{required:true}]}>
                                        <DatePicker style={{width:'100%'}}/>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item label="ISO" name="iso" required rules={[{required:true}]}>
                                        <Select mode="multiple" showSearch style={{ width: "100%" }} placeholder="Select ISO" optionFilterProp="children" onChange={changeStandard} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                standard.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="space-around">
                                <Col span={10}>
                                    <Form.Item label="Attachment">
                                        <Uploader dragger folder="iso" accept={'.png,.jpg,.jpeg,.pdf,.doc,.docx,.xls,.xlsx,.ppt'} onUploaded={(v)=> fileUploaded(v)} isUploading={(v)=> setUploading(v)}/>
                                    </Form.Item>
                                </Col>
                                <Col span={10} className="text-right">
                                    <SubmitButton loading={saving || uploading}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                : <Spinner />
            }
        </Card>
    );
}

export default Detail;
