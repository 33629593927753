import React, {useEffect, useState} from 'react'
import {Row, Typography, Divider, Empty, Form, Input, DatePicker, Switch, message} from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import {AddButton, FormModal, Spinner, Uploader} from '../../components';
import {SentralModel} from '../../models/SentralModel'
import {ImageViewer} from '../../components/ImageViewer';
import moment from 'moment'

const {Title} = Typography
const Swab = (props) => {
    const [empSwabHistories, setEmpSwabHistories] = useState(props.data);
    const [loaded, setLoaded] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle]= useState(null);
    const [status, setStatus] = useState(true);
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [uploading, setUploading] = useState(false)

    const fetchEmpSwabHistories = () =>{
        setLoaded(false)
        SentralModel.list('EmployeeSwabHistory', {filter: [['employee_code', props.emp_code]]}).then(res=>{
            setEmpSwabHistories(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        setLoaded(true)
    }, []);

    const addSwab=()=>{
        setModalTitle('Add Swab/PCR History')
        setDefaultValues({
            emp_swab_history_id: 0,
            status: false,
        })
        setStatus(false)
        setFormModal(true)
    }

    const editSwab = (v) =>{
        setModalTitle('Edit Swab/PCR History')
        setDefaultValues({
            emp_swab_history_id: v.emp_swab_history_id,
            laboratorium: v.laboratorium,
            date: moment(v.date, 'YYYY-MM-DD'),
            status: parseInt(v.status),
            attachment: v.attachment
        })
        setStatus(v.status)
        setFormModal(true)
    }

    const saveSwab = (v) => {
        setSaving(true)
        let payload={
            emp_swab_history_id: v.emp_swab_history_id,
            employee_code: props.emp_code,
            laboratorium: v.laboratorium,
            date: moment(v.date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            status: status,
            attachment: attachmentFile
        }
        SentralModel.save('EmployeeSwabHistory', payload, v.emp_swab_history_id).then((res)=>{
            setFormModal(false)
            fetchEmpSwabHistories()
            message.success('Swab/PCR history saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteSwab = (id) =>{
        message.loading('deleting swab history')
        SentralModel.deleteById('EmployeeSwabHistory', id).then((res)=>{
            message.destroy()
            message.success('Swab/PCR history deleted', 2)
            let tmp = empSwabHistories
            let index = tmp.findIndex(el => el.emp_swab_history_id===id)
            tmp.splice(index, 1);
            setEmpSwabHistories([])
            setEmpSwabHistories(tmp)
        })
    }

    const changeStatus = e => {
        setStatus(e)
    }

    const fileUploaded = (v) => {
        setAttachmentFile(v.message)
    }
    
    return (
        <div>
            <div className="text-right">
                <AddButton onAdd={addSwab}>Add data</AddButton>
            </div>
            {
                loaded ?
                    (empSwabHistories.length>0) ?
                        empSwabHistories.map((el, key) => (
                            <div key={key}>
                                <Divider orientation="left"><Title level={4}>{key+1}.</Title></Divider>
                                <ActionButton editable deleteable onEdit={() => editSwab(el)} onDelete={() => deleteSwab(el.emp_swab_history_id)}/>
                                <Row>
                                    <ProfileItem span={8} title="Laboratorium / Hospital">{el.laboratorium}</ProfileItem>
                                    <ProfileItem span={8} title="Date">{moment(el.date).format('DD MMMM YYYY')}</ProfileItem>
                                    <ProfileItem span={8} title="Status">{el.status === '1' ? 'Positive' : 'Negative'}</ProfileItem>
                                    <ProfileItem image span={8} title="Attachment">
                                        <div style={{width:200, maxWidth:'100%', border:'1px solid #ededed'}}>
                                            {
                                                el.attachment !== null ?
                                                    el.attachment.includes('.pdf') ?
                                                    <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + el.attachment}>
                                                        Show Attachment
                                                    </a> :
                                                    <ImageViewer previewable={el.attachment ? true : false} src={process.env.REACT_APP_API_FILE + "/" + el.attachment} alt="attachment"/> :
                                                <ImageViewer previewable={el.attachment ? true : false} src={process.env.PUBLIC_URL + '/images/noimage.png'} alt="attachment"/>
                                            }
                                        </div>
                                    </ProfileItem>
                                </Row>
                            </div>
                        ))
                    : <Empty/>
                : <Spinner />
            }
            {
                (formModal) ?
                <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveSwab(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving} loading={uploading}>
                    <Form.Item name="emp_swab_history_id" hidden>
                        <Input placeholder="input placeholder" />
                    </Form.Item>
                    <Form.Item label="Laboratorium / Hospital" name="laboratorium" rules={[{required:true}]}>
                        <Input placeholder="Laboratorium / Hospital" />
                    </Form.Item>
                    <Form.Item label="Date" name="date" rules={[{required:true}]}>
                        <DatePicker format="DD MMMM YYYY" style={{width:'100%'}} />
                    </Form.Item>
                    <Form.Item label="Status" name="status" rules={[{required: true}]}>
                        <Switch checkedChildren="Positive" unCheckedChildren="Negative" onChange={changeStatus} defaultChecked={defaultValues.status} />
                    </Form.Item>
                    <Form.Item label="Attachment" name="swab-test" rules={[{required:false}]}>
                        <Uploader dragger folder="swab-test" onUploaded={(v)=> fileUploaded(v)} isUploading={(v)=> setUploading(v)}/>
                    </Form.Item>
                </FormModal> :
                null
            }
        </div>
    );
}

export default Swab;
