import React, { useEffect, useState } from 'react';
import { Card, Typography, Space, Tabs, Row, Col } from 'antd'
import { PageTitle, Spinner, DataTable, ShowButton, CustomButton, NewButton } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { toFullDate } from '../../../utils/dateHandler'
import { DiffOutlined, ProfileTwoTone, CalendarTwoTone, SnippetsTwoTone, CheckCircleTwoTone } from '@ant-design/icons'
import authData from '../../../helpers/authData'
import moment from 'moment'

const { TabPane } = Tabs

const { Text } = Typography
const Index = () => {
    const [trainings, setTrainings] = useState([]);
    const [trainingExternals, setTrainingExternals] = useState([]);
    const [joined, setJoined] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [instruction, setInstruction] = useState('');
    const [tab, setTab] = useState('in');

    const fetchTrainings = () => {
        setLoaded(false)
        SentralModel.action('Training', 'trainingList', {}, 0).then(res => {
            setTrainings(res.data.list)
            setJoined(res.data.joined)

            // let test = res.data.joined.find((el) => el.training_id === 6);
            // console.log(test.participants.find((el) => el.employee_code === authData.employee_code).is_evaluation)
        }).finally(() => {
            setLoaded(true)
        })
    }
    const fetchTrainingExternals = () => {
        setLoaded(false)
        SentralModel.list('TrainingExternal', { filter: [['employee_code', authData.employee_code]], orderBy: "date", order: "DESC" }, null).then((res) => {
            setTrainingExternals(res.data)
            setLoaded(true)
        })
    }
    const fetchInstruction = () => {
        SentralModel.list('Instruction', { filter: [['code', 'SBTS']] }, null).then((res) => {
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    useEffect(() => {
        fetchTrainings()
        fetchTrainingExternals()
        fetchInstruction()
    }, []);

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'no',
        width: 40,
    },
    {
        title: 'Judul',
        dataIndex: 'title',
        key: 'title',
    }, {
        title: 'Sertifikat',
        align: 'center',
        width: 50,
        render: (row) => (
            row.certificate ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : '-'
        ),
    }, {
        title: 'Date',
        width: 160,
        render: (row) => (
            <div className="text-center">
                {toFullDate(row.training_date)}
            </div>
        ),
    }, {
        title: 'Time',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <span>{moment(row.start_time, 'HH:mm:ss').format('HH:mm')} - {moment(row.end_time, 'HH:mm:ss').format('HH:mm')}</span>
            </div>
        ),
    }, {
        title: 'Action',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <Space>
                    <ShowButton link to={'/request-training/detail/' + row.training_id} />
                    {
                        (tab === 'joined' && row.participants?.find((el) => el.employee_code === authData.employee_code)?.is_evaluation === null) ?
                            <CustomButton icon={<DiffOutlined />} link to={'/request-training/evaluation/' + row.training_id} />
                            : null
                    }
                </Space>
            </div>
        ),
    },
    ];

    const externalColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: 40,
        }, {
            title: 'Judul',
            dataIndex: 'title',
            key: 'title',
        }, {
            title: 'Date',
            width: 160,
            render: (row) => (
                <div className="text-center">
                    {toFullDate(row.date)}
                </div>
            ),
        }, {
            title: 'Time',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <span>{moment(row.start_time, 'HH:mm:ss').format('HH:mm')} - {moment(row.end_time, 'HH:mm:ss').format('HH:mm')}</span>
                </div>
            ),
        }, {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ShowButton link to={'/request-training/external-detail/' + row.training_external_id} />
                    </Space>
                </div>
            ),
        }
    ];

    const changeTab = e => {
        setTab(e)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Training and Certification" breadcrumbs={[['Training and Certification']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Training and Certification List</Text>
                    </div>}>
                    {
                        (instruction) ?
                            <Row className="mb-3">
                                <Col span={24} className="instruction-box">
                                    <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                                </Col>
                            </Row>
                            : null
                    }
                    {
                        (loaded) ?
                            <Tabs type="card" onChange={changeTab}>
                                <TabPane tab={<span><ProfileTwoTone style={{ color: '#4CA3FF' }} /> List</span>} key="list">
                                    <DataTable size='small' bordered dataSource={trainings} columns={columns} />
                                </TabPane>
                                <TabPane tab={<span><CalendarTwoTone twoToneColor="#52C41A" /> Joined</span>} key="joined">
                                    <DataTable size='small' bordered dataSource={joined} columns={columns} />
                                </TabPane>
                                <TabPane tab={<span><SnippetsTwoTone twoToneColor="#FFA500" /> External</span>} key="external">
                                    <NewButton to="/request-training/external-form" title="Add Training" />
                                    <DataTable size='small' bordered dataSource={trainingExternals} columns={externalColumns} />
                                </TabPane>
                            </Tabs>
                            : <Spinner />
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
