import { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Input, DatePicker, Select } from 'antd';
import { Uploader } from '../../../../components';
import { SentralModel } from '../../../../models/SentralModel';

const { Option } = Select;

export const FormatTicketPresales = (props) => {
    const { saveSubmit, onChangeCategory, categoryRequest = false, onChangeCompany, modalCompany } = props
    const [companies, setCompanies] = useState([])

    const fetchCompany = () => {
        SentralModel.action("Company", "getCompany", null, 0)
            .then((res) => {
                setCompanies(res.data)
            })
    }

    useEffect(() => {
        fetchCompany()

    }, []);

    return (
        <Form layout='vertical' onFinish={(v) => saveSubmit(v)}>
            <Form.Item label="Category Request" name="category_request" required rules={[{ required: true }]}>
                <Select placeholder="Select Category" onChange={(v) => onChangeCategory(v)}>
                    <Option value="Product Recomendation">Product Recomendation</Option>
                    <Option value="Support Project">Support Project</Option>
                    <Option value="Other">Other</Option>
                </Select>
            </Form.Item>
            {
                categoryRequest ?
                    <>
                        <Row justify='space-between'>
                            <Col span={17}>
                                <Form.Item label="Company" name="company_id" required rules={[{ required: true }]}>
                                    <Select showSearch allowClear placeholder="Select Company" style={{ width: "100%" }} optionFilterProp="children" onChange={onChangeCompany} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {
                                            companies.map((el, key) => (
                                                <Option key={key} value={el.company_id}>{el.company_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <div style={{ marginTop: 30 }}>
                                    <Button type='primary' onClick={modalCompany}>Add Company</Button>
                                </div>
                            </Col>
                        </Row>
                        <Form.Item label="SLA" name="sla" required rules={[{ required: true }]}>
                            <Select placeholder="Select SLA">
                                <Option value="Urgent">Urgent</Option>
                                <Option value="Hight">High</Option>
                                <Option value="Medium">Medium</Option>
                                <Option value="Low">Low</Option>
                            </Select>
                        </Form.Item>
                    </> :
                    <>
                        <Form.Item label="Nama Pengadaaan" name="procurement_name" required rules={[{ required: true }]}>
                            <Input placeholder='Input Procurement' />
                        </Form.Item>
                        <Form.Item label="Link" name="link" required rules={[{ required: true }]}>
                            <Input placeholder='Link' />
                        </Form.Item>
                        <Form.Item label="Tanggal Aanwijzing" name="aanwijzing_date" required rules={[{ required: true }]}>
                            <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                    </>
            }
            <Form.Item label="Attachment" required rules={[{ required: true }]}>
                <Uploader folder="attachment-ticket-presales" onUploaded={(v) => props.changeFileUploaded(v)} isUploading={(v) => props.changeSetUploading(v)} view={true} />
            </Form.Item>
            <Form.Item label="Description Request" name="description" required rules={[{ required: true }]}>
                <Input.TextArea rows={5} />
            </Form.Item>
            <Row justify='end'>
                <Col>
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>Save</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default FormatTicketPresales