import React, { useState, useEffect, useMemo } from 'react';
import { List, Divider, Typography, Row, Button, message } from 'antd';
import { BasicModal, DataTable, Spinner } from '../../components'
import { SentralModel } from '../../models/SentralModel';
import { formatDateTime } from '../../utils/dateFormat';
import { decrypt } from '../../helpers/crypto';
import authData from '../../helpers/authData';

const { Title } = Typography

const Information = () => {
    const [showModal, setShowModal] = useState(false)
    const [announcements, setAnnouncements] = useState(null)
    const [selectedAnnouncement, setSelectedAnnouncement] = useState({})
    const [showAnnouncement, setShowAnnouncement] = useState(false);

    const role = useMemo(() => localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : [], []);

    const fetchAnnouncements = (role) => {
        SentralModel.action('Announcement', 'getAnnouncement', { employee_code: authData.employee_code }, 0).then((res) => {
            setAnnouncements(res.data)
            if (res.data.announcement.length !== 0 || res.data.birthday.length !== 0 || (res.data.probationOneWeek.length !== 0 && role.includes('HR')) || (res.data.probationTwoWeek.length !== 0 && role.includes('HR')) || (res.data.probationTwoWeek.length !== 0 && role.includes('HR')) || res.data.training.length !== 0) {
                let firstLogin = (localStorage.getItem('firstLogin') === 'true') ? true : false;
                setShowAnnouncement(firstLogin)
            }
        })
    }

    const showAnnouncementDetail = (key) => {
        setSelectedAnnouncement(announcements.announcement[key])
        setShowModal(true)
    }

    useEffect(() => {
        fetchAnnouncements(role)
    }, [role]);

    const onCloseAnnouncement = () => {
        localStorage.setItem('firstLogin', false);
        setShowAnnouncement(false);
    }

    const confirmAbsence = (v) => {
        let payload = {
            training_id: v.training_id,
            employee_code: authData.employee_code
        }

        SentralModel.action('Training', 'confirmTrainingAbsence', payload, 0).then((res) => {
            message.success('Success Confirm Absence')
        }).catch((err) => {
            console.log(err)
        })
    }

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Training Name',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Action',
        render: (row) => (
            row.status === 0 || row.status === null ?
                <Button type='primary' size='small' onClick={() => confirmAbsence(row)}>Konfirmasi Absensi</Button>
                :
                <Button disabled type='primary' size='small' onClick={() => confirmAbsence(row)}>Konfirmasi Absensi</Button>
        ),
        key: 'action',
        align: 'center',
        width: '20%'
    }
    ];

    return (
        <div>
            {
                (announcements) ?
                    <div>
                        <List style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                            {
                                announcements.announcement.map((el, key) => (
                                    <div key={'information_' + key}>
                                        <List.Item onClick={() => showAnnouncementDetail(key)} style={{ padding: 0, margin: 0, border: 0, cursor: 'pointer' }}>
                                            {el.subject} <br />
                                            <span className="text-muted">{formatDateTime(el.updated_at)}</span>
                                        </List.Item>
                                        <Divider />
                                    </div>
                                ))
                            }
                        </List>

                        <BasicModal title="Announcement" width={800} showModal={showAnnouncement} onCloseModal={onCloseAnnouncement}>
                            <List style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                                {
                                    announcements.announcement.map((el, key) => (
                                        <div key={'information_' + key}>
                                            <List.Item onClick={() => showAnnouncementDetail(key)} style={{ padding: 0, margin: 0, border: 0, cursor: 'pointer' }}>
                                                {el.subject} <br />
                                                <span className="text-muted">{formatDateTime(el.updated_at)}</span>
                                            </List.Item>
                                            <Divider />
                                        </div>
                                    ))
                                }

                                {
                                    announcements.birthday.length !== 0 ?
                                        <Row align='middle' style={{ paddingBottom: 10 }}>
                                            <img alt="karajo-logo" src={process.env.PUBLIC_URL + '/images/cake.png'} style={{ width: 30 }} />
                                            <Title level={5} style={{ paddingLeft: 10, paddingTop: 10 }}>Happy Birthday</Title>
                                        </Row>
                                        : null
                                }
                                {
                                    announcements.birthday.length !== 0 ?
                                        <>
                                            {
                                                announcements.birthday.map((el, key) => (
                                                    <div key={'information_' + key}>
                                                        <List.Item style={{ padding: 0, margin: 0, border: 0 }}>
                                                            <strong>{`➤ ${el.name} (${el.division.division_name})`}</strong> <br />
                                                        </List.Item>
                                                    </div>
                                                ))
                                            }
                                            {/* <Divider/> */}
                                            <br />
                                            <p style={{ paddingLeft: 20 }}>Hopefully tomorrow will be happier, what you aspire to achieve and always be healthy.</p>
                                        </>
                                        : null
                                }

                                {
                                    role.includes('HR') ?

                                        announcements.probationOneWeek.length === 0 && announcements.probationTwoWeek.length === 0 && announcements.probationOneMonth.length === 0 ?
                                            null :
                                            <>
                                                <Row style={{ paddingBottom: 10 }}>
                                                    <Title level={5} style={{ paddingLeft: 10, paddingTop: 10 }}>Employee Probation</Title>
                                                </Row>
                                                {
                                                    announcements.probationOneWeek.length !== 0 && role.includes('HR') ?
                                                        <>
                                                            <Row>
                                                                <Title level={5}>Probation will end in 7 days</Title>
                                                            </Row>
                                                            {
                                                                announcements.probationOneWeek.map((el, key) => (
                                                                    <div key={'information_' + key}>
                                                                        <List.Item style={{ padding: 0, margin: 0, border: 0 }}>
                                                                            <strong>{`➤ ${el.employee_name} (${el.division_name})`}</strong> <br />
                                                                        </List.Item>
                                                                    </div>
                                                                ))
                                                            }
                                                        </>
                                                        : null
                                                }
                                                <Divider />
                                                {
                                                    announcements.probationTwoWeek.length !== 0 && role.includes('HR') ?
                                                        <>
                                                            <Row>
                                                                <Title level={5}>Probation will end in 14 days</Title>
                                                            </Row>
                                                            {
                                                                announcements.probationTwoWeek.map((el, key) => (
                                                                    <div key={'information_' + key}>
                                                                        <List.Item style={{ padding: 0, margin: 0, border: 0 }}>
                                                                            <strong>{`➤ ${el.employee_name} (${el.division_name})`}</strong> <br />
                                                                        </List.Item>
                                                                    </div>
                                                                ))
                                                            }
                                                        </>
                                                        : null
                                                }
                                                <Divider />
                                                {
                                                    announcements.probationOneMonth.length !== 0 && role.includes('HR') ?
                                                        <>
                                                            <Row>
                                                                <Title level={5}>Probation will end in 30 days</Title>
                                                            </Row>
                                                            {
                                                                announcements.probationOneMonth.map((el, key) => (
                                                                    <div key={'information_' + key}>
                                                                        <List.Item style={{ padding: 0, margin: 0, border: 0 }}>
                                                                            <strong>{`➤ ${el.employee_name} (${el.division_name})`}</strong> <br />
                                                                        </List.Item>
                                                                    </div>
                                                                ))
                                                            }
                                                        </>
                                                        : null
                                                }
                                            </>
                                        :
                                        null
                                }
                                {
                                    announcements.training.length !== 0 ?
                                        <>
                                            <Row>
                                                <Title level={5}>You haven't confirmed Training Absence</Title>
                                            </Row>
                                            <Row>
                                                <p>Please confirm your absence.</p>
                                            </Row>
                                            <DataTable columns={columns} dataSource={announcements.training} search={false} size="small" />
                                        </>
                                        : null
                                }
                            </List>
                        </BasicModal>
                    </div>
                    : <Spinner />
            }
            {
                (announcements && announcements.announcement.length === 0) ?
                    <span className="text-muted">No announcement for today</span>
                    : null
            }
            <BasicModal title="Announcement" width={800} showModal={showModal} onCloseModal={() => setShowModal(false)}>
                <Title style={{ marginBottom: 0 }} level={3}>{selectedAnnouncement.subject}</Title>
                <span className="text-muted">{formatDateTime(selectedAnnouncement.updated_at)}</span>
                <p className="mt-3" dangerouslySetInnerHTML={{ __html: selectedAnnouncement.announcement }}></p>
            </BasicModal>
        </div>
    );
}

export default Information;
