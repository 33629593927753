import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, message, Space, Tag, Switch, Select} from 'antd'
import {AddButton, EditButton, DeleteButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import {toIdr,extractNumber} from '../../../utils/currencyHandler'

const {Text} = Typography
const {Option} = Select;

const Index = () => {
    const [form] = Form.useForm()
    const [items, setItems] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const [status, setStatus] = useState(null)
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(null);

    const fetchItems = () =>{
        SentralModel.list('ItemStationery', {orderBy:"created_at", order:"desc"}).then(res=>{
            setItems(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchCategories = () => {
        setLoaded(false)
        SentralModel.list('StationeryCategory', {orderBy:"name", order:"asc"}).then(res=>{
            setLoaded(true)
            setCategories(res.data)
        })
    }

    useEffect(() => {
        fetchItems()
        fetchCategories()
    }, []);
    
    const dataSource = [
        ...items.map( el => {
            return {
                id: el.item_id,
                category_id: el.category_id,
                item_code: el.item_code,
                item_name: el.item_name,
                uom: el.uom,
                price: toIdr(el.price),
                minimum_stock: el.minimum_stock,
                current_stock: el.current_stock,
                is_active: el.is_active
            }
        })
    ];

    const columns = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
          title: 'Item Code',
          dataIndex: 'item_code',
          key: 'item_code'
        },
        {
            title: 'Item Name',
            dataIndex: 'item_name',
            key: 'item_name'
        },
        {
            title: 'UoM',
            dataIndex: 'uom',
            key: 'uom',
            width: '5%'
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: '15%',
            render: (text) => (
                <div className="text-right">
                    {text}
                </div>
            )
        },
        {
            title: 'Minimum Stock',
            dataIndex: 'minimum_stock',
            key: 'minimum_stock',
            width: '10%',
            render: (text) => (
                <div className="text-right">
                    {text}
                </div>
            )
        },
        {
            title: 'Current Stock',
            dataIndex: 'current_stock',
            key: 'current_stock',
            width: '10%',
            render: (text) => (
                <div className="text-right">
                    {text}
                </div>
            )
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            width: '10%',
            render: (text) => (
                <div className="text-center">
                    <Tag color={text === '1' ? "green" : "red"}>{(text === '1') ? 'Active' : 'Inactive'}</Tag>
                </div>
            )
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editItems(row)}/>
                        <DeleteButton onConfirm={() => deleteItems(row.id)}/>
                    </Space>
                </div>
            )
        },
    ];

    const addItem=()=>{
        setModalTitle('Add Item')
        setDefaultValues({
            item_id: 0,
            item_name: '',
            item_code: '',
            uom: null,
            price: null,
            minimum_stock: null,
            current_stock: null,
            is_active: true
        })
        setStatus('1')
        setFormModal(true)
    }

    const editItems = (v) =>{
        setModalTitle('Edit Items')
        setDefaultValues({
            item_id: v.id,
            category_id: parseInt(v.category_id),
            item_code: v.item_code,
            item_name: v.item_name,
            uom: v.uom,
            price: v.price,
            minimum_stock: v.minimum_stock,
            current_stock: v.current_stock,
            is_active: parseInt(v.is_active)
        })
        setStatus(v.is_active)
        setCategory(v.category_id)
        setFormModal(true)
    }

    const deleteItems = (id) =>{
        message.loading('deleting item')
        SentralModel.deleteById('ItemStationery', id).then((res)=>{
            message.destroy()
            message.success('Items deleted', 2)
            let tmp = items
            let index = tmp.findIndex(el => el.item_id===id)
            tmp.splice(index, 1);
            setItems([])
            setItems(tmp)
        })
    }

    const saveItem = (v) => {
        setSaving(true)
        let payload={
            item_id: v.item_id,
            category_id: category,
            item_code: v.item_code,
            item_name: v.item_name,
            uom: v.uom,
            price: extractNumber(v.price),
            minimum_stock: v.minimum_stock,
            current_stock: v.current_stock,
            is_active: status
        }
        SentralModel.save('ItemStationery', payload, v.item_id).then((res)=>{
            setFormModal(false)
            fetchItems()
            message.success('Item saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const changeItemPrice = (v) =>{
        if(v.target.value){
            let val = extractNumber(v.target.value)
            form.setFieldsValue({price: toIdr(val)})
        }
    }

    const changeCategory = e => {
        setCategory(e)
    }

    const changeStatus = e => {
        setStatus(e)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Item Stationary" breadcrumbs={[['Item Stationary']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Item List</Text>
                        <AddButton right onAdd={addItem} title="New Item"/>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveItem(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="item_id" hidden>
                                <Input placeholder="input placeholder" />
                            </Form.Item>
                            <Form.Item label="Category" name="category_id" required rules={[{required:true}]}>
                                <Select placeholder="Select category" style={{ width: "100%" }} onChange={changeCategory}>
                                    {
                                        categories.map((el, key) => (
                                            <Option key={key} value={el.stationery_category_id}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Item Code" name="item_code" rules={[{required:true}]}>
                                <Input placeholder="Item Code" />
                            </Form.Item>
                            <Form.Item label="Item Name" name="item_name" rules={[{required:true}]}>
                                <Input placeholder="Item Name" />
                            </Form.Item>
                            <Form.Item label="UoM" name="uom" rules={[{required:true}]}>
                                <Input placeholder="UoM" />
                            </Form.Item>
                            <Form.Item label="Price" name="price" rules={[{required:true}]}>
                                <Input onChange={(v) => changeItemPrice(v)} placeholder="Price" />
                            </Form.Item>
                            <Form.Item label="Minimum Stock" name="minimum_stock" rules={[{required:true}]}>
                                <Input placeholder="Minimum Stock" />
                            </Form.Item>
                            <Form.Item label="Current Stock" name="current_stock" rules={[{required:true}]}>
                                <Input placeholder="Current Stock" />
                            </Form.Item>
                            <Form.Item label="Status" name="is_active" rules={[{required:true}]}>
                                <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked={defaultValues.is_active} onChange={changeStatus} />
                            </Form.Item>
                        </FormModal> 
                    : null
                }
            </Card>
        </div>
    );
}

export default Index;
