import React from 'react';
import './style.css'

const DashboardItem = (props) => {
    return (
        <div onClick={props.onClick} className="card-click box-shadow-3">
            <div className='container'>
                <div style={{ width: '100%', height: 50, padding: 5, borderBottom: '1px solid #f0f0f0', fontSize: 20, fontWeight: 500, verticalAlign: 'middle' }}>
                    {props.title}
                </div>
                <div style={{ height: props.height, paddingLeft: 20, paddingRight: 20, paddingTop: 5, verticalAlign: 'middle' }}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default DashboardItem;
