import { useState } from 'react'
import { Button, DatePicker, Form, Select } from 'antd'
import { SentralModel } from '../../../../models/SentralModel'
import { Spinner, Uploader } from '../../../../components'
import moment from 'moment'

const { Option } = Select
const { RangePicker } = DatePicker

const ITInfra = (props) => {
    const [form] = Form.useForm()
    const [showDate, setShowDate] = useState(false)
    const [masterJob, setMasterJob] = useState([])
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [uploading, setUploading] = useState(false)

    const fetchMasterJob = (v) => {
        SentralModel.action("MasterJobDescription", "getMasterData", { division_id: 34, position_id: 66, job_category: v })
            .then((res) => {
                setMasterJob(res.data)
            })
    }

    const submitForm = (v) => {
        let payload = {
            ...v,
            start_date: v.job_category === "DAILY" ? null : moment(v.date[0]).format("YYYY-MM-DD"),
            due_date: v.job_category === "DAILY" ? null : moment(v.date[1]).format("YYYY-MM-DD"),
            attachment: attachmentFile
        }

        props.onSubmit(payload)
    }

    const onChangeCategory = (v) => {
        fetchMasterJob(v)
        if (v !== "DAILY") {
            setShowDate(true)
        } else {
            setShowDate(false)
        }
    }

    const fileUploaded = (v) => {
        let attcs = v.message
        setAttachmentFile(attcs)
    }

    return (
        <div>
            <Form layout='vertical' form={form} onFinish={submitForm}>
                <Form.Item label="Job Category" name="job_category" rules={[{ required: true, message: 'Job category is required' }]}>
                    <Select placeholder="Select Job Category" onChange={onChangeCategory}>
                        <Option value="DAILY">DAILY</Option>
                        <Option value="WEEKLY">WEEKLY</Option>
                        <Option value="MONTHLY">MONTHLY</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Job Description" name="job_description" rules={[{ required: true, message: 'Job description is required' }]}>
                    <Select placeholder="Select Job Description">
                        {
                            masterJob.map((el, key) => (
                                <Option value={el.description} key={key}>{el.description}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                {
                    showDate ?
                        <>
                            <Form.Item label="Date Timeline" name="date" rules={[{ required: true, message: 'Date is required' }]}>
                                <RangePicker style={{ width: "30%" }} />
                            </Form.Item>
                            <Form.Item label="Finished Date" name="finished_date" rules={[{ required: true, message: 'Finished date is required' }]}>
                                <DatePicker style={{ width: "30%" }} />
                            </Form.Item>
                            <Form.Item label="Attachment" name="attachment">
                                <Uploader onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                            </Form.Item>
                        </>
                        : null
                }
                <Form.Item>
                    {
                        uploading ?
                            <Button type="primary" disabled htmlType="submit"><Spinner /></Button>
                            :
                            <Button type="primary" htmlType="submit">Save</Button>

                    }
                </Form.Item>
            </Form>
        </div>
    )
}

export default ITInfra