import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Row, Select, Form, Checkbox, Input, message } from 'antd'
import { PageTitle } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { decrypt } from '../../../helpers/crypto'

const { Option } = Select

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const Index = () => {
    const forceUpdate = useForceUpdate()
    const [setItemForm] = Form.useForm()
    const [currentSelected, setCurrentSelected] = useState({})
    const [division, setDivision] = useState([])
    const [position, setPosition] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(0)
    const [selectedPosition, setSelectedPosition] = useState(0)
    const [totalPercentage, setTotalPercentage] = useState(0)
    const [kpiItems, setKpiItems] = useState([])
    const [listCategory, setListCategory] = useState([])
    const [totalCategoryValue, setTotalCategoryValue] = useState(0)
    const [totalCategory, setTotalCategory] = useState([])
    const [totalItemByCategory, setTotalItemByCategory] = useState([])
    const [itemCategories, setItemCategories] = useState({})
    const [items, setItems] = useState({})
    const [totalItems, setTotalItems] = useState({})
    const [totalCategories, setTotalCategories] = useState({})

    const role = localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : []

    const fetchAppraisalItem = (v) => {
        SentralModel.action("Appraisal", "getAppraisalMasterSetup", v, 0).then((res) => {
            setKpiItems(res.data)
            let x = {};
            let objValue = {};
            let ctgValue = {};
            let totalAll = 0;
            let totalCategory = 0;
            let dataCtg = [];
            let dataItemByCtg = [];
            let listCtg = [];

            res.data.forEach(ctg => {
                let totalValueCategory = 0;
                ctg.items.forEach(item => {
                    x['kpi_item_' + item.kpi_item_id] = item.selected
                    setItemForm.setFieldsValue({
                        ['kpi_item_' + item.kpi_item_id]: item.selected
                    })

                    setItemForm.setFieldsValue({
                        ['kpi_item_value_' + item.kpi_item_id]: item.rate
                    })

                    if (item.rate !== 0) {
                        Object.assign(objValue, { ['kpi_item_id_' + item.kpi_item_id]: item.rate })
                    }

                    totalValueCategory += item.rate
                })

                if (ctg.rate > 0) {
                    Object.assign(ctgValue, { ['kpi_item_category_id_' + ctg.kpi_item_category_id]: ctg.rate })
                }

                setItemForm.setFieldsValue({
                    ['kpi_category_value_' + ctg.kpi_item_category_id]: ctg.rate
                })

                setItemForm.setFieldsValue({
                    ['kpi_category_' + ctg.kpi_item_category_id]: true
                })

                totalAll += totalValueCategory;
                let totalByCtg = ctg.items.reduce((total, item) => total + item.rate, 0)
                let category = {
                    category_id: ctg.kpi_item_category_id,
                    total: ctg.rate ?? 0
                }

                let itemByCategory = {
                    category_id: ctg.kpi_item_category_id,
                    total: totalByCtg ?? 0
                }

                dataItemByCtg.push(itemByCategory)
                dataCtg.push(category);

                totalCategory += ctg.rate

                if (ctg.rate !== 0) {
                    listCtg.push(ctg.kpi_item_category_id)
                }
            });

            setCurrentSelected(x)
            setTotalPercentage(totalAll)
            setTotalCategoryValue(totalCategory)
            setTotalCategory(dataCtg)
            setTotalItemByCategory(dataItemByCtg)
            setListCategory(listCtg)
            setTotalItems(objValue)
            setTotalCategories(ctgValue)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', { filter: [["is_active", "1"]], orderBy: "division_name", order: "asc" }).then(res => {
            setDivision(res.data)
        })
    }

    const fetchPosition = () => {
        SentralModel.list('Position', { filter: [["is_active", "1"], ["deleted_at", null]], orderBy: "position_name", order: "asc" }).then(res => {
            const v = res.data
            let arr = []
            v.forEach((el) => {
                arr.push({ value: el.position_id, label: el.position_name })
            })
            setPosition(arr)
        })
    }

    const saveKpiItem = (v) => {
        const newObj = Object.assign(totalItems, items)
        const arrObj = Object.values(newObj)

        if (arrObj.includes(0)) {
            message.warning("Failed save data, item percentage value cannot be 0")
            return
        }
        if ((totalPercentage / listCategory.length) !== 100) {
            message.warning("Failed save data, please check item percentage")
            return
        }
        if (totalCategoryValue !== 100) {
            message.warning("Failed save data, please check total percentage")
            return
        }

        let payload = {
            categories: Object.assign(totalCategories, itemCategories),
            list_item: Object.assign(totalItems, items),
            division_id: selectedDivision,
            position_id: selectedPosition,
        }

        SentralModel.action('Appraisal', 'saveMasterAppraisalComponent', payload, 0).then((res) => {
            message.success('Master Appraisal Component saved', 2);
        })
    }

    useEffect(() => {
        fetchDivision()
        fetchPosition()

    }, [])


    const handleChangeDivision = (v) => {
        const divs = division.filter((el) => el.division_id === v)
        setSelectedDivision(divs[0].division_id)
        fetchAppraisalItem({ division_id: selectedDivision, position_id: 0 })
    }

    const handleChangePosition = (v) => {
        setSelectedPosition(v)
        fetchAppraisalItem({ division_id: selectedDivision, position_id: v })
    }

    // const checkAll = (e, v) => {
    //     let selected = kpiItems.filter((el) =>
    //         el.kpi_item_category_id === v
    //     )
    //     let x = currentSelected
    //     if (e.target.checked) {
    //         selected[0].items.forEach(el => {
    //             setItemForm.setFieldsValue({
    //                 ['kpi_item_' + el.kpi_item_id]: true
    //             })
    //             x['kpi_item_' + el.kpi_item_id] = true
    //         })
    //     } else {
    //         selected[0].items.forEach(el => {
    //             setItemForm.setFieldsValue({
    //                 ['kpi_item_' + el.kpi_item_id]: false
    //             })
    //             x['kpi_item_' + el.kpi_item_id] = false
    //         })
    //     }
    //     setCurrentSelected(x)
    //     forceUpdate()

    // }

    const totalItem = (v) => {
        let total = 0

        v.items.forEach((el) => {
            if (el.selected === true) {
                total += el.rate
            }
        })

        return total;
    }


    const checkItem = (v, id, ctg) => {
        let x = currentSelected
        let y = totalItems
        if (v.target.checked) {
            x['kpi_item_' + id] = true
            y['kpi_item_id_' + id] = 0

            let category = kpiItems.find(el => el.kpi_item_category_id === ctg)
            let item = category.items.find(el => el.kpi_item_id === id)
            item.selected = true
            item.rate = 0
        } else {
            x['kpi_item_' + id] = false
            setItemForm.setFieldsValue({
                ['kpi_item_value_' + id]: 0
            })

            let category = kpiItems.find(el => el.kpi_item_category_id === ctg)
            let item = category.items.find(el => el.kpi_item_id === id)
            item.selected = false
            item.rate = 0

            delete totalItems['kpi_item_id_' + id];
        }

        setCurrentSelected(x)
        forceUpdate()
    }

    const changeCategoryValue = (e, ctgId) => {
        let totalCtg = 0;
        let v = itemCategories
        v['kpi_item_category_id_' + ctgId] = parseInt(e.target.value)

        setItemCategories(v)

        const ctg = totalCategory.find((el) => el.category_id === ctgId)
        ctg.total = parseInt(e.target.value)

        totalCategory.forEach((el) => {
            totalCtg += parseInt(el.total)
        })

        setTotalCategoryValue(totalCtg)
    }

    const changeItemValue = (e, id, ctgId) => {
        let arr = listCategory

        if (!arr.find(e => e === ctgId)) {
            arr.push(ctgId)
        }

        setListCategory(arr)

        let arrItem = items
        arrItem[`kpi_item_id_${id}`] = parseInt(e.target.value)

        const newArrItem = Object.assign(totalItems, arrItem)
        setItems(newArrItem)

        let category = kpiItems.find(el => el.kpi_item_category_id === ctgId)
        let item = category.items.find(el => el.kpi_item_id === id)
        item.rate = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)

        let totalByCtg = category.items.reduce((total, item) => total + item.rate, 0)

        let ctg = totalItemByCategory.find(el => el.category_id === ctgId)
        ctg.total = totalByCtg

        let totalAll = totalItemByCategory.reduce((total, cat) => total + cat.total, 0)

        forceUpdate()
        setTotalPercentage(totalAll)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Set Item Master" breadcrumbs={[['Set Item Master']]}></PageTitle>
                <Card title={
                    <Row justify="space-between">
                        <Col>
                            {
                                selectedDivision.division_name
                            }
                        </Col>
                        <Col span={10}>
                            <Form layout='vertical'>
                                <Row justify='space-between'>
                                    <Col span={11}>
                                        <Form.Item label="Division">
                                            <Select style={{ width: "100%" }} placeholder="Select Division" onChange={(v) => handleChangeDivision(v)}>
                                                {
                                                    division.map((el, key) => (
                                                        <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item label="Position">
                                            <Select
                                                showSearch
                                                placeholder="Select Position"
                                                options={position}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                onChange={(v) => handleChangePosition(v)}
                                                style={{ width: "100%" }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                }>
                    <Form form={setItemForm} onFinish={saveKpiItem} size="small">
                        <table className="table-collapse table-default" style={{ width: '100%', marginBottom: 20 }}>
                            <thead>
                                <tr>
                                    <th className="text-center" style={{ width: '30px' }}>No</th>
                                    <th className="text-left" colSpan="2">Component</th>
                                    <th>Rate (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    kpiItems.map((el, i) => (
                                        (el.items.length > 0) ?
                                            <React.Fragment key={'category_' + i}>
                                                <tr style={{ backgroundColor: '#dedcdc' }}>
                                                    <th className="text-left" colSpan={3}>
                                                        {/* <Checkbox onChange={(e) => checkAll(e, el.kpi_item_category_id)} />  */}
                                                        {el.name}
                                                    </th>
                                                    <th style={{ width: '100px' }}>
                                                        <Form.Item name={'kpi_category_value_' + el.kpi_item_category_id} style={{ margin: 0, padding: 0 }}>
                                                            {
                                                                role.includes("SUPERADMIN") || role.includes("HR") ?
                                                                    <Input
                                                                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                                                                        type="number"
                                                                        onBlur={(e) => {
                                                                            if (e.target.value === '') {
                                                                                setItemForm.setFieldsValue({ ['kpi_category_value_' + el.kpi_item_category_id]: 0 })
                                                                            }
                                                                        }}
                                                                        onChange={(v) => changeCategoryValue(v, el.kpi_item_category_id)}
                                                                    />
                                                                    : <Input
                                                                        disabled
                                                                        style={{ fontWeight: 'bold', textAlign: 'center' }}
                                                                        type="number"
                                                                        onBlur={(e) => {
                                                                            if (e.target.value === '') {
                                                                                setItemForm.setFieldsValue({ ['kpi_category_value_' + el.kpi_item_category_id]: 0 })
                                                                            }
                                                                        }}
                                                                        onChange={(v) => changeCategoryValue(v, el.kpi_item_category_id)}
                                                                    />
                                                            }
                                                        </Form.Item>
                                                    </th>
                                                </tr>
                                                <tr style={{ backgroundColor: '#f6f6f6' }}>
                                                    <th className="text-left" colSpan={3}>Total Item</th>
                                                    <th>
                                                        <Input
                                                            disabled
                                                            style={{ fontWeight: 'bold', textAlign: 'center' }}
                                                            type="number"
                                                            value={totalItem(el)} />
                                                    </th>
                                                </tr>
                                                {
                                                    el.items.map((item, j) => (
                                                        <tr key={'item_' + item.kpi_item_id}>
                                                            <td style={{ verticalAlign: "top", }} className="text-center">{j + 1}</td>
                                                            <td style={{ verticalAlign: "top" }}>
                                                                <Form.Item name={'kpi_item_' + item.kpi_item_id} style={{ margin: 0, padding: 0 }} valuePropName="checked">
                                                                    {
                                                                        role.includes("SUPERADMIN") || role.includes("HR") ?
                                                                            <Checkbox onChange={(e) => checkItem(e, item.kpi_item_id, el.kpi_item_category_id)} />
                                                                            :
                                                                            <Checkbox disabled onChange={(e) => checkItem(e, item.kpi_item_id, el.kpi_item_category_id)} />
                                                                    }
                                                                </Form.Item>
                                                            </td>
                                                            <td style={{ verticalAlign: "top" }}>{item.description}</td>
                                                            <td>
                                                                <Form.Item name={'kpi_item_value_' + item.kpi_item_id} style={{ margin: 0, padding: 0 }}>
                                                                    {
                                                                        role.includes("SUPERADMIN") || role.includes("HR") ?
                                                                            <Input
                                                                                style={{ textAlign: 'center' }}
                                                                                type='number'
                                                                                disabled={!currentSelected['kpi_item_' + item.kpi_item_id]}
                                                                                min={0}
                                                                                onChange={(v) => changeItemValue(v, item.kpi_item_id, el.kpi_item_category_id)}
                                                                                onBlur={(e) => {
                                                                                    if (e.target.value === '') {
                                                                                        setItemForm.setFieldsValue({ ['kpi_item_value_' + item.kpi_item_id]: 0 })
                                                                                    }
                                                                                }} />
                                                                            :
                                                                            <Input
                                                                                style={{ textAlign: 'center' }}
                                                                                type='number'
                                                                                disabled
                                                                                min={0}
                                                                                onChange={(v) => changeItemValue(v, item.kpi_item_id, el.kpi_item_category_id)}
                                                                                onBlur={(e) => {
                                                                                    if (e.target.value === '') {
                                                                                        setItemForm.setFieldsValue({ ['kpi_item_value_' + item.kpi_item_id]: 0 })
                                                                                    }
                                                                                }} />
                                                                    }
                                                                </Form.Item>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </React.Fragment>
                                            : null
                                    ))
                                }
                                <tr>
                                    <th colSpan={3} className="text-right">Total</th>
                                    <th>{totalCategoryValue} %</th>
                                </tr>
                            </tbody>
                        </table>

                        <div className='text-right'>
                            <Form.Item>
                                {
                                    role.includes("SUPERADMIN") || role.includes("HR") ?
                                        <Button type="primary" htmlType="submit" size='middle'>
                                            Save
                                        </Button>
                                        :
                                        <Button disabled type="primary" htmlType="submit" size='middle'>
                                            Save
                                        </Button>
                                }
                            </Form.Item>
                        </div>
                    </Form>
                </Card>
            </Card>
        </div>
    )
}

export default Index