import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Divider, Row } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import authData from '../../../helpers/authData'
import { CheckCircleTwoTone } from '@ant-design/icons';

const Index = () => {
    const [loaded, setLoaded] = useState(false)
    const [defaultCategory, setDefaultCategory] = useState("Daily")
    const [dashboardData, setDashboardData] = useState([])

    const fetchDashboardData = () => {
        setLoaded(false)
        SentralModel.action("ItInfra", "getDashboard", null, null).then((res) => {
            setDashboardData(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchDashboardData()

    }, [])

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Dashboard IT Infra" breadcrumbs={[['Dashboard IT Infra']]}></PageTitle>
                <div style={{ marginTop: 10, marginBottom: 15 }}>
                    <Button onClick={() => setDefaultCategory("Daily")}>Daily</Button>
                    <Button onClick={() => setDefaultCategory("Weekly")}>Weekly</Button>
                    <Button onClick={() => setDefaultCategory("Monthly")}>Monthly</Button>
                </div>
                <Divider />

                {
                    loaded ?
                        defaultCategory === "Daily" ?
                            <div>
                                <PageTitle title="Daily"></PageTitle>
                                <div>
                                    <Row justify='space-between'>
                                        {
                                            authData.employee_code === "ADM001" ?
                                                <Col span={7}>
                                                    {
                                                        dashboardData?.admin?.filter((el) => el.job_category === "DAILY").length > 0 ?
                                                            <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Admin</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Job Description</th>
                                                                        <th>Information</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        dashboardData?.admin?.filter((el) => el.job_category === "DAILY").map((el, key) => (
                                                                            <tr>
                                                                                <td key={key}>{el.description}</td>
                                                                                <td><CheckCircleTwoTone style={{ fontSize: "16px" }} twoToneColor="#24e551" /></td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            :
                                                            <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Admin</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={2}>
                                                                            <p style={{ margin: "20px 0" }}>No Data</p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                    }
                                                </Col>
                                                : null
                                        }
                                        <Col span={authData.employee_code === "ADM001" ? 7 : 11}>
                                            {
                                                dashboardData?.tanto?.filter((el) => el.job_category === "DAILY").length > 0 ?
                                                    <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Tanto</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Job Description</th>
                                                                <th>Information</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                dashboardData?.tanto?.filter((el) => el.job_category === "DAILY").map((el, key) => (
                                                                    <tr key={key}>
                                                                        <td>{el.description}</td>
                                                                        <td><CheckCircleTwoTone style={{ fontSize: "16px" }} twoToneColor="#24e551" /></td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Tanto</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <p style={{ margin: "20px 0" }}>No Data</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                            }
                                        </Col>
                                        <Col span={authData.employee_code === "ADM001" ? 7 : 11}>
                                            {
                                                dashboardData?.octa?.filter((el) => el.job_category === "DAILY").length > 0 ?
                                                    <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Octa</th>
                                                            </tr>
                                                            <tr>
                                                                <th>Job Description</th>
                                                                <th>Information</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                dashboardData?.octa?.filter((el) => el.job_category === "DAILY").map((el, key) => (
                                                                    <tr key={key}>
                                                                        <td>{el.description}</td>
                                                                        <td><CheckCircleTwoTone style={{ fontSize: "16px" }} twoToneColor="#24e551" /></td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Octa</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={2}>
                                                                    <p style={{ margin: "20px 0" }}>No Data</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            :
                            defaultCategory === "Weekly" ?
                                <div>
                                    <PageTitle title="Weekly"></PageTitle>
                                    <div>
                                        <Row justify='space-between'>
                                            {
                                                authData.employee_code === "ADM001" ?
                                                    <Col span={7}>
                                                        {
                                                            dashboardData?.admin?.filter((el) => el.job_category === "WEEKLY").length > 0 ?
                                                                <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Admin</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Job Description</th>
                                                                            <th>Information</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            dashboardData?.admin?.filter((el) => el.job_category === "WEEKLY").map((el, key) => (
                                                                                <tr>
                                                                                    <td key={key}>{el.description}</td>
                                                                                    <td><CheckCircleTwoTone style={{ fontSize: "16px" }} twoToneColor="#24e551" /></td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                :
                                                                <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Admin</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td colSpan={2}>
                                                                                <p style={{ margin: "20px 0" }}>No Data</p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                        }
                                                    </Col>
                                                    : null
                                            }
                                            <Col span={authData.employee_code === "ADM001" ? 7 : 11}>
                                                {
                                                    dashboardData?.tanto?.filter((el) => el.job_category === "WEEKLY").length > 0 ?
                                                        <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Tanto</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Job Description</th>
                                                                    <th>Information</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    dashboardData?.tanto?.filter((el) => el.job_category === "WEEKLY").map((el, key) => (
                                                                        <tr key={key}>
                                                                            <td>{el.description}</td>
                                                                            <td><CheckCircleTwoTone style={{ fontSize: "16px" }} twoToneColor="#24e551" /></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Tanto</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={2}>
                                                                        <p style={{ margin: "20px 0" }}>No Data</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                }
                                            </Col>
                                            <Col span={authData.employee_code === "ADM001" ? 7 : 11}>
                                                {
                                                    dashboardData?.octa?.filter((el) => el.job_category === "WEEKLY").length > 0 ?
                                                        <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Octa</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Job Description</th>
                                                                    <th>Information</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    dashboardData?.octa?.filter((el) => el.job_category === "WEEKLY").map((el, key) => (
                                                                        <tr key={key}>
                                                                            <td>{el.description}</td>
                                                                            <td><CheckCircleTwoTone style={{ fontSize: "16px" }} twoToneColor="#24e551" /></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Octa</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={2}>
                                                                        <p style={{ margin: "20px 0" }}>No Data</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                :
                                <div>
                                    <PageTitle title="Monthly"></PageTitle>
                                    <div>
                                        <Row justify='space-between'>
                                            {
                                                authData.employee_code === "ADM001" ?
                                                    <Col span={7}>
                                                        {
                                                            dashboardData?.admin?.filter((el) => el.job_category === "MONTHLY").length > 0 ?
                                                                <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Admin</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Job Description</th>
                                                                            <th>Information</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            dashboardData?.admin?.filter((el) => el.job_category === "MONTHLY").map((el, key) => (
                                                                                <tr>
                                                                                    <td key={key}>{el.description}</td>
                                                                                    <td><CheckCircleTwoTone style={{ fontSize: "16px" }} twoToneColor="#24e551" /></td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                :
                                                                <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Admin</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td colSpan={2}>
                                                                                <p style={{ margin: "20px 0" }}>No Data</p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                        }
                                                    </Col>
                                                    : null
                                            }
                                            <Col span={authData.employee_code === "ADM001" ? 7 : 11}>
                                                {
                                                    dashboardData?.tanto?.filter((el) => el.job_category === "MONTHLY").length > 0 ?
                                                        <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Octa</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Job Description</th>
                                                                    <th>Information</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    dashboardData?.tanto?.filter((el) => el.job_category === "MONTHLY").map((el, key) => (
                                                                        <tr key={key}>
                                                                            <td>{el.description}</td>
                                                                            <td><CheckCircleTwoTone style={{ fontSize: "16px" }} twoToneColor="#24e551" /></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Tanto</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={2}>
                                                                        <p style={{ margin: "20px 0" }}>No Data</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                }
                                            </Col>
                                            <Col span={authData.employee_code === "ADM001" ? 7 : 11}>
                                                {
                                                    dashboardData?.octa?.filter((el) => el.job_category === "MONTHLY").length > 0 ?
                                                        <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Octa</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Job Description</th>
                                                                    <th>Information</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    dashboardData?.octa?.filter((el) => el.job_category === "MONTHLY").map((el, key) => (
                                                                        <tr key={key}>
                                                                            <td>{el.description}</td>
                                                                            <td><CheckCircleTwoTone style={{ fontSize: "16px" }} twoToneColor="#24e551" /></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                        :
                                                        <table style={{ margin: "0 auto", textAlign: "center" }} className="styled-table">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ textAlign: "center", fontSize: 16 }} colSpan={2}>Octa</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={2}>
                                                                        <p style={{ margin: "20px 0" }}>No Data</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default Index