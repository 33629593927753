import { useState, useEffect } from 'react';
import { Button, Card, Col, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import '../style.css'

const { Title } = Typography

const DashboardDigitalisasi = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false)
    const [data, setData] = useState([])

    const fetchDataDigitalisasi = async () => {
        await fetch(`${process.env.REACT_APP_SMAIL_API}/dashboard/indeks-digital`, {
            method: 'GET',
            headers: {
                'client-key': process.env.REACT_APP_SMAIL_CLIENT_KEY
            }
        }).then((response) => {
            response.json().then((res) => {
                const v = res.data
                var data = v.sort(({ percentage: a }, { percentage: b }) => b - a);
                setData(data)
                setLoaded(true)
            })
        })
    }

    useEffect(() => {
        fetchDataDigitalisasi()

    }, [])

    return (
        <div>
            <Card style={{ margin: '15px 10px 0 10px' }} className="content-container">
                <div style={{ marginBottom: 10 }}>
                    <Row justify='space-between'>
                        <Col>
                            <Card>
                                <Title level={4}>INDEKS DIGITALISASI DOKUMEN</Title>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Row style={{ marginBottom: 10 }}>
                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/action-plan-home')}>ACTION PLAN</Button>
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                    <Button disabled style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => null}>DAILY ACTIVITY</Button>
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/working-location-dashboard')}>WORKING LOCATION</Button>
                                </Row>
                                <Row>
                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/digitalisasi-home')}>DIGITALISASI DOKUMEN</Button>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
                {
                    loaded ?

                        <Card>
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                        <th style={{ border: '0.5px solid grey' }}>NO</th>
                                        <th style={{ border: '0.5px solid grey' }}>DIVISION</th>
                                        <th style={{ border: '0.5px solid grey' }}>JANUARI</th>
                                        <th style={{ border: '0.5px solid grey' }}>FEBRUARI</th>
                                        <th style={{ border: '0.5px solid grey' }}>MARET</th>
                                        <th style={{ border: '0.5px solid grey' }}>APRRIL</th>
                                        <th style={{ border: '0.5px solid grey' }}>MEI</th>
                                        <th style={{ border: '0.5px solid grey' }}>JUNI</th>
                                        <th style={{ border: '0.5px solid grey' }}>JULI</th>
                                        <th style={{ border: '0.5px solid grey' }}>AGUSTUS</th>
                                        <th style={{ border: '0.5px solid grey' }}>SEPTEMBER</th>
                                        <th style={{ border: '0.5px solid grey' }}>OKTOBER</th>
                                        <th style={{ border: '0.5px solid grey' }}>NOVEMBER</th>
                                        <th style={{ border: '0.5px solid grey' }}>DESEMBER</th>
                                        <th style={{ border: '0.5px solid grey' }}>PERSENTASE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.map((el, key) => (
                                            <tr key={key} className="active-row">
                                                <td style={{ border: '0.5px solid grey' }}>{key + 1}</td>
                                                <td style={{ border: '0.5px solid grey' }}>{el.department}</td>
                                                <td style={{ border: '0.5px solid grey' }}>{el.months[0].total}</td>
                                                <td style={{ border: '0.5px solid grey' }}>{el.months[1].total}</td>
                                                <td style={{ border: '0.5px solid grey' }}>{el.months[2].total}</td>
                                                <td style={{ border: '0.5px solid grey' }}>{el.months[3].total}</td>
                                                <td style={{ border: '0.5px solid grey' }}>{el.months[4].total}</td>
                                                <td style={{ border: '0.5px solid grey' }}>{el.months[5].total}</td>
                                                <td style={{ border: '0.5px solid grey' }}>{el.months[6].total}</td>
                                                <td style={{ border: '0.5px solid grey' }}>{el.months[7].total}</td>
                                                <td style={{ border: '0.5px solid grey' }}>{el.months[8].total}</td>
                                                <td style={{ border: '0.5px solid grey' }}>{el.months[9].total}</td>
                                                <td style={{ border: '0.5px solid grey' }}>{el.months[10].total}</td>
                                                <td style={{ border: '0.5px solid grey' }}>{el.months[11].total}</td>
                                                <td>{el.percentage}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </Card>
                        : null
                }
            </Card>
        </div >
    )
}

export default DashboardDigitalisasi