import { useEffect, useState } from 'react';
import { Row, Col, Button, Space, Form, Input, DatePicker, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { SentralModel } from '../../../../models/SentralModel';
const { Option } = Select;

const FormatTicketIt = (props) => {
    const { saveSubmit } = props
    const [form] = Form.useForm();
    const [typeJob, setTypeJob] = useState([])
    // eslint-disable-next-line
    const [customer, setCustomer] = useState([])

    const fetchTypeJob = () => {
        SentralModel.action("Task", "getTypeJob", 0, 0).then((res) => {
            setTypeJob(res.data)
        })
    }

    const fetchTicketNumber = () => {
        SentralModel.action("Task", "getTicketNumber", 0, 0).then((res) => {
            form.setFieldsValue({
                ticket_number: res.data.ticket_number
            })
        })
    }

    const fetchCustomer = () => {
        SentralModel.action("Task", "getMasterCustomer", 0, 0).then((res) => {
            setCustomer(res.data)
        })
    }

    useEffect(() => {
        fetchTypeJob()
        fetchTicketNumber()
        fetchCustomer()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Form form={form} layout='vertical' onFinish={(v) => saveSubmit(v)}>
            <Row justify='space-between'>
                <Col span={11}>
                    <Row justify='space-between'>
                        <Col span={11}>
                            <Form.Item label="Type Job" name="type_job">
                                <Select placeholder="Select Type Job">
                                    {
                                        typeJob.map((el, key) => (
                                            <Option value={el.name} key={key}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="Type Problem" name="type_problem">
                                <Select placeholder="Select Type Problem">
                                    <Option></Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={11}>
                    <Form.Item label="Ticket Number" name="ticket_number">
                        <Input disabled placeholder='Ticket Number' />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify='space-between'>
                <Col span={11}>
                    <Row justify='space-between'>
                        <Col span={7}>
                            <Form.Item label="Serial Number" name="serial_number">
                                <Input placeholder='Serial Number' />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item label="Type Quantity" name="type_quantity">
                                <Input placeholder='Type Quantity' />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <div style={{ marginTop: 30 }}>
                                <Space>
                                    <Button>
                                        <SearchOutlined />
                                    </Button>
                                    <Button type='primary'>
                                        CSV
                                    </Button>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={11}>
                    <Space>
                        <Form.Item label="Company Name" name="company_name">
                            <Select placeholder="Select Company Name">
                                <Option></Option>
                            </Select>
                        </Form.Item>
                        <Button>
                            <SearchOutlined />
                        </Button>
                    </Space>
                </Col>
            </Row>
            <Row justify='space-between'>
                <Col span={11}>
                    <Row justify='space-between'>
                        <Col span={11}>
                            <Form.Item label="Item Name" name="item_name">
                                <Input placeholder='Item Name' />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="SKU / Product No" name="sku">
                                <Input placeholder='SKU / Product No' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={11}>
                    <Form.Item label="Address" name="address">
                        <Input placeholder='Address' />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify='space-between'>
                <Col span={11}>
                    <Row justify='space-between'>
                        <Col span={11}>
                            <Form.Item label="SLA Level" name="sla_level">
                                <Input placeholder='SLA Level' />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item label="Due Date" name="due_date">
                                <DatePicker />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={11}>
                    <Form.Item label="No Telp" name="no_telp">
                        <Input placeholder='No Telp' />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify='space-between'>
                <Col span={11}>
                    <Form.Item label="Description" name="description">
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Col>
                <Col span={11}>
                    <Row justify='space-between'>
                        <Col span={7}>
                            <Form.Item label="PIC" name="pic">
                                <Input placeholder="PIC" />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item label="Email" name="email">
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item label="Mobile" name="mobile">
                                <Input placeholder="Mobile" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form.Item>
                <Button type='primary' htmlType='submit'>
                    Save
                </Button>
            </Form.Item>
        </Form>
    )
}

export default FormatTicketIt