import React, {useEffect, useState} from 'react'
import {Row, Typography, Divider, Empty, Form, Input, Select, message} from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import {AddButton, FormModal, Spinner} from '../../components';
import {SentralModel} from '../../models/SentralModel'

const {Title} = Typography
const {Option} = Select;
const SocialMedia = (props) => {
    const [empSocialMedias, setEmpSocialMedias] = useState(props.data);
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle]= useState(null);
    const [socialMedias, setSocialMedias] = useState([]);
    const [selectedSocialMedia, setSelectedSocialMedia] = useState(null);

    const fetchEmpSocialMedia = () => {
        setLoaded(false)
        SentralModel.list('EmployeeSocialMedia', {filter: [['employee_code', props.emp_code]]}).then(res => {
            setEmpSocialMedias(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchSocialMedias = () => {
        SentralModel.list('GeneralDetail', {filter:[['general_code', 'SM']], orderBy:"order_key", order:"asc"}).then(res => {
            setSocialMedias(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchSocialMedias()
    }, []);

    const addSocialMedia = () => {
        setModalTitle('Add Social Media')
        setDefaultValues({
            emp_social_media_id: 0
        })
        setSelectedSocialMedia(null)
        setFormModal(true)
    }

    const editSocialMedia = (v) => {
        setModalTitle('Edit Social Media')
        setDefaultValues({
            emp_social_media_id: v.emp_social_media_id,
            social_media: v.social_media,
            other_social_media: v.other_social_media,
            username: v.username
        })
        setSelectedSocialMedia(v.social_media)
        setFormModal(true)
    }

    const saveSocialMedia = (v) => {
        setSaving(true)
        let payload={
            emp_social_media_id: v.emp_social_media_id,
            employee_code: props.emp_code,
            social_media: selectedSocialMedia,
            other_social_media: v.other_social_media ?? null,
            username: v.username
        }
        SentralModel.save('EmployeeSocialMedia', payload, v.emp_social_media_id).then((res)=>{
            setFormModal(false)
            fetchEmpSocialMedia()
            message.success('Social media saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteSocialMedia = (id) => {
        message.loading('deleting social media')
        SentralModel.deleteById('EmployeeSocialMedia', id).then((res) => {
            message.destroy()
            message.success('Social media deleted', 2)
            let tmp = empSocialMedias
            let index = tmp.findIndex(el => el.emp_social_media_id === id)
            tmp.splice(index, 1)
            setEmpSocialMedias([])
            setEmpSocialMedias(tmp)
        })
    }

    const changeSocialMedia = e => {
        setSelectedSocialMedia(e)
    }

    return (
        <div>
            <div className="text-right">
                <AddButton onAdd={ addSocialMedia } >Add data</AddButton>
            </div>
            {
                loaded ?
                    (empSocialMedias.length > 0) ?
                        empSocialMedias.map((el, key) => (
                            <div key={key}>
                                <Divider orientation="left"><Title level={4}>{key+1}.</Title></Divider>
                                <ActionButton editable deleteable onEdit={() => editSocialMedia(el)} onDelete={() => deleteSocialMedia(el.emp_social_media_id)}/>
                                <Row>
                                    {
                                        el.social_media === 'SMO' ?
                                            <ProfileItem title="Social Media">{el.other_social_media ?? ''}</ProfileItem>
                                        : <ProfileItem title="Social Media">{el.detail?.description ?? ''}</ProfileItem>
                                    }
                                    <ProfileItem title="Username">{el.username}</ProfileItem>
                                </Row>
                            </div>
                        ))
                    : <Empty />
                : <Spinner />
            }
            {
                (formModal) ?
                <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveSocialMedia(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                    <Form.Item name="emp_social_media_id" hidden>
                        <Input placeholder="input placeholder" />
                    </Form.Item>
                    <Form.Item label="Social Media" name="social_media" rules={[{required:true}]}>
                        <Select showSearch style={{ width: "100%" }} placeholder="Select Social Media" optionFilterProp="children" onChange={changeSocialMedia} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {
                                socialMedias.map((el, key) => (
                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    {
                        selectedSocialMedia === 'SMO' ?
                            <Form.Item label="Other Social Media" name="other_social_media" rules={[{required:true}]}>
                                <Input placeholder="Other Social Media" />
                            </Form.Item>
                        : null
                    }
                    <Form.Item label="Username" name="username" rules={[{required:true}]}>
                        <Input placeholder="Username" />
                    </Form.Item>
                </FormModal> :
                null
            }
        </div>
    );
}

export default SocialMedia;
