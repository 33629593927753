import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { isAndroid, isIOS } from 'react-device-detect';
import { Layout } from 'antd';
import 'antd/dist/antd.min.css';
import AppContent from './layouts/AppContent'
import Login from './views/auth/Login'
import Forgot from './views/auth/Forgot'
import PasswordExpired from './views/auth/PasswordExpired'
import Approval from './views/Approval'
// import DashboardActionPlan from './views/dashboard-action-plan'
import ScrollToTop from './Hooks/ScrollToTop'
import './assets/main.css'
import './assets/size.css'
import SSO from './views/auth/SSO';
import { RedirectModal } from './components';
import DashboardActionPlanHome from './views/dashboard-action-plan/action-plan-home';
import DashboardDigitalisasi from './views/dashboard-action-plan/digitalisasi';
import DashboardActionPlanSales from './views/dashboard-action-plan/action-plan-sales';
import { RecoilRoot } from 'recoil';
import DashboardWorkingLocation from './views/dashboard-action-plan/working-location';

function App() {
  const [authenticated, setAuthenticated] = useState(true)
  const [mobile, setMobile] = useState(false)

  const ShowModalApp = () => {
    if (isAndroid || isIOS) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }

  const redirectToApp = () => {
    return window.location = "launch://karajo"
  }

  useEffect(() => {
    if (isAndroid || isIOS) {
      redirectToApp()
    }
    ShowModalApp()
    let user = localStorage.getItem('currentUser')
    if (!user) {
      setAuthenticated(false)
      if (
        document.location.pathname !== "/login" && document.location.pathname !== "/sso" && document.location.pathname !== "/approval" && document.location.pathname !== "/password-expired" && document.location.pathname !== "/action-plan-home" && document.location.pathname !== '/digitalisasi-home' && document.location.pathname !== "/working-location-dashboard" && document.location.pathname !== "/action-plan-sales"
      ) {
        window.location.href = "/login?redirect=" + window.location.pathname
      }
    } else {
      if (document.location.pathname === "/login" || document.location.pathname === "/sso") {
        window.location.href = "/"
      }
    }
  }, []);

  return (
    <RecoilRoot>
      <div>
        {
          mobile ?
            <RedirectModal id={"redirect"} showModal={mobile} onCloseModal={() => setMobile(false)} href={"intent://karajo/#Intent;scheme=launch;package=com.app.karajo;end"} />
            : null
        }
        <Router>
          <ScrollToTop />
          <Layout theme="light">
            <Layout style={{ minHeight: '100vh' }}>

              {
                (authenticated) ?
                  document.location.pathname === '/action-plan-home' ?
                    <DashboardActionPlanHome /> :
                    document.location.pathname === '/action-plan-sales' ?
                      <DashboardActionPlanSales /> :
                      document.location.pathname === '/digitalisasi-home' ?
                        <DashboardDigitalisasi /> :
                        document.location.pathname === '/working-location-dashboard' ?
                          <DashboardWorkingLocation /> :
                          <AppContent />
                  :
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/forgot-password" component={Forgot} />
                    <Route exact path="/password-expired" component={PasswordExpired} />
                    <Route exact path="/approval" component={Approval} />
                    <Route exact path="/sso" component={SSO} />
                    <Route exact path="/action-plan-home" component={DashboardActionPlanHome} />
                    <Route exact path="/action-plan-sales" component={DashboardActionPlanSales} />
                    <Route exact path="/digitalisasi-home" component={DashboardDigitalisasi} />
                    <Route exact path="/working-location-dashboard" component={DashboardWorkingLocation} />
                  </Switch>
              }

            </Layout>
          </Layout>
        </Router>
      </div>
    </RecoilRoot>
  );
}

export default App;
