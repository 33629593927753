import React from 'react'
import authData from '../../helpers/authData'
import {
    ReconciliationOutlined, FieldTimeOutlined, DollarCircleOutlined, WalletOutlined, FormOutlined,
    FileProtectOutlined, FileDoneOutlined, ProfileOutlined, SettingOutlined, BankOutlined, ClusterOutlined,
    ApartmentOutlined, ScissorOutlined, CalendarOutlined, BarsOutlined, LockOutlined, EnvironmentOutlined,
    UserOutlined, CarOutlined, LoginOutlined, SolutionOutlined, BranchesOutlined, EditOutlined, SoundOutlined,
    AimOutlined, FallOutlined, RiseOutlined, SnippetsOutlined, ExceptionOutlined, InfoCircleOutlined, BarcodeOutlined,
    FileTextOutlined, SyncOutlined, QrcodeOutlined, LogoutOutlined, TeamOutlined, ScheduleOutlined, AppstoreOutlined, ControlOutlined
} from '@ant-design/icons';

export const documentationMenus = [
    {
        name: 'Exit Clearance',
        to: '/documentation/exit-clearance',
        icon: <ExceptionOutlined />,
        role: ["USER"],
        key: 'documentation-exit-clearance'
    },
    {
        name: 'SOP',
        to: '/documentation/sop',
        icon: <FileProtectOutlined />,
        role: ["USER"],
        key: 'documentation-sop'
    },
    {
        name: 'Classification SOP',
        to: '/documentation/classification-iso',
        icon: <FileProtectOutlined />,
        role: ["USER"],
        key: 'documentation-classification-sop'
    },
    {
        name: 'Company Regulations',
        to: '/documentation/regulation',
        icon: <FileDoneOutlined />,
        role: ["USER"],
        key: 'documentation-company-regulation'
    },
    {
        name: 'User Manual',
        to: '/',
        icon: <ProfileOutlined />,
        role: ["USER"],
        component: React.forwardRef(() => (
            <a href={process.env.REACT_APP_API_FILE + '/user_manual/f7042c31-c103-447c-8970-61d25d9e2f50.pdf'} target="_blank" rel="noopener noreferrer">
                <ProfileOutlined /> User Manual
            </a>
        )),
        key: 'documentation-user-manual'
    },
    {
        name: 'Legalitas',
        to: '/documentation/legalitas',
        icon: <FileProtectOutlined />,
        role: ["USER"],
        key: 'documentation-legalitas'
    },
]

export const submissionMenus = [
    {
        name: 'Leave',
        to: '/leave',
        icon: <ReconciliationOutlined />,
        role: ["USER"],
        key: 'submission-menu-leave'
    }, {
        name: 'Overtime',
        to: '/overtime',
        icon: <FieldTimeOutlined />,
        role: ["USER"],
        key: 'submission-menu-overtime'
    }, {
        name: 'Fund',
        icon: <DollarCircleOutlined />,
        role: ["USER"],
        key: 'submission-menu-fund',
        items: [{
            name: 'Reimbursement',
            to: '/reimbursement',
            role: ["USER"],
            key: 'submission-menu-reimbursement'
        }, {
            name: 'Entertainment',
            to: '/entertainment',
            role: ["USER"],
            key: 'submission-menu-entertainment'
        }, {
            name: 'Medical',
            to: '/medical',
            role: ["USER"],
            key: 'submission-menu-medical'
        }, {
            name: 'Commission',
            to: '/commission',
            role: ["USER"],
            key: 'submission-menu-commission'
        }]
    }, {
        name: 'Business Trip',
        to: '/business-trip',
        icon: <CarOutlined />,
        role: ["USER"],
        key: 'submission-menu-business-trip'
    }, {
        name: 'Office Stationery',
        to: '/office-stationery',
        icon: <ScissorOutlined />,
        role: ["USER"],
        key: 'submission-menu-office-stationery'
    }, {
        name: 'Purchase Request',
        to: '/purchase-request',
        icon: <WalletOutlined />,
        role: ["USER"],
        key: 'submission-menu-purchase-request'
    }, {
        name: 'Voucher',
        to: '/voucher',
        icon: <QrcodeOutlined />,
        role: ["USER"],
        key: 'submission-menu-voucher',
    }, {
        name: 'Training & Certification',
        to: '/request-training',
        icon: <AimOutlined />,
        role: ["USER"],
        key: 'submission-menu-training-certification'
    }, {
        name: 'CRF',
        to: '/crf',
        icon: <FileDoneOutlined />,
        role: ["USER"],
        key: 'submission-menu-crf'
    },
    {
        name: 'Recruitment',
        to: '/recruitment',
        icon: <SolutionOutlined />,
        role: ["USER"],
        key: 'submission-menu-recruitment'
    },
    {
        name: 'Resign',
        to: '/resign',
        icon: <LogoutOutlined />,
        role: ["USER"]
    },
    {
        name: 'Send Reward & Punishment',
        to: '/send-reward-punishment',
        icon: <TeamOutlined />,
        role: ["USER"]
    }
]

if (authData && (authData?.division_id === 24 || authData?.division_id === 25)) {
    submissionMenus.push({
        name: 'Software Development',
        to: '/softdev',
        icon: <FileDoneOutlined />,
        role: ["USER"],
        key: 'software-development'
    })
}

export const payrollMenus = [
    {
        name: 'Payroll',
        to: '/payroll',
        icon: <DollarCircleOutlined />,
        role: ["USER"],
        key: 'payroll-menu-payroll'
    },
    // {
    //     name: 'My KPI',
    //     to: '/my-kpi',
    //     icon: <UserOutlined />,
    //     role: ["USER"],
    //     key: 'payroll-menu-my-kpi'
    // }, 
    {
        name: 'Setup',
        to: '/payroll/setup',
        icon: <SettingOutlined />,
        role: ["ADMIN", "HR"],
        key: 'payroll-menu-setup'
    }, {
        name: 'Schema',
        to: '/payroll/schema',
        icon: <BarsOutlined />,
        role: ["ADMIN", "HR"],
        key: 'payroll-menu-schema'
    }, {
        name: 'Payment',
        to: '/payroll/payment',
        icon: <WalletOutlined />,
        role: ["ADMIN", "HR"],
        key: 'payroll-menu-payment'
    }, {
        name: 'SPT',
        to: '/payroll/spt',
        icon: <FileProtectOutlined />,
        role: ["ADMIN", "HR"],
        key: 'payroll-menu-spt'
    }, {
        name: 'Sync',
        to: '/payroll/sync',
        icon: <SyncOutlined />,
        role: ["ADMIN", "HR"],
        key: 'payroll-menu-sync'
    }
]

export const managementMenus = [
    {
        name: 'Employee',
        icon: <UserOutlined />,
        role: ["ADMIN", "HR"],
        key: 'management-menu-employee',
        items: [{
            name: 'Status',
            to: '/management/employee-status',
            role: ["ADMIN", "HR"],
            key: 'management-menu-employee-status'
        }, {
            name: 'Leave Rights',
            to: '/management/leave-rights',
            role: ["ADMIN", "HR"],
            key: 'management-menu-leave-rights'
        }, {
            name: 'Medical Allowance',
            to: '/management/medical-allowance',
            role: ["ADMIN", "HR"],
            key: 'management-menu-medical-allowance'
        }]
    }, {
        name: 'Announcement',
        to: '/management/announcement',
        icon: <SoundOutlined />,
        role: ["ADMIN", "HR", "GA"],
        key: 'management-menu-announcement'
    }, {
        name: 'Manual Attendance Setting',
        to: '/management/manual-attendance-setting',
        icon: <SettingOutlined />,
        role: ["SUPERADMIN", "ADMIN", "HR"],
        key: 'management-menu-manual-attendance-setting'
    }, {
        name: 'Master Job Desc',
        to: '/management/master-job',
        icon: <FileTextOutlined />,
        role: ["SUPERADMIN", "HEAD"],
        key: 'management-master-job-desc-menu'
    }, {
        name: 'Important Date',
        to: '/management/important-date',
        icon: <CalendarOutlined />,
        role: ["ADMIN", "HR"],
        key: 'management-menu-important-date'
    }, {
        name: 'Approval Schema',
        to: '/management/approval-schema',
        icon: <FileProtectOutlined />,
        role: ["ADMIN", "HR"],
        key: 'management-menu-approval-schema'
    }, {
        name: 'Budget',
        to: '/management/budget',
        icon: <WalletOutlined />,
        role: ["ADMIN", "HEAD", "HR"],
        key: 'management-menu-budget'
    }, {
        name: 'Comission',
        to: '/management/master-comission',
        icon: <WalletOutlined />,
        role: ["ADMIN", "HEAD", "HR"],
        key: 'management-menu-comission'
    }, {
        name: 'Validity Hour',
        to: '/management/validity-hour',
        icon: <CalendarOutlined />,
        role: ["ADMIN", "HR"],
        key: 'management-menu-validity-hour'
    }, {
        name: 'Vacancy',
        to: '/management/vacancy',
        icon: <SolutionOutlined />,
        role: ["ADMIN", "HR"],
        key: 'management-menu-vacancy'
    }, {
        name: 'Appraisal',
        icon: <FormOutlined />,
        role: ["ADMIN", "HR", "HEAD"],
        key: 'management-menu-appraisal',
        items: [{
            name: 'Component',
            to: '/management/appraisal/component',
            role: ["ADMIN", "HR", "HEAD"],
            key: 'management-menu-appraisal-component'
        }, {
            name: 'Template Component',
            to: '/management/template-component',
            role: ["ADMIN", "HR"],
            key: 'management-menu-master-item'
        }, {
            name: 'Appraisal',
            to: '/management/appraisal',
            role: ["ADMIN", "HR", "HEAD"],
            key: 'management-menu-apprisal'
        }]
    }, {
        name: 'Training & Certification',
        to: '/training',
        icon: <AimOutlined />,
        role: ["ADMIN", "HR"],
        key: 'management-menu-training-certification'
    }, {
        name: 'Reward',
        to: '/management/reward',
        icon: <RiseOutlined />,
        role: ["HR"],
        key: 'management-menu-reward'
    }, {
        name: 'Punishment',
        to: '/management/punishment',
        icon: <FallOutlined />,
        role: ["HR"],
        key: 'management-menu-punishment'
    }, {
        name: 'Vote',
        to: '/management/vote',
        icon: <FormOutlined />,
        role: ["HR", "GA"],
        key: 'management-menu-vote'
    }, {
        name: 'Test',
        icon: <BarsOutlined />,
        role: ["ADMIN", "HR"],
        key: 'management-menu-test',
        items: [{
            name: 'Multiple Choice',
            to: '/management/test/multiple-choice',
            role: ["ADMIN", "HR"],
            key: 'management-menu-multiple-choice'
        }, {
            name: 'Essay',
            to: '/management/test/essay',
            role: ["ADMIN", "HR"],
            key: 'management-menu-test-essay'
        }]
    },
]

export const organizationMenus = [
    {
        name: 'Employee',
        to: '/organization/employee',
        icon: <UserOutlined />,
        role: ["ADMIN", "HR", "USER"],
        key: 'organization-menu-employee'
    }, {
        name: 'Company Information',
        to: '/organization/company',
        icon: <BankOutlined />,
        role: ["ADMIN", "HR"],
        key: 'organization-menu-company'
    }, {
        name: 'Branch',
        to: '/organization/branch',
        icon: <BranchesOutlined />,
        role: ["ADMIN", "HR"],
        key: 'organization-menu-branch'
    }, {
        name: 'Division',
        to: '/organization/division',
        icon: <ClusterOutlined />,
        role: ["ADMIN", "HR"],
        key: 'organization-menu-division'
    }, {
        name: 'Position',
        to: '/organization/position',
        icon: <ApartmentOutlined />,
        role: ["ADMIN", "HR"],
        key: 'organization-menu-position'
    }, {
        name: 'KPI',
        to: '/organization/kpi',
        icon: <EditOutlined />,
        role: ["HR"],
        key: 'organization-menu-kpi'
    }, {
        name: 'Extension',
        to: '/organization/extension',
        icon: <UserOutlined />,
        role: ["USER"],
        key: 'organization-menu-extension'
    }
]

export const recruitmentMenus = [
    {
        name: 'Employee',
        to: '/organization/employee',
        icon: <UserOutlined />,
        role: ["HR", "ADMIN"],
        key: 'recruitment-menu-employee'
    }, {
        name: 'Company Information',
        to: '/organization/company',
        icon: <BankOutlined />,
        role: ["HR", "ADMIN"],
        key: 'recruitment-menu-company'
    }, {
        name: 'Branch',
        to: '/organization/branch',
        icon: <BranchesOutlined />,
        role: ["ADMIN", "HR"],
        key: 'recruitment-menu-branch'
    }, {
        name: 'Division',
        to: '/organization/division',
        icon: <ClusterOutlined />,
        role: ["ADMIN", "HR"],
        key: 'recuritment-menu-division'
    }, {
        name: 'Position',
        to: '/organization/position',
        icon: <ApartmentOutlined />,
        role: ["ADMIN", "HR"],
        key: 'recruitment-menu-position'
    }, {
        name: 'KPI',
        to: '/organization/kpi',
        icon: <EditOutlined />,
        role: ["HR"],
        key: 'recruitment-menu-kpi'
    },
]

export const reportMenus = [
    {
        name: 'Employee',
        to: '/report/employee',
        icon: <UserOutlined />,
        role: ["ADMIN", "HR", "GA"],
        key: 'report-menu-employee'
    },
    {
        name: 'Annual Report',
        to: '/report/annual-report',
        icon: <FileDoneOutlined />,
        role: ["HR"],
        key: 'report-manu-annual-report'
    },
    {
        name: 'Daily Activity',
        to: '/report/daily-activity',
        icon: <UserOutlined />,
        role: ["HEAD", "ADMIN"],
        key: 'report-menu-daily-activity'
    },
    {
        name: 'Daily & Working Location',
        to: '/report/new-daily-activity',
        icon: <UserOutlined />,
        role: ["SUPERADMIN"],
        key: 'report-menu-daily-activity-working-location'
    },
    {
        name: 'Export Daily & Working Location',
        to: '/report/export-daily-working',
        icon: <UserOutlined />,
        role: ["SUPERADMIN"],
        key: 'report-menu-export-daily-activity-working-location'
    },
    {
        name: 'Activity',
        icon: <LoginOutlined />,
        role: ["ADMIN", "HR", "GA", "OPR", "HEAD"],
        key: 'report-menu-activity',
        items: [
            {
                name: 'Attendance',
                to: '/report/attendance',
                role: ["ADMIN", "HR", "GA", "HEAD"],
                key: 'report-menu-attendance'
            }, {
                name: 'Daily Jobs',
                to: '/report/jobs',
                role: ["ADMIN", "HR", "GA"],
                key: 'report-menu-daily-jobs'
            }, {
                name: 'Payroll',
                to: '/report/payroll',
                role: ["ADMIN", "HR", "GA"],
                key: 'report-menu-payroll'
            },
            {
                name: 'Payroll Operational',
                to: '/report/payroll-operational',
                role: ["ADMIN", "HR", "GA", "OPR"],
                key: 'report-menu-payroll-operational'
            }, {
                name: 'Score Card Employee',
                to: '/report/score-card',
                role: ["ADMIN", "HR", "GA", "HEAD"],
                key: 'report-menu-score-card'
            },
            // {
            //     name: 'Vote',
            //     to: '/report/vote',
            //     role: ["ADMIN", "HR", "GA"]
            // }, 
            {
                name: 'Vote Result',
                to: '/report/vote-result',
                role: ["ADMIN", "HR", "GA"],
                key: 'report-menu-vote-result'
            },
            {
                name: 'Training Hours',
                to: '/report/training-hours',
                role: ["ADMIN", "HR", "GA"]
            },

        ]
    },
    {
        name: 'Information',
        icon: <InfoCircleOutlined />,
        role: ["ADMIN", "HR", "GA", "ACC"],
        key: 'report-menu-information',
        items: [
            {
                name: 'Medical History',
                to: '/report/medical-history',
                role: ["ADMIN", "HR", "GA"],
                key: 'report-menu-medical-history'
            },
            {
                name: 'Budget',
                to: '/report/budget',
                role: ["ADMIN", "ACC"],
                key: 'report-menu-budget'
            }
        ]
    },
    {
        name: 'Submission',
        icon: <SolutionOutlined />,
        role: ["ADMIN", "HR", "GA"],
        key: 'report-menu-submission',
        items: [
            {
                name: 'Leave',
                to: '/report/leave',
                role: ["ADMIN", "HR", "GA"],
                key: 'report-menu-leave'
            }, {
                name: 'Overtime',
                to: '/report/overtime',
                role: ["ADMIN", "HR", "GA"],
                key: 'report-menu-overtime'
            }, {
                name: 'Reimbursement',
                to: '/report/reimbursement',
                role: ["ADMIN", "HR", "GA"],
                key: 'report-menu-reimbursement'
            }, {
                name: 'Entertainment',
                to: '/report/entertainment',
                role: ["ADMIN", "HR", "GA"],
                key: 'report-menu-entertainment'
            }, {
                name: 'Medical',
                to: '/report/medical',
                role: ["ADMIN", "HR", "GA"],
                key: 'report-menu-medical'
            }, {
                name: 'Business Trip',
                to: '/report/business-trip',
                role: ["ADMIN", "HR", "GA"],
                key: 'report-menu-business-trip'
            }, {
                name: 'Office Stationery',
                to: '/report/office-stationery',
                role: ["ADMIN", "HR", "GA"],
                key: 'report-menu-office-stationery'
            }, {
                name: 'Purchase Request',
                to: '/report/purchase-request',
                role: ["ADMIN", "HR", "GA"],
                key: 'report-menu-purchase-request'
            }, {
                name: 'Training',
                to: '/report/training',
                role: ["ADMIN", "HR", "GA"],
                key: 'report-menu-training'
            },
        ]
    },
    {
        name: 'KPI',
        icon: <FormOutlined />,
        role: ["ADMIN", "HR"],
        key: 'report-menu-kpi',
        items: [
            {
                name: 'Component',
                to: '/report/component-kpi',
                role: ["ADMIN", "HR"],
                key: 'report-component-kpi'
            },
            // {
            //     name: 'KPI',
            //     to: '/report/kpi',
            //     role: ["ADMIN", "HR"],
            //     key: 'report-menu-kpi-children'
            // }
        ]
    },
    {
        name: 'Vacancy',
        to: '/report/vacancy',
        icon: <UserOutlined />,
        role: ["ADMIN", "HR", "GA"],
        key: 'report-menu-vacancy'
    },
    {
        name: 'Action Plan',
        to: '/report/action-plan',
        icon: <FileDoneOutlined />,
        role: ["ADMIN", "HR", "GA"],
        key: 'report-manu-action-plan'
    },
]

export const dashboardMenus = [
    {
        name: 'Attendance',
        to: '/dashboard/attendance',
        icon: <LoginOutlined />,
        role: ["ADMIN", "HR"],
        key: 'dashboard-menu-attendance'
    },
    {
        name: 'Admin',
        to: '/dashboard/admin',
        icon: <LoginOutlined />,
        role: ["ADMIN", "HR"],
        key: 'dashboard-menu-admin'
    },
    {
        name: 'Action Plan',
        to: '/dashboard/action-plan',
        icon: <LoginOutlined />,
        role: ["ADMIN", "HR"],
        key: 'dashboard-menu-action-plan'
    }
]

export const dashboardHrMenus = [
    {
        name: 'Employee',
        to: '/dashboardHr/employee',
        icon: <TeamOutlined />,
        role: ["ADMIN", "HR"],
        key: 'dashboard-hr-menu-employee'
    },
    {
        name: 'Attendance',
        to: '/dashboardHr/attendance',
        icon: <ScheduleOutlined />,
        role: ["ADMIN", "HR"],
        key: 'dashboard-hr-menu-attendance'
    },
    {
        name: 'Admin',
        to: '/dashboardHr/admin',
        icon: <AppstoreOutlined />,
        role: ["ADMIN", "HR"],
        key: 'dashboard-hr-menu-admin'
    },
]

export const settingMenus = [
    {
        name: 'User',
        to: '/setting/user',
        icon: <LockOutlined />,
        role: ["SUPERADMIN"],
        key: 'setting-menu-user'
    },
    {
        name: 'Score Card Weight',
        to: '/setting/score-card-weight',
        icon: <ControlOutlined />,
        role: ["SUPERADMIN"],
        key: 'setting-menu-score-card-weight'
    },
    {
        name: 'Attendance Area',
        to: '/setting/area',
        icon: <EnvironmentOutlined />,
        role: ["ADMIN", "HR"],
        key: 'setting-menu-attendance'
    },
    {
        name: 'General',
        to: '/setting/general',
        icon: <SettingOutlined />,
        role: ["SUPERADMIN"],
        key: 'setting-menu-general'
    },
    {
        name: 'Company',
        to: '/setting/company',
        icon: <BankOutlined />,
        role: ["SUPERADMIN"],
        key: 'setting-menu-company'
    },
    {
        name: 'Email',
        to: '/setting/email',
        icon: <SolutionOutlined />,
        role: ["SUPERADMIN"],
        key: 'setting-menu-email'
    },
    {
        name: 'Bank',
        to: '/setting/bank',
        icon: <BankOutlined />,
        role: ["SUPERADMIN"],
        key: 'setting-menu-bank'
    },
    {
        name: 'COA',
        to: '/setting/coa',
        icon: <DollarCircleOutlined />,
        role: ["SUPERADMIN"],
        key: 'setting-menu-coa'
    },
    {
        name: 'Instructions',
        to: '/setting/instruction',
        icon: <SnippetsOutlined />,
        role: ["HR"],
        key: 'setting-menu-instruction'
    },
    {
        name: 'Location',
        icon: <EnvironmentOutlined />,
        role: ["SUPERADMIN"],
        key: 'setting-menu-location',
        items: [{
            name: 'Country',
            to: '/setting/country',
            role: ["SUPERADMIN"],
            key: 'setting-menu-country'
        }, {
            name: 'Province',
            to: '/setting/province',
            role: ["SUPERADMIN"],
            key: 'setting-menu-province'
        }, {
            name: 'City',
            to: '/setting/city',
            role: ["SUPERADMIN"],
            key: 'setting-menu-city'
        }]
    },
    {
        name: 'Version Patch',
        to: '/setting/version-patch',
        icon: <BarcodeOutlined />,
        role: ["SUPERADMIN"],
        key: 'setting-menu-version-patch'
    },
    // {
    //     name: 'Application',
    //     to: '/setting/application',
    //     icon: <BarcodeOutlined />,
    //     role: ["SUPERADMIN"]
    // },
]

export const generalAffairMenus = [
    {
        name: 'Stationery',
        icon: <ScissorOutlined />,
        role: ["SUPERADMIN", "GA"],
        key: 'ga-stationery',
        items: [
            {
                name: 'Category',
                to: '/stationery/category',
                role: ["SUPERADMIN", "GA"],
                key: 'ga-stationery-category'
            }, {
                name: 'Item',
                to: '/stationery/item',
                role: ["SUPERADMIN", "GA"],
                key: 'ga-stationery-item'
            }, {
                name: 'Goods Receipt',
                to: '/stationery/goods-receipt',
                role: ["SUPERADMIN", "GA"],
                key: 'ga-stationery-good-receipt',
            }, {
                name: 'Goods Distribution',
                to: '/stationery/goods-distribution',
                role: ["SUPERADMIN", "GA"],
                key: 'ga-stationery-good-distribution'
            }, {
                name: 'Stock',
                to: '/stationery/stock',
                role: ["SUPERADMIN", "GA"],
                key: 'ga-stationery-stock'
            }
        ]
    },
    {
        name: 'Asset',
        icon: <BarsOutlined />,
        role: ["SUPERADMIN", "GA"],
        key: 'ga-asset',
        items: [
            {
                name: 'Vendor',
                to: '/asset/vendor',
                role: ["SUPERADMIN", "GA"],
                key: 'ga-vendor'
            }, {
                name: 'Category',
                to: '/asset/category',
                role: ["SUPERADMIN", "GA"],
                key: 'ga-category'
            }, {
                name: 'Item',
                to: '/asset/item',
                role: ["SUPERADMIN", "GA"],
                key: 'ga-item'
            }, {
                name: 'Goods Receipt',
                to: '/asset/goods-receipt',
                role: ["SUPERADMIN", "GA"],
                key: 'ga-good-receipt'
            }, {
                name: 'Goods Distribution',
                to: '/asset/goods-distribution',
                role: ["SUPERADMIN", "GA"],
                key: 'ga-goods-distribution'
            }, {
                name: 'Stock',
                to: '/asset/stock',
                role: ["SUPERADMIN", "GA"],
                key: 'ga-stock'
            }
        ]
    },
    {
        name: 'Letter',
        to: '/letter',
        icon: <FileTextOutlined />,
        role: ["SUPERADMIN", "GA"],
        key: 'ga-letter'
    },
]

export const actionPlanMenus = [
    {
        name: 'Action Plan',
        to: '/action-plan',
        icon: <FileDoneOutlined />,
        role: ["SUPERADMIN", "USER", "HR", "GA"],
        key: 'action-plan-menu-action-plan'
    }
]

export const rewardPunishmentMenu = [
    {
        name: 'List Reward Punishment',
        to: 'list-reward-punishment',
        icon: <TeamOutlined />,
        role: ["SUPERADMIN", "HR"],
        key: 'reward-punishment-menu'
    }
]

export const itInfraMenu = [
    {
        name: 'Dashboard Infra',
        to: '/it-infra/dashboard-infra',
        icon: <TeamOutlined />,
        role: ["SUPERADMIN", "HEAD", "USER"],
        key: 'it-infra-dashboard-menu'
    }
]