import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, Button, Tabs, Space, Form, Input, DatePicker, Select, Drawer, message, InputNumber, Tag, Modal, Badge, TimePicker, Slider, Progress, Checkbox, Divider } from 'antd';
import { CalendarOutlined, ClockCircleOutlined, CheckOutlined, FileTextOutlined, PlusCircleOutlined, FilterOutlined, FileExcelOutlined } from '@ant-design/icons';
import { DeleteButton, FormModal, ShowButton, Spinner, TakeButton, Uploader } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import authData from '../../../helpers/authData';
import { DataTable, EditButton } from '../../../components';
import { toFullDate } from '../../../utils/dateHandler';
import { getDatesInRange } from '../../../helpers/dateRange';
import FormatTicketIt from './TicketFormat/FormatTicketIt';
import FormatTicketPresales from './TicketFormat/FormatTicketPresales';
import FormatTicketSoftware from './TicketFormat/FormatTicketSoftware';
import FormatTicketGeneral from './TicketFormat/FormatTicketGeneral';
import ModalCompany from './ModalFormat/ModalCompany';
import ModalEditProgressDaily from './ModalFormat/ModalEditProgressDaily';
import ModalEditProgressTask from './ModalFormat/ModalEditProgressTask';
import ListTicket from './Ticket/ListTicket';
import { useHistory } from 'react-router-dom';
import ITInfra from './Tabs/ITInfra';

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

const DailyActivity = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [loaded, setLoaded] = useState(false);
    const [loadedSummary, setLoadedSummary] = useState(true);
    const [saving, setSaving] = useState(false);
    const [myTasks, setMyTasks] = useState([]);
    const [assignTasks, setAssignTasks] = useState([]);
    const [defaultValues, setDefaultValues] = useState({ job_id: 0, finished: false });
    const [defaultValueEdit, setDefaultValueEdit] = useState({});
    const [action, setAction] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [taskTo, setTaskTo] = useState([]);
    const [tab, setTab] = useState('in');
    const [disabled, setDisabled] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [isHead, setIsHead] = useState(null);
    // eslint-disable-next-line
    const [diffDate, setDiffDate] = useState([]);
    const [defaultTab, setDefaultTab] = useState('task');
    const [defaultStat, setDefaultStat] = useState('');
    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [measurements, setMeasurements] = useState([]);
    const [listWorkingLocation, setListWorkingLocation] = useState([]);
    const [division, setDivision] = useState([]);
    const [divisionId, setDivisionId] = useState(0);
    const [category, setCategory] = useState('TASK/DAILY ACTIVITY');
    const [modalAddTask, setModalAddTask] = useState(false);
    const [modalCompany, setModalCompany] = useState(false);
    const [editProgressDailyModal, setEditProgressDailyModal] = useState(false);
    const [editProgressTaskModal, setEditProgressTaskModal] = useState(false);
    const [modalAddTaskTitle, setModalAddTaskTitle] = useState('');
    const [editProgressModalTitle, setEditProgressModalTitle] = useState('');
    const [dailyProgressData, setDailyProgressData] = useState([]);
    const [taskProgressData, setTaskProgressData] = useState([]);
    const [showModalAddProgress, setShowModalAddProgress] = useState(false);
    const [showModalAddTaskProgress, setShowModalAddTaskProgress] = useState(false);
    const [titleModalCompany, setTitleModalCompany] = useState('');
    const [progressValue, setProgressValue] = useState(0);
    const [progressDailyValue, setProgressDailyValue] = useState(0);
    const [percentageTask, setPercentageTask] = useState(0);
    const [periods, setPeriods] = useState([]);
    const [datePeriods, setDatePeriods] = useState([]);
    const [loadedPeriod, setLoadedPeriod] = useState(false);
    const modalAddProgressTitle = useState('Add Daily Progress');
    const modalAddTaskProgressTitle = useState('Add Task Progress');
    const [attachmentFile, setAttachmentFile] = useState(null);
    const [myself, setMyself] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [categoryRequest, setCategoryRequest] = useState('Product Recomendation');
    const [isTicketPresales, setIsTicketPresales] = useState(false);
    const [isMilestoneTask, setIsMilestoneTask] = useState(false);
    const [ticketInfo, setTicketInfo] = useState({});
    const [attachmentInfo, setAttachmentInfo] = useState(null)
    const [confirmFinished, setConfirmFinished] = useState("confirm");
    // eslint-disable-next-line
    const [confirmFinishedValue, setConfirmFinishedValue] = useState("");
    const [modalConfirm, setModalConfirm] = useState(false);
    const [remarksFinished, setRemarkFinished] = useState(null);
    const [attachmentConfirm, setAttachmentConfirm] = useState(null);
    const [saveDataTask, setSaveDataTask] = useState({});
    const [showAttachmentFinished, setShowAttachmentFinished] = useState(null);
    const [myProgressPercentage, setMyProgressPercentage] = useState(0);
    const [multipleAssign, setMultipleAssign] = useState([]);
    // const [listAttachment, setListAttachment] = useState([])
    // const [remarks, setRemarks] = useState('')

    const fileUploaded = (v) => {
        setAttachmentFile(v.message)
        setUploading(true)
    }

    const fileUploadFinish = (v) => {
        setAttachmentConfirm(v.message)
        setUploading(true)
    }

    const fetchPeriods = (year) => {
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, { orderBy: 'from_date', order: 'asc' }).then(res => {
            setPeriods(res.data)
        })
    }

    const fetchTask = (v) => {
        let payload = {
            ...v,
            division_id: authData.division_id
        }

        SentralModel.action('Task', 'getAllTask', payload).then(res => {
            setMyTasks(res.data.my_task)
            setAssignTasks(res.data.assign_task)
            setLoaded(true)
        })
    }

    const fetchEmployees = () => {
        setLoaded(false)
        SentralModel.list('Employee', { filter: [['is_active', '=', '1'], ['employee_status', ['ESPR', 'ESPB', 'ESKN']]], orderBy: 'name', order: 'asc' })
            .then(res => {
                setLoaded(true)
                setEmployees(res.data)
            })
    }

    const fetchMyData = () => {
        SentralModel.get('Employee', {}, authData.employee_code)
            .then((res) => {
                setSelectedDivision(res.data.division_id)
                setIsHead(res.data.is_division_head === '0' ? false : true)
            })
    }

    const fetchCompany = () => {
        SentralModel.action("Company", "getCompany", null, 0)
            .then((res) => {
                setCompanies(res.data)
            })
    }

    const fetchMeasurements = () => {
        setLoaded(false)
        SentralModel.list('Measurement')
            .then(res => {
                setLoaded(true)
                const data = res.data.filter((el) => el.measurement_id === 1 || el.measurement_id === 6)
                setMeasurements(data)
            })
    }

    const fetchListProgressTask = (v) => {
        let percentage = 0
        SentralModel.action("Task", "listProgressTask", null, v).then((res) => {
            const v = res.data
            const daily = v.filter((el) => el.data_from === "DAILY ACTIVITY" || el.data_from === "DAILY ATTENDANCE")
            const task = v.filter((el) => el.data_from === "TASK KARAJO")
            if (daily.length > 0) {
                setDailyProgressData(daily)
            }
            if (task.length > 0) {
                task.forEach((el) => {
                    percentage += el.progress
                })
                setPercentageTask(percentage)
                setTaskProgressData(task)
            }
        })
    }

    const fetchListWorkLocation = () => {
        SentralModel.action("WorkingLocation", "getWorkingLocation", { employee_code: authData.employee_code }, 0).then((res) => {
            const v = res.data
            let data = []

            v.map((el, key) => {
                return data.push({ ...el, index: key })
            })

            setListWorkingLocation(data)
        })
    }

    const fetchDivision = () => {
        SentralModel.action("Division", "getDivision", null, null).then((res) => {
            const v = res.data.filter((el) => el.division_id !== 2 && el.division_id !== 9 && el.division_id !== 18 && el.division_id !== 25 && el.division_id !== 35)
            setDivision(v)
        })
    }

    useEffect(() => {
        fetchTask({ start_date: null, end_date: null });
        fetchMyData();
        fetchEmployees();
        fetchCompany();
        fetchMeasurements();
        fetchListWorkLocation();
        fetchDivision();

    }, [])

    const fetchReport = (ft) => {
        setLoadedSummary(false)
        SentralModel.action('Task', 'summary', ft, 0).then((res) => {
            setSummaryData(res.data)
            setLoadedSummary(true)
        })
    }

    const editTask = (v, stat) => {
        if (v.data_from === "TICKET PRESALES") {
            setTicketInfo(v)
            setIsTicketPresales(true)
        } else {
            setIsTicketPresales(false)
        }

        if (v.data_from === "MILESTONE TASK") {
            setIsMilestoneTask(true)
        } else {
            setIsMilestoneTask(false)
        }

        setDefaultValues({
            all_app_task_id: v.id,
            emp_task_id: v.task_id,
            employee_code: v.created_by,
            category: category,
            category_task_code: v.category_task_code,
            milestone_id: v.milestone_id,
            milestone_task_id: v.milestone_task_id,
            data_from: v.data_from,
            task_to: v.assign_to,
            description: v.description,
            remarks_presales: v.progress[0]?.job_description,
            start_date: moment(v.start_date),
            deadline: moment(v.due_date),
            note: v.reply,
            finished_date: v.finished_date === null ? null : moment(v.finished_date),
            status: v.status === 'Finished' ? true : false,
            confirm_finished: v.confirm_finished,
            attachment_clause: v.attachment_clause,
            remark_finished: v.remark_finished
        })

        setShowAttachmentFinished(v.finished_attachment)
        setConfirmFinishedValue(v.confirm_finished)
        setAttachmentInfo(v.attachment)
        setTaskProgressData(v.progress)
        setDefaultStat(stat)
        setEditProgressTaskModal(true)
        setEditProgressModalTitle("Update Progress Task")

        let progress = 0
        let myProgress = 0
        let arrAssign = []
        v.progress.forEach((el) => {
            if (el.employee_code === authData.employee_code) {
                myProgress += el.progress
            }

            progress += el.progress
        })

        v.multiple_task_to.forEach((el) => {
            arrAssign.push(el)
        });

        let progressAverage = progress / parseInt(v.multiple_task_to.length)
        // let progressAverage = progress
        setMyProgressPercentage(myProgress)
        setPercentageTask(progressAverage)
        setMultipleAssign(arrAssign)
        const date1 = new Date(v.start_date);
        const date2 = (Date.parse(moment(new Date()).format('YYYY-MM-DD')) > Date.parse(moment(v.due_date).format('YYYY-MM-DD'))) ? new Date() : new Date(v.due_date);
        const dates = getDatesInRange(date1, date2)
        setDiffDate(dates)
    }

    const editDaily = (v, stat) => {
        setDefaultValueEdit({
            all_app_task_id: v.id,
            emp_task_id: v.task_id,
            employee_code: v.created_by,
            category: category,
            data_from: v.data_from,
            task_to: v.assign_to,
            description: v.description,
            start_date: moment(v.start_date),
            deadline: moment(v.due_date),
            note: v.reply,
            finished_date: v.finished_date === null ? null : moment(v.finished_date),
            status: v.status === 'Finished' ? true : false,
        })
        setDailyProgressData(v.progress);

        setDefaultStat(stat)
        setAttachmentInfo(v.attachment)
        setEditProgressDailyModal(true)
        setEditProgressModalTitle("Update Daily Activity")
    }

    const saveJob = (v) => {
        setSaving(true)
        if (defaultTab === 'task') {
            let payload = {
                action: action,
                emp_task_id: v.emp_task_id,
                employee_code: authData.employee_code,
                data_from: myself ? "DAILY ATTENDANCE" : "TASK DAILY",
                remarks: myself ? "DAILY ATTENDANCE" : "TASK DAILY",
                measurement_id: v.measurement_id,
                task_to: taskTo,
                company_id: company === null ? null : company,
                company_name: companyName === '' ? null : companyName,
                description: v.description,
                deadline: moment(v.deadline),
                time_start: v.time ? moment(v.time[0]).format('HH:mm') : null,
                time_end: v.time ? moment(v.time[1]).format('HH:mm') : null,
                status: "In Progress",
                attachment: attachmentFile,
                myself: myself,
                confirm_finished: v.confirm_finished ?? null,
                attachment_clause: v.attachment_clause ?? null
            }

            SentralModel.action('Task', 'saveTask', payload, v.emp_task_id).then((res) => {
                setCategory('TASK/DAILY ACTIVITY');
                fetchTask({ start_date: null, end_date: null });
                message.success('Task Saved', 2);
                setSaving(false);
                setAttachmentFile(null);
                setModalAddTask(false);
            }).catch((err) => {
                message.warning('Failed Save Task', 2);
                setSaving(false);
            })
        }
    }

    const saveTicket = (v) => {
        let payload = {
            ...v,
            attachment: attachmentFile,
            company_name: companyName,
            division_id: divisionId
        }

        setSaving(true)
        SentralModel.action("Task", "saveTicket", payload, null).then((res) => {
            message.success("Success Add Ticket", 2)
            fetchTask({ start_date: null, end_date: null });
            setSaving(false);
            setAttachmentFile(null);
            setModalAddTask(false);
        }).catch((err) => {
            message.warning('Failed Save Task', 2);
            setSaving(false);
        })
    }

    const takeTicket = (v) => {
        SentralModel.action("Task", "takeTicket", null, v).then((res) => {
            message.success("Success Take Ticket", 2);
            fetchTask({ start_date: null, end_date: null });
        })
    }

    const saveWorkingLocation = (v) => {
        let payload = {
            ...v,
            time_start: moment(v.time[0]).format("HH:mm"),
            time_end: moment(v.time[1]).format("HH:mm"),
        }

        SentralModel.action("WorkingLocation", "addWorkingLocation", payload, v.all_app_task_id).then((res) => {
            message.success("Success Add Working Location")
            fetchListWorkLocation();
        })
    }

    const changeTab = e => {
        setTab(e)
    }

    const changeTabForm = (e) => {
        setDefaultTab(e)
    }

    const changeEmployee = e => {
        setTaskTo(e)
    }

    const changeCompany = e => {
        setCompany(e)
        const comp = companies.filter((el) => el.company_id === e)
        setCompanyName(comp[0].company_name)
    }

    const changePeriod = (v) => {
        SentralModel.action('PayrollPeriod', 'getListDate', { period_id: v }, 0).then(res => {
            setDatePeriods(res.data)
            setLoadedPeriod(true)
        })
    }

    const onChangeYear = (v) => {
        let year = moment(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    const disabledDate = (current) => {
        let currentMonth = moment().format('MM');
        let addMonth = 1;

        if (currentMonth === '12') {
            addMonth = 2;
        }

        let customDate = moment(new Date()).add(addMonth, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");
    }

    const onShowModalAddTask = () => {
        setDivisionId(0)
        setModalAddTask(true)
        setAction('add')
        setDefaultValues({
            emp_task_id: 0,
            category: category,
            employee_code: authData.employee_code,
            description: null,
            deadline: null
        })
        setDisabled(false)
        setModalAddTaskTitle("Add Daily Task")
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/task?division_id=' + selectedDivision + '&employee_code=' + selectedEmployee + '&from=' + selectedDateRange[0] + '&to=' + selectedDateRange[1])
    }

    const filterData = (v) => {
        let ft = {
            division_id: selectedDivision,
            employee_code: v.employee_code,
            date_range: (v.date_range) ? [moment(v.date_range[0]).format('YYYY-MM-DD'), moment(v.date_range[1]).format('YYYY-MM-DD')] : []
        }

        fetchReport(ft)
        setShowFilter(false)
    }

    const addCompany = (v) => {
        let payload = {
            company_name: v.company_name,
            address: v.address,
            email: v.email,
            phone_no: v.phone_no,
            phone_no_2: v.phone_no_2
        }

        SentralModel.action("Company", "saveCompany", payload, 0).then((res) => {
            message.success("Success Add Company")
            form.resetFields()
            setModalCompany(false)
            fetchCompany()
        }).catch((err) => {
            message.error("Failed Add Company")
        })
    }

    const onShowModalCompany = () => {
        setModalCompany(true)
        setTitleModalCompany('Add Company')
    }

    const addDaily = (v) => {
        let arr = []
        arr.push({
            all_app_task_id: 0,
            job_description: v.job_description,
            time: v.time,
            progress: progressDailyValue,
            time_start: moment(v.time[0]).format("HH:mm"),
            time_end: moment(v.time[1]).format("HH:mm")
        })

        if (dailyProgressData.length > 0) {
            const newData = dailyProgressData.concat(arr)
            setDailyProgressData(newData)
        } else {
            setDailyProgressData(arr)
        }
        setShowModalAddProgress(false)
    }

    const addTaskProgress = (v) => {
        let arr = []
        let percentage = 0
        let myPercentage = 0

        arr.push({
            all_progress_task_id: 0,
            job_description: v.job_description,
            remarks: v.remarks,
            progress: progressValue,
            progress_date: moment(Date.now()).format("YYYY-MM-DD"),
            employee_code: authData.employee_code
        })

        if (taskProgressData.length > 0) {
            const newData = taskProgressData.concat(arr)
            newData.forEach((el) => {
                if (el.employee_code === authData.employee_code) {
                    myPercentage += el.progress
                }
                percentage += el.progress

                // diffDate.forEach((d) => {
                //     if (moment(el.progress_date).format("YYYY-MM-DD") === moment(d).format("YYYY-MM-DD")) {
                //         const idx = diffDate.indexOf(d)
                //         diffDate.splice(idx, 1)
                //     }
                // })
            })

            // diffDate.forEach((el) => {
            //     if (moment(el).format("YYYY-MM-DD") < moment(Date.now()).format("YYYY-MM-DD")) {
            //         newData.push({
            //             all_progress_task_id: 0,
            //             job_description: "Job Not Updated",
            //             remarks: "Not Updated",
            //             progress: 0,
            //             progress_date: moment(el).format("YYYY-MM-DD")
            //         })
            //     }
            // })

            setPercentageTask(percentage / multipleAssign.length)
            setMyProgressPercentage(myPercentage)
            setTaskProgressData(newData)
        } else {
            arr.forEach((el) => {
                percentage += el.progress
                myPercentage += el.progress
                // diffDate.forEach((d) => {
                //     if (moment(el.progress_date).format("YYYY-MM-DD") === moment(d).format("YYYY-MM-DD")) {
                //         const idx = diffDate.indexOf(d)
                //         diffDate.splice(idx, 1)
                //     }
                // })
            })

            // diffDate.forEach((el) => {
            //     if (moment(el).format("YYYY-MM-DD") < moment(Date.now()).format("YYYY-MM-DD")) {
            //         arr.push({
            //             all_progress_task_id: 0,
            //             job_description: "Job Not Updated",
            //             remarks: "Not Updated",
            //             progress: 0,
            //             progress_date: moment(el).format("YYYY-MM-DD")
            //         })
            //     }
            // })

            setPercentageTask(percentage / multipleAssign.length)
            setMyProgressPercentage(myPercentage)
            setTaskProgressData(arr)
        }

        setShowModalAddTaskProgress(false)
    }

    const removeDailyProgress = (v) => {
        const dp = dailyProgressData.filter((el) => el.key !== v.key)
        if (v.all_app_task_id !== 0) {
            SentralModel.action("Task", "deleteProgress", null, v.all_progress_task_id).then((res) => {
            })
        }

        setDailyProgressData(dp)
    }

    const updateDailyProgress = (v) => {
        let payload = {
            ...v,
            data_from: "DAILY ATTENDANCE",
            daily_progress: dailyProgressData
        }

        SentralModel.action("Task", "updateProgress", payload, 0).then((res) => {
            message.success("Success", 2)
            fetchListProgressTask(v.all_app_task_id)
            fetchTask({ start_date: null, end_date: null })
            setEditProgressDailyModal(false)
        })
    }

    const removeTaskProgress = (v) => {
        let percentage = 0
        let myPercentage = 0
        const dp = taskProgressData.filter((el) => el.key !== v.key)
        if (v.all_progress_task_id !== 0) {
            SentralModel.action("Task", "deleteProgress", null, v.all_progress_task_id).then((res) => {
            })
        }

        dp.forEach((el) => {
            if (el.employee_code === authData.employee_code) {
                myPercentage += el.progress
            }

            percentage += el.progress
        })

        setPercentageTask(percentage)
        setMyProgressPercentage(myPercentage)
        setTaskProgressData(dp)
    }

    const showConfirmFinished = (v) => {
        setModalConfirm(true)
        let payload = {
            ...v
        }

        setSaveDataTask(payload)
    }

    const updateTaskProgress = (v) => {
        let payload = {
            ...v,
            data_from: v.data_from,
            daily_progress: taskProgressData,
            attachment: attachmentFile
        }

        if (percentageTask > 100) {
            message.warning("Total Percentage is More than 100%, Please fixed it", 5)
        } else {
            if (!isTicketPresales && !isMilestoneTask) {
                if (percentageTask === 100) {
                    showConfirmFinished(payload)
                } else {
                    SentralModel.action("Task", "updateTaskProgress", payload, 0).then((res) => {
                        message.success("Success Update Progress", 2)
                        fetchListProgressTask(v.all_app_task_id)
                        fetchTask({ start_date: null, end_date: null })
                        setEditProgressTaskModal(false)
                        setAttachmentFile(null)
                    }).catch((err) => {
                        message.warning("Failed Update", 2)
                    })
                }
            }
            if (isMilestoneTask) {
                SentralModel.action("Task", "updateMilestoneTask", payload, 0).then((res) => {
                    message.success("Success Update Progress", 2)
                    fetchTask({ start_date: null, end_date: null })
                    setEditProgressTaskModal(false)
                    setAttachmentFile(null)
                }).catch((err) => {
                    message.warning("Failed Update", 2)
                })
            }
            if (isTicketPresales) {
                SentralModel.action("Task", "updateTicketPresales", payload, 0).then((res) => {
                    message.success("Success Update Progress", 2)
                    fetchTask({ start_date: null, end_date: null })
                    setEditProgressTaskModal(false)
                    setAttachmentFile(null)
                }).catch((err) => {
                    message.warning("Failed Update", 2)
                })
            }
        }
    }

    const saveFinishedTask = (v) => {
        let payload = {
            ...saveDataTask,
            remark_finished: remarksFinished,
            daily_progress: taskProgressData,
            attachment: attachmentFile,
            finished_attachment: attachmentConfirm
        }

        if (saveDataTask.confirm_finished === "confirm_with_attachment" && attachmentConfirm === null) {
            message.warning("Please Complete your Requirement")
        } else {
            SentralModel.action("Task", "updateTaskProgress", payload, 0).then((res) => {
                message.success("Success Update Progress", 2)
                fetchListProgressTask(v.all_app_task_id)
                fetchTask({ start_date: null, end_date: null })
                setModalConfirm(false)
                setEditProgressTaskModal(false)
                setAttachmentFile(null)
            }).catch((err) => {
                message.warning("Failed Update", 2)
            })
        }
    }

    const saveJobItInfra = (v) => {
        let payload = {
            ...v,
        }

        SentralModel.action("ItInfra", "saveJobInfra", payload, null).then((res) => {
            message.success("Success save job")
            setModalAddTask(false)
            fetchTask({ start_date: null, end_date: null });
        }).catch((err) => {
            message.warning("Failed save job")
        })
    }

    const changeDivision = (v) => {
        setDivisionId(v)
    }

    const column = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
        }, {
            title: tab === "in" ? 'Task From' : 'Assign To',
            render: (row) => (
                tab === "in" ?
                    row.data_from === "TICKET PRESALES" ?
                        <>
                            <Row justify='center' className='mb-3'>
                                <Col>
                                    <Tag color='blue'>
                                        {row?.task_from?.name}
                                    </Tag>
                                </Col>
                            </Row>
                            <Row justify='center'>
                                <Col>
                                    <Tag color='green'>
                                        Presales: {row?.multiple_task_to?.length > 0 ? row?.multiple_task_to[0]?.task_to?.name : "-"}
                                    </Tag>
                                </Col>
                            </Row>
                        </>
                        :
                        row?.task_from?.employee_code === authData.employee_code ?
                            <Tag color='green'>Myself</Tag> :
                            <Tag color='blue'>
                                {row?.task_from?.name}
                            </Tag>
                    :
                    row.data_from === "TICKET PRESALES" ?
                        <>
                            <Row justify='center' className='mb-3'>
                                <Col>
                                    <Tag color='blue'>
                                        {row?.task_from?.name}
                                    </Tag>
                                </Col>
                            </Row>
                            <Row justify='center'>
                                <Col>
                                    <Tag color='green'>
                                        Presales: {row?.multiple_task_to.length > 0 ? row?.multiple_task_to[0]?.task_to?.name : "-"}
                                    </Tag>
                                </Col>
                            </Row>
                        </>
                        :
                        row?.multiple_task_to?.length === 0 ?
                            <Tag color='blue'>
                                {row?.task_to?.name}
                            </Tag> :
                            row?.multiple_task_to?.map((el) => (
                                <Tag color='blue'>
                                    {el?.task_to?.name}
                                </Tag>
                            ))
            ),
            align: 'center',
            key: 'tab'
        }, {
            title: 'Task Origin',
            dataIndex: 'data_from',
            key: 'data_from',
            filters: [
                {
                    text: 'DAILY ACTIVITY',
                    value: 'DAILY ACTIVITY'
                },
                {
                    text: 'DAILY ATTENDANCE',
                    value: 'DAILY ATTENDANCE'
                },
                {
                    text: 'MILESTONE TASK',
                    value: 'MILESTONE TASK'
                },
                {
                    text: 'SANUR-SLS',
                    value: 'SANUR-SLS',
                },
                {
                    text: 'SANUR-OPR',
                    value: 'SANUR-OPR',
                },
                {
                    text: 'TASK KARAJO',
                    value: 'TASK KARAJO',
                },
                {
                    text: 'TASK-SANUR-SLS',
                    value: 'TASK-SANUR-SLS',
                },
                {
                    text: 'TASK-SANUR-OPR',
                    value: 'TASK-SANUR-OPR',
                }
            ],
            onFilter: (value, record) => record.data_from === value,
        }, {
            title: 'Description',
            render: (row) => (
                row.description !== null ?
                    row.description.length > 150 ? `${row?.description.substring(0, 150)}...` : row?.description
                    : "-"
            ),
            key: 'description',
        }, {
            title: 'Deadline',
            key: 'deadline',
            render: (row) => (
                toFullDate(row?.due_date)
            )
        }, {
            title: 'Finished Date',
            render: (row) => (
                row?.finished_date === null || row?.finished_date === "0000-00-00 00:00:00" ? '-' : moment(row?.finished_date).format('DD MMMM YYYY')
            ),
            key: 'finished_date',
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            filters: [
                {
                    text: 'In Progress',
                    value: 'In Progress'
                },
                {
                    text: 'Finished',
                    value: 'Finished'
                }
            ],
            onFilter: (value, record) => record.status === value,
        }, {
            title: 'Action',
            align: 'center',
            render: (row) => (
                <Space>
                    {
                        tab === 'in' ?
                            row.data_from === "DAILY ACTIVITY" || row.data_from === "DAILY ATTENDANCE" ?
                                <>
                                    <EditButton onEdit={() => editDaily(row)} />
                                    <ShowButton onShow={() => editDaily(row, 'show')} />
                                </>
                                :
                                row.data_from === "SANUR-SLS" || row.data_from === "SANUR-OPR" ?
                                    <EditButton onEdit={() => history.push(`ticket/detail/${row.task_id}`)} />
                                    :
                                    <>
                                        {
                                            row.data_from === "TICKET" && row.multiple_task_to.length === 0 ?
                                                <TakeButton onTake={() => takeTicket(row.id)} />
                                                : null
                                        }
                                        {
                                            row.status === "Finished" ?
                                                <EditButton disabled={true} onEdit={() => editTask(row)} />
                                                :
                                                <EditButton onEdit={() => editTask(row)} />
                                        }
                                        <ShowButton onShow={() => editTask(row, 'show')} />
                                    </>
                            :
                            row.data_from === "DAILY ACTIVITY" || row.data_from === "DAILY ATTENDANCE" ?
                                <ShowButton onShow={() => editDaily(row, 'show')} />
                                :
                                <ShowButton onShow={() => editTask(row, 'show')} />
                    }
                </Space>
            ),
            key: 'action'
        }
    ];

    const summaryColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
        },
        {
            title: 'Task Origin',
            dataIndex: 'data_from',
            key: 'data_from'
        },
        {
            title: 'Task From',
            dataIndex: 'from_name',
            key: 'from_name',
        }, {
            title: 'Task To',
            dataIndex: 'to_name',
            key: 'to_name',
        }, {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        }, {
            title: 'Deadline',
            key: 'deadline',
            render: (row) => (
                toFullDate(row?.due_date)
            )
        }, {
            title: 'Finished Date',
            render: (row) => (
                row?.finished_date === null ? '-' : moment(row?.finished_date).format('DD MMMM YYYY')
            ),
            key: 'finished_date',
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        }
    ];

    const columnDailyProgress = [
        {
            title: "Job Description",
            dataIndex: "job_description",
            key: "job_description"
        },
        {
            title: "Time",
            render: (row) => (
                `${row?.time_start} - ${row?.time_end}`
            ),
            key: "time"
        },
        {
            title: "Progress",
            render: (row) => (
                row?.progress ?
                    <Progress percent={row.progress} size="small" />
                    :
                    <Progress percent={0} size="small" />
            ),
            align: "center",
            key: "progress"
        },
        {
            title: "Action",
            render: (row) => (
                defaultStat === 'show' ?
                    <DeleteButton disabled onConfirm={() => removeDailyProgress(row)} /> :
                    <DeleteButton onConfirm={() => removeDailyProgress(row)} />
            ),
            key: "action",
            width: "5%",
            align: "center"
        }
    ];

    const columnTaskProgress = [
        {
            title: "Job Description",
            dataIndex: "job_description",
            key: "job_description",
            align: "center"
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            align: "center"
        },
        {
            title: "Date",
            render: (row) => (
                moment(row?.progress_date).format("YYYY-MM-DD")
            ),
            key: "date",
            align: "center"
        },
        {
            title: "Progress",
            render: (row) => (
                row?.progress ?
                    <Progress percent={row.progress} size="small" />
                    :
                    <Progress percent={0} size="small" />
            ),
            align: "center",
            key: "progress"
        },
        {
            title: 'Employee',
            render: (row) => (
                row?.employee?.name
            ),
            key: 'employee'
        },
        {
            title: "Action",
            render: (row) => (
                defaultStat === 'show' ?
                    <DeleteButton disabled onConfirm={() => removeTaskProgress(row)} /> :
                    moment(row?.progress_date).format("YYYY-MM-DD") < moment(Date.now()).format("YYYY-MM-DD") ?
                        <DeleteButton disabled onConfirm={() => removeTaskProgress(row)} /> :
                        <DeleteButton onConfirm={() => removeTaskProgress(row)} />
            ),
            key: "action",
            width: "5%",
            align: "center"
        }
    ];

    const columnsWorkingLocation = [
        {
            title: "Company Name",
            key: "company_name",
            render: (row) => (
                row?.company?.company_name
            ),
            align: "center"
        },
        {
            title: "Time",
            key: "time",
            render: (row) => (
                `${row.time_start} - ${row.time_end}`
            )
        },
        // {
        //     title: "Action",
        //     key: "action",
        //     render: (row) => (
        //         row.index === 0 ?
        //             <Space>
        //                 <EditButton disabled onEdit={() => onShowModalWorkingLocation(row)} />
        //                 <DeleteButton disabled />
        //             </Space>
        //             :
        //             <Space>
        //                 <EditButton onEdit={() => onShowModalWorkingLocation(row)} />
        //                 <DeleteButton onConfirm={() => onDelete(row.working_location_id)} />
        //             </Space>
        //     ),
        //     align: "center",
        // }
    ];

    const renderFormComponent = () => {
        switch (divisionId) {
            case 13:
                return (
                    <FormatTicketPresales saveSubmit={(v) => saveTicket(v)} onChangeCategory={(v) => setCategoryRequest(v)} categoryRequest={categoryRequest} onChangeCompany={changeCompany} modalCompany={onShowModalCompany} changeFileUploaded={(v) => fileUploaded(v)} changeSetUploading={(v) => setUploading(v)} />
                )
            case 24:
                return (
                    <FormatTicketSoftware saveSubmit={(v) => saveTicket(v)} changeFileUploaded={(v) => fileUploaded(v)} changeSetUploading={(v) => setUploading(v)} />
                )
            case 34:
                return (
                    <FormatTicketIt saveSubmit={(v) => saveTicket(v)} />
                )
            default:
                return (
                    <FormatTicketGeneral saveSubmit={(v) => saveTicket(v)} onChangeEmployee={changeEmployee} employees={employees} divisionId={divisionId} disabled={disabled} changeFileUploaded={(v) => fileUploaded(v)} changeSetUploading={(v) => setUploading(v)} />
                )
        }
    }

    return (
        <div>
            <Row justify="space-between" className="mb-3">
                <Col >
                    <span>
                        <Button type="primary" color="#ededed" onClick={() => onShowModalAddTask()} icon={<PlusCircleOutlined />}>
                            Add Task
                        </Button>
                    </span>
                </Col>
                <Col>
                    <Space>
                        <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year" />
                        <Select placeholder="Select Period" style={{ width: 200 }} onChange={changePeriod}>
                            {
                                periods.map((el, key) => (
                                    <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                ))
                            }
                        </Select>
                    </Space>
                </Col>
            </Row>
            {
                loaded ?
                    <Tabs type="card" defaultActiveKey='daily'>
                        <TabPane tab="Daily" key="daily">
                            <>
                                <Row justify='center' className='mb-3'>
                                    <Col span={24}>
                                        {
                                            loadedPeriod ?
                                                <div className='scrollButton'>
                                                    <Space>
                                                        {
                                                            datePeriods.map((el, key) => (
                                                                <Button key={key} onClick=
                                                                    {
                                                                        () => fetchTask({ start_date: el.date, end_date: el.date })
                                                                    }
                                                                >
                                                                    {moment(el.date).format("DD MMM")}
                                                                </Button>
                                                            ))
                                                        }
                                                    </Space>
                                                </div>
                                                : null
                                        }
                                    </Col>
                                </Row>

                                <Tabs defaultActiveKey="task" type="card" onChange={changeTab}>
                                    <TabPane tab={
                                        myTasks?.filter(el => el?.status === 'In Progress').length > 0 ?
                                            <Badge dot size="small">
                                                <span><CalendarOutlined style={{ color: '#4ca3ff' }} /> My Task</span>
                                            </Badge>
                                            :
                                            <span><CalendarOutlined style={{ color: '#4ca3ff' }} /> My Task</span>
                                    } key="in">
                                        <DataTable size="small" columns={column} dataSource={myTasks} bordered />
                                    </TabPane>

                                    <TabPane tab={
                                        assignTasks?.filter(el => el.status !== 'Finished').length > 0 ?
                                            <span>
                                                <ClockCircleOutlined style={{ color: 'red' }} />
                                                <Badge dot size="small">
                                                    <div>
                                                        Assigned Task
                                                    </div>
                                                </Badge>
                                            </span>
                                            :
                                            <span><ClockCircleOutlined style={{ color: 'red' }} />
                                                Assigned Task
                                            </span>
                                    } key="out">
                                        <Tabs defaultActiveKey='status' type='card'>
                                            <TabPane tab={
                                                <span><ClockCircleOutlined style={{ color: '#4ca3ff' }} />
                                                    <Space>
                                                        On Progress
                                                        <Tag color='red'>
                                                            {
                                                                assignTasks?.filter(el => el.status !== 'Finished').length
                                                            }
                                                        </Tag>
                                                    </Space>
                                                </span>
                                            } key="progress-task">
                                                <DataTable size="small" columns={column} dataSource={assignTasks?.filter(el => el.status !== 'Finished')} bordered />
                                            </TabPane>

                                            <TabPane tab={<span><CheckOutlined style={{ color: 'green' }} /> Finished</span>} key="finished-task">
                                                <DataTable size="small" columns={column} dataSource={assignTasks?.filter(el => el.status === 'Finished')} bordered />
                                            </TabPane>
                                        </Tabs>
                                    </TabPane>

                                    {
                                        isHead ?
                                            <TabPane tab={<span><FileTextOutlined style={{ color: 'green' }} /> Summary</span>} key="summary">
                                                <div className="text-right mb-2">
                                                    <Space>
                                                        <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                                                        <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                                                    </Space>
                                                </div>
                                                {
                                                    loadedSummary ?
                                                        <DataTable size="small" columns={summaryColumn} dataSource={summaryData} bordered />
                                                        : <Spinner />
                                                }
                                            </TabPane>
                                            : null
                                    }
                                </Tabs>
                            </>
                        </TabPane>
                        {
                            // authData.employee_code === 'KRTJ20201123064' || authData.employee_code === 'SMI20220103227' || 
                            authData.employee_code === 'ADM001' ?
                                <TabPane tab="Ticket" key="ticket">
                                    <ListTicket />
                                </TabPane>
                                : null
                        }
                    </Tabs>
                    :
                    <Spinner />
            }

            {
                modalAddTask ?
                    <Modal centered title={modalAddTaskTitle} width={1200} visible={modalAddTask} onCancel={() => setModalAddTask(false)} footer={null}>
                        <Tabs defaultActiveKey={defaultTab} type="card" onChange={changeTabForm}>
                            <TabPane tab="Task" key="task">
                                <Form layout='vertical' initialValues={defaultValues} onFinish={(v) => saveJob(v)}>
                                    <Form.Item name="all_app_task_id" hidden>
                                        <Input placeholder="all_app_task_id" />
                                    </Form.Item>
                                    <Form.Item name="emp_task_id" hidden>
                                        <Input placeholder="ID" />
                                    </Form.Item>
                                    <Form.Item name="employee_code" hidden>
                                        <Input placeholder="Employee Code" />
                                    </Form.Item>
                                    <Form.Item name="data_from" hidden>
                                        <Input placeholder="Data From" />
                                    </Form.Item>
                                    <Form.Item name="start_date" hidden>
                                        <DatePicker />
                                    </Form.Item>
                                    <Form.Item name="category" label="Category" rules={[{ required: true }]}>
                                        <Select placeholder="Select Category" onChange={(v) => setCategory(v)}>
                                            <Option value="WORKING LOCATION">WORKING LOCATION</Option>
                                            <Option value="TASK/DAILY ACTIVITY">TASK/DAILY ACTIVITY</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Assign Myself">
                                        <Checkbox onChange={() => setMyself(!myself)}>Myself</Checkbox>
                                    </Form.Item>
                                    {
                                        !myself ?
                                            <Form.Item label="Assign To" name="task_to" rules={[{ required: true }]}>
                                                <Select mode='multiple' showSearch allowClear placeholder="Select employee" optionFilterProp="children" style={{ width: "100%" }} onChange={changeEmployee} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {
                                                        employees.map((el, key) => (
                                                            <Option key={key} value={el.employee_code}>{el.name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                            : null
                                    }
                                    {
                                        !myself ?
                                            <>
                                                <Form.Item label="Confirm Finished" name="confirm_finished" rules={[{ required: true }]}>
                                                    <Select placeholder="Select Confirm Finished" onChange={(v) => setConfirmFinished(v)}>
                                                        <Option value="confirm">Only Confirm</Option>
                                                        <Option value="confirm_with_attachment">Confirm and Attachment</Option>
                                                    </Select>
                                                </Form.Item>
                                                {
                                                    confirmFinished === "confirm_with_attachment" ?
                                                        <Form.Item label="Attachment Clause" name="attachment_clause">
                                                            <Input.TextArea rows={4} />
                                                        </Form.Item>
                                                        : null
                                                }
                                            </> : null
                                    }
                                    <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                                        <Input.TextArea rows={4} placeholder="Description" disabled={disabled} />
                                    </Form.Item>
                                    <Form.Item label="Deadline" name="deadline" rules={[{ required: true }]}>
                                        <DatePicker format="DD MMMM YYYY" style={{ width: '100%' }} disabled={disabled} />
                                    </Form.Item>
                                    <Form.Item label="Upload Attachment">
                                        <Uploader folder="attachment-daily-activity" onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} view={true} />
                                    </Form.Item>
                                    <div style={{ float: 'right' }}>
                                        {
                                            <Form.Item>
                                                <Button type='primary' htmlType='submit'>Save</Button>
                                            </Form.Item>
                                        }
                                    </div>
                                </Form>
                            </TabPane>
                            <TabPane tab="Ticket" key="ticket">
                                <div style={{ widh: "100%" }} className="mb-3">
                                    <Select style={{ width: "40%" }} placeholder="Select Ticket Division" onChange={(v) => changeDivision(v)}>
                                        {
                                            division.map((el, key) => (
                                                <Option value={el.division_id} key={key} >{el.division_name}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                <Divider />
                                {renderFormComponent()}
                            </TabPane>
                            {
                                authData.employee_code === 'ADM001' || authData.position_id === 66 ?
                                    <TabPane tab="IT Infra Job" key="it_infra_job">
                                        <ITInfra onSubmit={(v) => saveJobItInfra(v)} />
                                    </TabPane>
                                    : null
                            }
                        </Tabs>
                    </Modal>
                    : null
            }

            {
                onShowModalCompany ?
                    <ModalCompany form={form} titleModalCompany={titleModalCompany} submitAddCompany={(v) => addCompany(v)} showModalCompany={modalCompany} showSetModalCompany={() => setModalCompany(false)} />
                    : null
            }

            {
                editProgressDailyModal ?
                    <ModalEditProgressDaily
                        title={editProgressModalTitle}
                        visible={editProgressDailyModal}
                        onCancel={() => setEditProgressDailyModal(false)}
                        initialValues={defaultValueEdit}
                        onFinish={(v) => updateDailyProgress(v)}
                        changeCategory={(v) => setCategory(v)}
                        attachmentInfo={attachmentInfo}
                        clickAddProgress={() => {
                            setShowModalAddProgress(true)
                            setProgressDailyValue(0)
                        }}
                        columnDailyProgress={columnDailyProgress}
                        dailyProgressData={dailyProgressData}
                        defaultStat={defaultStat}
                        columnsWorkingLocation={columnsWorkingLocation}
                        listWorkingLocation={listWorkingLocation}
                        defaultValueEdit={defaultValueEdit}
                        finishWorkingLocation={(v) => saveWorkingLocation(v)}
                        measurements={measurements}
                        changeCompany={changeCompany}
                        companies={companies}
                        onShowModalCompany={onShowModalCompany}
                    />
                    : null
            }

            {
                editProgressTaskModal ?
                    <ModalEditProgressTask
                        title={editProgressModalTitle}
                        visible={editProgressTaskModal}
                        onCancel={() => setEditProgressTaskModal(false)}
                        initialValues={defaultValues}
                        onFinish={(v) => (percentageTask === 100 && !isMilestoneTask && !isTicketPresales) ? showConfirmFinished(v) : updateTaskProgress(v)}
                        ticketInfo={ticketInfo}
                        isTicketPresales={isTicketPresales}
                        changeCategory={(v) => setCategory(v)}
                        defaultStat={defaultStat}
                        attachmentInfo={attachmentInfo}
                        taskProgressData={taskProgressData}
                        onClickAddProgress={() => {
                            setShowModalAddTaskProgress(true)
                            setProgressValue(0)
                        }}
                        onClickShowModalAddProgress={() => setShowModalAddTaskProgress(true)}
                        columnTaskProgress={columnTaskProgress}
                        percentageTask={percentageTask}
                        myProgress={myProgressPercentage}
                        fileUploaded={(v) => fileUploaded(v)}
                        setUploading={(v) => setUploading(v)}
                        uploading={uploading}
                        saving={saving}
                        showAttachmentFinished={showAttachmentFinished}
                        multipleTaskTo={multipleAssign}
                    />
                    : null
            }

            {
                showModalAddProgress ?
                    <FormModal isAdd title={modalAddProgressTitle} defaultValues={defaultValues} submitForm={(v) => addDaily(v)} width={500} showModal={showModalAddProgress} onCloseModal={() => setShowModalAddProgress(false)}>
                        <Form.Item label="Job Description" name="job_description" rules={[{ required: true }]}>
                            <Input.TextArea rows={4} placeholder="Job Description" />
                        </Form.Item>
                        <Form.Item label="Time" name="time" rules={[{ required: true }]}>
                            <TimePicker.RangePicker format="HH:mm" />
                        </Form.Item>
                        <Form.Item label="Progress">
                            <Row justify='space-around'>
                                <Col span={16}>
                                    <Slider min={0} max={100} value={progressDailyValue} onChange={(v) => setProgressDailyValue(v)} />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        value={progressDailyValue}
                                        onChange={(v) => setProgressDailyValue(v)}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </FormModal> : null
            }

            {
                showModalAddTaskProgress ?
                    <FormModal isAdd title={modalAddTaskProgressTitle} defaultValues={defaultValues} submitForm={(v) => addTaskProgress(v)} width={500} showModal={showModalAddTaskProgress} onCloseModal={() => setShowModalAddTaskProgress(false)}>
                        <Form.Item label="Job Description" name="job_description" rules={[{ required: true }]}>
                            <Input.TextArea rows={4} placeholder="Job Description" />
                        </Form.Item>
                        <Form.Item label="Remarks" name="remarks" rules={[{ required: true }]}>
                            <Input placeholder="Remarks" />
                        </Form.Item>
                        <Form.Item label="Progress">
                            <Row justify='space-around'>
                                <Col span={16}>
                                    <Slider min={0} max={100 - myProgressPercentage} value={progressValue} onChange={(v) => setProgressValue(v)} />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        min={1}
                                        max={100 - myProgressPercentage}
                                        value={progressValue}
                                        onChange={(v) => setProgressValue(v)}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                    </FormModal>
                    : null
            }

            {
                modalConfirm ?
                    <FormModal title="Confirm Finished" submitForm={saveFinishedTask} width={500} showModal={modalConfirm} onCloseModal={() => setModalConfirm(false)}>
                        {
                            saveDataTask.confirm_finished === "confirm" ?
                                <Form.Item label="Remark Finished" name="remark_finished" onChange={(v) => setRemarkFinished(v.target.value)}>
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                                :
                                <Form.Item label="Upload Attachment" name="attachment">
                                    <Uploader dragger folder="attachment-daily-activity" onUploaded={(v) => fileUploadFinish(v)} isUploading={(v) => setUploading(v)} view={true} />
                                </Form.Item>
                        }
                    </FormModal>
                    : null
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}>
                <Form layout="vertical" onFinish={filterData}>
                    <Form.Item name="employee_code" label="Employee">
                        <Select showSearch allowClear style={{ width: '100%' }} onChange={(v) => { setSelectedEmployee(v) }} placeholder="Select Employee" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            <Select.Option value={null}>All Employee</Select.Option>
                            {
                                employees.filter(el => selectedDivision === 2 || selectedDivision === 8 ? el.is_active === 1 : el.division_id === selectedDivision).map((el, key) => (
                                    <Select.Option
                                        key={key}
                                        value={el.employee_code}
                                    >{el.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range" label="Date Range" rules={[{ required: true, message: 'Date range is required' }]}>
                        <RangePicker onChange={(v) => setSelectedDateRange([moment(v[0]).format('YYYY-MM-DD'), moment(v[1]).format('YYYY-MM-DD')])} />
                    </Form.Item>

                    <Form.Item >
                        <div className="text-right">
                            <Button htmlType="submit" type="primary" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
}

export default DailyActivity;