import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input,  message, Space, Select, Spin, DatePicker} from 'antd'
import {AddButton, PageTitle, Spinner, FormModal, DataTable, EditButton} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import authData from '../../../helpers/authData'
import {toIdr, extractNumber} from '../../../utils/currencyHandler'
import moment from 'moment'
import XLSX from 'xlsx';

const {Text} = Typography
const {Option} = Select

const Index = () => {
    const [form]= Form.useForm()
    const [budgets, setBudgets] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [selectedYear, setSelectedYear] = useState(moment().year())
    const [formModal, setFormModal] = useState(false)
    const [formImportModal, setFormImportModal] = useState(false)
    const [coaOptions, setCoaOptions] = useState([])
    const [saving, setSaving] = useState(false)
    const [time, setTime] = useState(0)
    const [searching, setSearching] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [importBudgets, setImportBudgets] = useState([])

    const fetchBudgets = (y) =>{
        setLoaded(false)
        SentralModel.list('BudgetPlanning', {filter:[["division_id",authData.division_id],["year",y]]}).then(res=>{
            setBudgets(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchBudgets(moment().year())
    }, []);

    const editBudget = (v) =>{
        form.setFieldsValue({
            budget_planning_id: v.budget_planning_id,
            description: v.description,
            amount: toIdr(v.amount),
            coa_code: v.coa_code,
            year:v.year,
            realization_month: moment(v.realization_month)
        })
        setCoaOptions([{coa_code:v.coa_code, coa_name:v.coa_name}])
        setFormModal(true)
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },{
            title: 'COA',
            dataIndex: 'coa_name',
            key: 'coa_name',
        },{
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },{
            title: 'Amount',
            dataIndex: 'amount',
            render: (text) => toIdr(text)
        },{
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
        },{
            title: 'Action',
            align:'center',
            render: (row) => (
                <EditButton onEdit={()=>editBudget(row)}/>
            )
        },
    ];

    const dataSource = [
        ...budgets.map(el => {
            return{
                budget_planning_id: el.budget_planning_id,
                description: el.description,
                amount: el.amount,
                author: (el.employee) ? el.employee.name : '-',
                coa_name: (el.coa) ? el.coa.coa_name : '-',
                coa_code: el.coa_code,
                year: moment(el.year, 'YYYY'),
                realization_month: moment(el.realization_month)
            }
        })
    ]

    const addBudget=()=>{
        form.setFieldsValue({
            budget_planning_id: 0,
            year: moment(),
            coa_code:null,
            description:null,
            realization_month:moment(),
            amount:null
        })
        setFormModal(true)
    }

    const saveBudget = (v) => {
        setSaving(true)
        let payload={
            budget_planning_id: v.budget_planning_id,
            description: v.description,
            year: moment(v.year).format('YYYY'),
            division_id: authData.division_id,
            coa_code: v.coa_code,
            realization_month: moment(v.realization_month, 'MMMM').format('MM'),
            amount: extractNumber(v.amount),
        }
        SentralModel.save('BudgetPlanning', payload, v.budget_planning_id).then(()=>{
            fetchBudgets(selectedYear)
            message.success('Budget planning saved', 2)
            setFormModal(false)
            setSaving(false)
        }).catch(() => {
            message.destroy()
            message.error('Failed to add budget', 2)
            setSaving(false)
        })
    }

    const changeYear = (v) =>{
        setSelectedYear(v)
        fetchBudgets(v)
    }

    const searchCoa = (v) =>{
        if(time) clearTimeout(time)
        if(v){
            setTime(setTimeout(() => {
                setSearching(true)
                SentralModel.action('Search','coa', {coa_name: v},0).then((res)=>{
                    setCoaOptions(res.data)
                })
            }, 500))
        }else{
            setSearching(false)
        }
    }

    const changeAmount = (v) => {
        let number = extractNumber(v.target.value)
        form.setFieldsValue({amount: toIdr(number)})
    }

    const addImport=()=>{
        setDefaultValues({
            budget_planning_id: 0,
        })
        setFormImportModal(true)
    }

    const handleFile = (file /*:File*/) => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
        //   console.log(rABS, wb);
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          /* Update state */
          setImportBudgets(data)
        //   console.log(data)
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    }

    const saveImportBudget = (v) => {
        setSaving(true)
        let payload={
            budget_planning_id: v.budget_planning_id,
            year: moment(v.year).format('YYYY'),
            division_id: authData.division_id,
            budgets: importBudgets
        }
        SentralModel.action('Budget', 'importBudget', payload, v.budget_planning_id).then(()=>{
            fetchBudgets(selectedYear)
            message.success('Budget planning saved', 2)
            setFormImportModal(false)
            setSaving(false)
        }).catch(() => {
            message.destroy()
            message.error('Failed to add budget', 2)
            setSaving(false)
        })
    }

    const options = coaOptions.map((el, key) => <Option value={el.coa_code} key={key}>{el.coa_name}</Option>);

    return (
        
        <div>
            <Card className="content-container">
                <PageTitle title="Budget" breadcrumbs={[['Budget']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Budget Planning ({authData.division.division_name})</Text>
                        <AddButton right onAdd={addBudget} title="Add Budget Plan"/>
                        <Text style={{float: 'right', marginRight: '5px', marginLeft: '5px'}}>&nbsp;</Text>
                        <AddButton right onAdd={addImport} title="Import Budget"/>
                    </div> }>
                    <div className="full-width">
                        <span className="mb-3" style={{float:'right', marginLeft:'10px'}}>
                            <Space>
                                <Select value={selectedYear} placeholder="Select Year" style={{ width: 250}} onChange={changeYear}>
                                    <Option value={moment().year()-1}>{moment().year()-1}</Option>
                                    <Option value={moment().year()}>{moment().year()}</Option>
                                    <Option value={moment().year()+1}>{moment().year()+1}</Option>
                                </Select>
                            </Space>
                        </span>
                    </div>
                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                {
                    (formModal) ?
                    <FormModal form={form} title='Budget Planning' submitForm={(v)=>saveBudget(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="budget_planning_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item name="year" label="Year" rules={[{required:true}]}>
                            {/* <Input type="number"/> */}
                            <DatePicker picker="year" style={{width:'100%'}} format='YYYY'/>
                        </Form.Item>
                        <Form.Item label="COA Name" name="coa_code" rules={[{required:true}]}>
                        <Select
                            showSearch
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={searchCoa}
                            notFoundContent={searching ? <Spin size="small" /> : null}>
                            {options}
                        </Select>
                        </Form.Item>
                        <Form.Item label="Description" name="description" rules={[{required:true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Amount" name="amount" onChange={changeAmount} rules={[{required:true}]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="realization_month" label="Realization Month" rules={[{required:true}]}>
                            <DatePicker picker="month" style={{width:'100%'}} format='MMMM'/>
                        </Form.Item>
                        
                    </FormModal> :
                    null
                }

                {
                    (formImportModal) ?
                    <FormModal title='Import Budget' defaultValues={defaultValues} submitForm={(v)=>saveImportBudget(v)} width={400} showModal={formImportModal} onCloseModal={() => setFormImportModal(false)} saving={saving}>
                        <Form.Item name="year" label="Year" rules={[{required:true}]}>
                            <DatePicker picker="year" style={{width:'100%'}} format='YYYY'/>
                        </Form.Item>
                        <Form.Item label="Excel File" name="excel_file">
                            <ImportFile handleFile={handleFile} />
                        </Form.Item>
                        <div style={{marginBottom: '10px'}}>
                            <a href={process.env.REACT_APP_API_FILE + "/budget/30f0f2b6-027f-441b-9a2f-2647b4b0bb9c.xlsx"} download>
                                Click here to download template
                            </a>
                        </div>
                    </FormModal> :
                    null
                }
            </Card>
        </div>
    );
}

export default Index;

class ImportFile extends React.Component {
    constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
      const files = e.target.files;
      if (files && files[0]) this.props.handleFile(files[0]);
    }
    render() {
      return (
        <div className="form-group">
            <input
                type="file"
                className="form-control"
                id="file"
                accept=".xlsx"
                onChange={this.handleChange}
            />
        </div>
      );
    }
}