import { Button, Card, DatePicker, Form, Input, Modal, Select, Tabs, TimePicker, Typography, Col, Row } from 'antd'
import React from 'react'
import { DataTable } from '../../../../components'

const { TabPane } = Tabs
const { Option } = Select
const { Text } = Typography

const ModalEditProgressDaily = (props) => {
    const {
        title,
        visible,
        onCancel,
        initialValues,
        onFinish,
        changeCategory,
        attachmentInfo,
        columnDailyProgress,
        dailyProgressData,
        defaultStat,
        columnsWorkingLocation,
        listWorkingLocation,
        defaultValueEdit,
        finishWorkingLocation,
        measurements,
        changeCompany,
        clickAddProgress,
        companies,
        onShowModalCompany
    } = props

    return (
        <Modal centered title={title} width={800} visible={visible} onCancel={onCancel} footer={null}>
            <Tabs type='card'>
                <TabPane tab="Task" tabKey='task'>
                    <Form layout='vertical' initialValues={initialValues} onFinish={(v) => onFinish(v)}>
                        <Form.Item name="all_app_task_id" hidden>
                            <Input placeholder="all_app_task_id" />
                        </Form.Item>
                        <Form.Item name="emp_task_id" hidden>
                            <Input placeholder="ID" />
                        </Form.Item>
                        <Form.Item name="employee_code" hidden>
                            <Input placeholder="Employee Code" />
                        </Form.Item>
                        <Form.Item name="data_from" hidden>
                            <Input placeholder="Data From" />
                        </Form.Item>
                        <Form.Item name="start_date" hidden>
                            <DatePicker />
                        </Form.Item>
                        <Form.Item name="category" label="Category" rules={[{ required: true }]}>
                            <Select placeholder="Select Category" disabled onChange={(v) => changeCategory(v)}>
                                <Option value="WORKING LOCATION">WORKING LOCATION</Option>
                                <Option value="TASK/DAILY ACTIVITY">TASK/DAILY ACTIVITY</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                            <Input.TextArea rows={4} disabled placeholder="Description" />
                        </Form.Item>
                        <Form.Item>
                            {
                                attachmentInfo === null ?
                                    <Button disabled onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${attachmentInfo}`)}>No Attachment</Button>
                                    :
                                    <Button onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${attachmentInfo}`)}>Show Attachment</Button>
                            }
                        </Form.Item>
                        <Card style={{ marginBottom: 10 }} title={
                            <Row justify='space-between'>

                                <Col>
                                    <Text>Daily Progress</Text>
                                </Col>
                                {
                                    defaultStat === 'show' ?
                                        null :
                                        <Col>
                                            <Button type="primary" onClick={clickAddProgress}>Add Progress</Button>
                                        </Col>
                                }
                            </Row>
                        }>
                            <DataTable size="small" columns={columnDailyProgress} dataSource={dailyProgressData} bordered search={false} pagination={false} />
                        </Card>
                        {
                            defaultStat === 'show' ?
                                null :
                                <Form.Item>
                                    <Button type='primary' htmlType='submit'>Save</Button>
                                </Form.Item>
                        }
                    </Form>
                </TabPane>

                <TabPane tab="Working Location" key="working_location">
                    <Card title="List Working Location" style={{ marginBottom: 10 }}>
                        <DataTable columns={columnsWorkingLocation} dataSource={listWorkingLocation} pagination={false} search={false} size="small" />
                    </Card>
                    {
                        defaultStat === 'show' ?
                            null :
                            <Card title="Add Working Location">
                                <Form layout='vertical' initialValues={defaultValueEdit} onFinish={(v) => finishWorkingLocation(v)}>
                                    <Form.Item name="all_app_task_id" hidden>
                                        <Input placeholder="all_app_task_id" />
                                    </Form.Item>
                                    <Form.Item label="Job Category" name="measurement_id" required rules={[{ required: true }]}>
                                        <Select placeholder="Select category" style={{ width: "100%" }}>
                                            {
                                                measurements.map((el, key) => (
                                                    <Option key={key} value={el.measurement_id}>{el.title}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Row justify='space-between'>
                                        <Col span={17}>
                                            <Form.Item label="Company" name="company_id" required rules={[{ required: true }]}>
                                                <Select showSearch allowClear placeholder="Select company" style={{ width: "100%" }} optionFilterProp="children" onChange={changeCompany} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {
                                                        companies.map((el, key) => (
                                                            <Option key={key} value={el.company_id}>{el.company_name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <div style={{ marginTop: 30 }}>
                                                <Button type='primary' onClick={onShowModalCompany}>Add Company</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Form.Item label="Description" name="description" >
                                        <Input.TextArea disabled placeholder='Description' rows={4} />
                                    </Form.Item>
                                    <Form.Item label="Time" name="time" required rules={[{ required: true }]}>
                                        <TimePicker.RangePicker format="HH:mm" style={{ width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type='primary' htmlType='submit'>Save</Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                    }
                </TabPane>
            </Tabs>
        </Modal>
    )
}

export default ModalEditProgressDaily