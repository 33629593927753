import { useState, useEffect } from 'react'
import { SentralModel } from '../../../models/SentralModel'
import { Button, Card, Col, Row, Space, Drawer, Form, Select, Tabs, DatePicker, Tag, Typography } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import { BasicModal, DataTable, PageTitle, Spinner } from '../../../components'
import moment from 'moment'
import { getUnique } from '../../../helpers/duplicate'
import { BsFingerprint } from "react-icons/bs";

const { Option } = Select
const { TabPane } = Tabs
const { Text } = Typography

const Index = () => {
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    const [loadedDaily, setLoadedDaily] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [dataDaily, setDataDaily] = useState([])
    const [dataWorkingLocation, setDataWorkingLocation] = useState([])
    const [dataDailyFilter, setDataDailyFilter] = useState([])
    const [dataWorkingLocationFilter, setDataWorkingLocationFilter] = useState([])
    const [division, setDivisions] = useState([])
    const [periods, setPeriods] = useState([])
    const [datePeriod, setDatePeriod] = useState([])
    const [loadedPeriod, setLoadedPeriod] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const [defaultTab, setDefaultTab] = useState('daily_activity')
    // eslint-disable-next-line
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [showModalWorking, setShowModalWorking] = useState(false)
    const [locationData, setLocationData] = useState([])
    const [defaultDate, setDefaultDate] = useState(moment(Date.now()).format("YYYY-MM-DD"))
    const [defaultKey, setDefaultKey] = useState('')

    const fetchDataDaily = (v) => {
        setLoadedDaily(false)
        SentralModel.action("Report", "dailyWorkingLocation", v, 0).then((res) => {
            const dw = res.data

            let e = []
            dw.forEach((el) => {
                e.push({ employee_code: el?.task_to?.employee_code, name: el?.task_to?.name, division_id: el?.task_to?.division_id })
            })

            const listEmployees = getUnique(e)

            var arr = []
            listEmployees.forEach((el) => {
                let obj = []
                let locations = []
                dw.forEach((i) => {
                    if (el.employee_code === i.task_to.employee_code) {
                        obj.push(i)

                        i?.working_location?.forEach((j) => {
                            const check = locations.filter((el) => el.working_location_id === j.working_location_id)
                            if (check.length === 0) {
                                locations.push(j)
                            }
                        })
                    }
                })

                arr.push({ employee_code: el.employee_code, name: el.name, division_id: el.division_id, task: obj, locations: locations })
            })

            setDataDaily(arr)
            setLoadedDaily(true)
            setIsFilter(false)
        })
    }

    const fetchWorkingLocation = (v) => {
        setLoaded(false)
        SentralModel.action("Report", "workingLocationTest", v, 0).then((res) => {
            const wl = res.data

            let e = []
            wl.forEach((el) => {
                e.push({ employee_code: el.employee_code, division_id: el?.employee?.division_id, name: el?.employee?.name })
            })

            const listEmployees = getUnique(e)

            var arr = []
            listEmployees.forEach((el) => {
                let obj = []
                wl.forEach((i) => {
                    if (el.employee_code === i.employee_code) {
                        obj.push(i)
                    }
                })

                arr.push({ employee_code: el.employee_code, division_id: el.division_id, name: el.name, locations: obj })
            })

            fetchDataDaily({ first_date: null, end_date: null, division_id: null })

            setDataWorkingLocation(arr)
            setLoaded(true)
            setIsFilter(false)
        })
    }

    const fetchDivisions = () => {
        SentralModel.list('Division', { filter: [["is_active", "1"]], fields: "division_id,division_name", orderBy: 'division_id', order: 'ASC' }).then((res) => {
            setDivisions(res.data)
        })
    }

    const fetchPeriods = (year) => {
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, { orderBy: 'from_date', order: 'asc' }).then(res => {
            setPeriods(res.data)
        })
    }

    const changePeriod = (v) => {
        SentralModel.action('PayrollPeriod', 'getListDate', { period_id: v }, 0).then(res => {
            setDatePeriod(res.data)
            setLoadedPeriod(true)
        })
    }

    const showWorkingLocation = (v) => {
        setShowModalWorking(true)
        setLocationData(v)
    }

    useEffect(() => {
        fetchWorkingLocation({ first_date: moment(Date.now()).format('YYYY-MM-DD'), end_date: moment((Date.now())).format('YYYY-MM-DD'), division_id: null })
        fetchDivisions()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            width: '5%',
            align: 'center'
        },
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Activity',
            render: (row) => (
                <table className="table-collapse table-default" style={{ width: '100%', margin: '0px' }}>
                    <tbody>
                        {
                            row?.task?.map((el, x) => (
                                <tr key={x}>
                                    <td style={{ width: "250px" }}>
                                        {
                                            el?.data_from === "SANUR-SLS" ?
                                                `${el?.task?.description} (${el?.task_id})`
                                                :
                                                `${el?.task?.description}`
                                        }
                                    </td>
                                    <td style={{ width: "150px" }}>{el?.data_from}</td>
                                    <td className="text-center" style={{ width: 40 }}>
                                        <Tag color={el?.task?.status === "Finished" ? "green" : "red"}>
                                            {
                                                el?.data_from === "SANUR-SLS" && el?.task?.task_to === null ? "NA"
                                                    :
                                                    el?.task?.status === "Finished" ? "FI" : "IP"
                                            }
                                        </Tag>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            ),
            key: 'list_progress',
            align: 'center',
            width: "50%"
        },
        {
            title: 'Working Location',
            render: (row) => (
                <Button onClick={() => showWorkingLocation(row?.locations)}>
                    {row?.locations?.length}
                </Button>
            ),
            key: 'working_location',
            align: 'center'
        },
        {
            title: 'Date',
            render: (row) => (
                moment(row?.created_at).format('dddd, DD MMMM YYYY')
            ),
        }
    ];

    const columnWorkingLocation = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            width: '5%',
            align: 'center'
        },
        {
            title: 'Employee Name',
            render: (row) => (
                row?.name
            ),
            key: 'employee_name'
        },
        {
            title: 'Working Location',
            render: (row) => (
                <table className='table-sub'>
                    <thead>
                        <tr>
                            <th style={{ width: "300px" }}>Company</th>
                            <th>Date</th>
                            <th style={{ textAlign: "center" }}>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            row?.locations.map((el, key) => (
                                <tr key={key}>
                                    <td style={{ width: "300px" }}>
                                        {
                                            el?.description === "Alpha" || el?.description === "Leave" ?
                                                <Tag color="red">
                                                    {el?.description}
                                                </Tag>
                                                :
                                                el?.absence_description === "Machine" ?
                                                    <Space>
                                                        {el?.company?.company_name}
                                                        <BsFingerprint color='#1987e8' />
                                                    </Space>
                                                    :
                                                    el?.company?.company_name
                                        }
                                    </td>
                                    <td>{moment(el?.date).format("dddd, DD MMMM YYYY")}</td>
                                    <td style={{ textAlign: "center" }}>{el?.time_start} - {el?.time_end}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            ),
            key: 'working_location'
        }
    ]

    const columnsLocation = [
        {
            title: 'Company',
            render: (row) => (
                row.company_id === null ? "OFFICE" : row.company?.company_name
            ),
            key: 'company',
            align: 'center'
        },
        {
            title: 'Date',
            render: (row) => (
                moment(row?.date).format('dddd, DD MMMM YYYY')
            ),
            key: 'date',
            align: 'center'
        },
        {
            title: 'Time',
            render: (row) => (
                `${row?.time_start} - ${row?.time_end}`
            ),
            key: 'time',
            align: 'center'
        }
    ]

    const onChangeTab = (v) => {
        setDefaultTab(v)
    }

    const filterData = (v) => {
        const daily = dataDaily
        const workingLocation = dataWorkingLocation

        if (v.division === 99) {
            const filterDaily = daily.filter((el) => el.division_id === 13 || el.division_id === 24 || el.division_id === 33 || el.division_id === 34)
            const filterWorkingLocation = workingLocation.filter((el) => el.division_id === 13 || el.division_id === 24 || el.division_id === 33 || el.division_id === 34)

            setDataDailyFilter(filterDaily)
            setDataWorkingLocationFilter(filterWorkingLocation)
        } else {
            const filterDaily = daily.filter((el) => el.division_id === v.division)
            const filterWorkingLocation = workingLocation.filter((el) => el.division_id === v.division)

            setDataDailyFilter(filterDaily)
            setDataWorkingLocationFilter(filterWorkingLocation)
        }

        setIsFilter(true)
    }

    const resetFilter = () => {
        form.resetFields()

        fetchDataDaily({ first_date: moment(Date.now()).format('YYYY-MM-DD'), end_date: moment((Date.now())).format('YYYY-MM-DD'), employee_code: null })
        fetchWorkingLocation({ first_date: moment(Date.now()).format('YYYY-MM-DD'), end_date: moment((Date.now())).format('YYYY-MM-DD'), employee_code: null })

        setShowFilter(false)
        setIsFilter(false)
    }

    const onChangeYear = (v) => {
        let year = moment(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    const disabledDate = (current) => {
        let currentMonth = moment().format('MM');
        let addMonth = 1;

        if (currentMonth === '12') {
            addMonth = 2;
        }

        let customDate = moment(new Date()).add(addMonth, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");
    }

    const onSelectFetching = (v, key) => {
        setDefaultKey(key)
        setDefaultDate(v.date)
        fetchDataDaily({ first_date: v.date, end_date: v.date, division_id: null })
        fetchWorkingLocation({ first_date: v.date, end_date: v.date, division_id: null })
    }

    // const syncWorkingLocation = () => {
    //     SentralModel.action("Task", "syncWorkingLocation", { date: defaultDate }, 0).then((res) => {
    //         message.success("Success Synchronize")
    //         fetchWorkingLocation({ first_date: defaultDate, end_date: defaultDate, division_id: null })
    //         fetchDataDaily({ first_date: defaultDate, end_date: defaultDate, division_id: null })
    //     }).catch((err) => {
    //         message.warning("Failed Synchronize")
    //     })
    // }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Daily Activity & Working Location" breadcrumbs={[['Daily Activity & Working Location']]}></PageTitle>
                <Row className='mb-3' justify="end">
                    <Col>
                        <Space>
                            <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year" />
                            <Select placeholder="Select Period" style={{ width: 200 }} onChange={changePeriod}>
                                {
                                    periods.map((el, key) => (
                                        <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                    ))
                                }
                            </Select>
                        </Space>
                    </Col>
                </Row>

                <div className='mb-2'>
                    {
                        loadedPeriod ?
                            <Row justify='center' className='mb-3'>
                                <Col span={24}>
                                    <div className='scrollButton'>
                                        <Space>
                                            {
                                                datePeriod.map((el, key) => (
                                                    moment(el.date).format("dddd").toLowerCase() === "saturday" || moment(el.date).format("dddd").toLowerCase() === "sunday" ?
                                                        <Button key={key}
                                                            type="primary"
                                                            danger
                                                        >
                                                            {moment(el.date).format("DD MMM")}
                                                        </Button>
                                                        :
                                                        new Date(moment(el.date).format("YYYY-MM-DD")).getTime() === new Date(moment(defaultDate).format("YYYY-MM-DD")).getTime() || defaultKey === key ?
                                                            <Button key={key}
                                                                type="primary"
                                                                onClick=
                                                                {
                                                                    () => onSelectFetching(el, key)
                                                                }
                                                            >
                                                                {moment(el.date).format("DD MMM")}
                                                            </Button>
                                                            :
                                                            <Button key={key}
                                                                onClick=
                                                                {
                                                                    () => onSelectFetching(el, key)
                                                                }
                                                            >
                                                                {moment(el.date).format("DD MMM")}
                                                            </Button>
                                                ))
                                            }
                                        </Space>
                                    </div>
                                </Col>
                            </Row>
                            : null
                    }
                </div>

                {/* <div className='mb-2'>
                    <Row justify='end'>
                        <Col>
                            <Button type="primary" onClick={() => syncWorkingLocation()}>Sync Working Location</Button>
                        </Col>
                    </Row>
                </div> */}
                <Tabs type="card" defaultActiveKey={defaultTab} onChange={(v) => onChangeTab(v)}>
                    <TabPane tab="Daily Activity" key="daily_activity">
                        <Row justify='space-between' className='mb-3'>
                            <Col>
                                <Card title="Legend">
                                    <Space>
                                        <Space>
                                            <Tag color='green'>FI</Tag>
                                            <Text>Finshed</Text>
                                            |
                                        </Space>
                                        <Space>
                                            <Tag color='red'>NA</Tag>
                                            <Text>Not Assigned</Text>
                                            |
                                        </Space>
                                        <Space>
                                            <Tag color='green'>IP</Tag>
                                            <Text>In Progress</Text>
                                        </Space>
                                    </Space>
                                </Card>
                            </Col>
                            <Col>
                                <Space>
                                    {/* <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button> */}
                                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                                </Space>
                            </Col>
                        </Row>

                        {
                            loadedDaily ?
                                <DataTable size="small" columns={columns} dataSource={isFilter ? dataDailyFilter : dataDaily} bordered search={false} />
                                : <Spinner />
                        }
                    </TabPane>
                    <TabPane tab="Working Location" key="working_location">
                        <Row justify='end' className='mb-3'>
                            <Col>
                                <Space>
                                    {/* <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button> */}
                                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                                </Space>
                            </Col>
                        </Row>

                        {
                            loaded ?
                                <DataTable size="small" columns={columnWorkingLocation} dataSource={isFilter ? dataWorkingLocationFilter : dataWorkingLocation} bordered search={false} />
                                : <Spinner />
                        }
                    </TabPane>
                </Tabs>
            </Card>

            {
                showModalWorking ?
                    <BasicModal title="Working Location" width={600} showModal={showModalWorking} onCloseModal={() => setShowModalWorking(false)}>
                        <DataTable size="small" search={false} pagination={false} columns={columnsLocation} dataSource={locationData} bordered />
                    </BasicModal>
                    : null
            }

            {
                showFilter ?
                    <Drawer
                        title="Filter Data"
                        width={400}
                        placement="right"
                        closable={false}
                        onClose={() => setShowFilter(false)}
                        visible={showFilter}
                    >
                        <Form form={form} layout="vertical" onFinish={filterData}>
                            <Form.Item name="division" label="Division">
                                <Select placeholder="Select Division" allowClear onChange={(v) => setSelectedDivision(v)}>
                                    <Option value={99}>All IT Bizdev</Option>
                                    {
                                        division.map((el, key) => (
                                            <Option value={el.division_id} key={key}>{el.division_name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Space>
                                <Form.Item>
                                    <Button type='primary' htmlType='submit'>Submit</Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button type='primary' danger onClick={resetFilter}>Clear Filter</Button>
                                </Form.Item>
                            </Space>
                        </Form>
                    </Drawer>
                    : null
            }
        </div >
    )
}

export default Index