import React, {useEffect, useState} from 'react';
import {Card, Row, Col, Tabs, Typography, Space} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import { PageTitle, NewButton, DataTable, ShowButton, Spinner} from '../../../components'
import authData from '../../../helpers/authData'
import {SentralModel} from '../../../models/SentralModel'
import {formatDateTime} from '../../../utils/dateFormat'

const {Text} = Typography
const {TabPane} = Tabs;

const Index = () => {
    const [recruitments, setRecruitments] = useState(null);
    const [available, setAvailable] = useState(true)
    const [instruction, setInstruction] = useState('')

    const fetchRecruitment = () => {
        let payload={
            filter: [["requester", authData.employee_code]]
        }
        SentralModel.list('SubmissionRecruitment', payload).then(res => {
            setRecruitments(res.data)
        })
    }

    const fetchInstruction = () => {
        SentralModel.list('Instruction', {filter:[['code', 'SBRC']]}, null).then((res) => {
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission', 'checkSchema', {category: 'RECRUITMENT', division_id: authData.division_id}).then((res)=>{
            setAvailable(res.message)
        })
    }

    useEffect(() => {
        fetchRecruitment()
        checkApprovalSchema()
        fetchInstruction()
    }, []);
    
    const recruitmentData = (recruitments) ? [
        ...recruitments.map(el => {
            return { 
                id: el.sub_recruitment_id,
                status: el.status,
                division: el.division.division_name,
                position: el.position.position_name,
                subject: el.subject,
                approver: el.approver,
                created_at: formatDateTime(el.created_at)
            }
        })
    ] : [];

    const recruitmentColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            align:'center',
            width:'70px'
        },{
            title: 'Division',
            dataIndex: 'division',
            key: 'division',
        },{
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
        },{
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },{
            title: 'Action',
            align:'center',
            width:'200px',
            render : (row) => (
                <Space>
                    <ShowButton link to={'/recruitment/' + row.id} />
                </Space>
            )
        }
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Recruitment" breadcrumbs={[['Recruitment']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Recruitment Request</Text>
                        <NewButton disabled={!available} right to="/recruitment/create" title={available ? "Request Employee" : 'Approval Not Available'}/>
                    </div>
                }>
                    {
                        instruction ?
                            <Row>
                                <Col span={24} className="instruction-box">
                                    <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                                </Col>
                            </Row>
                        : <Spinner />
                    }
                </Card>
                <br/>
                {
                    (recruitments) ?
                        <Tabs defaultActiveKey="waiting" type="card">
                            <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                                <DataTable size="small" columns={recruitmentColumn} dataSource={recruitmentData.filter(el => el.status==="WAITING")} bordered/>
                            </TabPane>
                            <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                                <DataTable size="small" columns={recruitmentColumn} dataSource={recruitmentData.filter(el => el.status==="APPROVED")} bordered/>
                            </TabPane>
                            <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                                <DataTable size="small" columns={recruitmentColumn} dataSource={recruitmentData.filter(el => el.status==="REJECTED")} bordered/>
                            </TabPane>
                        </Tabs>
                    : null
                }

            </Card>
        </div>
    );
}

export default Index;
