import {message} from 'antd'

export const CustomModel = {
    employeeList,
    version,
    boostGet,
    boostPost,
    boost
};

function employeeList() {
    const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/auth/employeeList`, requestOptions).then(handleResponse);
}

function version() {
    const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/auth/version`, requestOptions).then(handleResponse);
}

function boostGet(func, param) {
    const requestOptions = {
        method: 'GET',
    };

    return fetch(`${process.env.REACT_APP_API_BOOST}/APIKarajo/`+func+`/`+param, requestOptions).then(handleResponse);
}

function boostPost(func, payload) {
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    };

    return fetch(`${process.env.REACT_APP_API_BOOST}/APISAPSMI/`+func, requestOptions).then(handleResponse);
}

function boost(path, func, payload) {
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    };

    return fetch(`${process.env.REACT_APP_API_BOOST}/`+path+`/`+func, requestOptions).then(handleResponse);
}

export function handleResponse(response) {
    return response.json().then(res => {
        if(response.ok){
            return res
        }else{
            const error = response.statusText;
            message.error(error,2)
            return Promise.reject();
        }
    });
}
