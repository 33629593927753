import React from 'react'
import { Row, Col, Button, Form, Input } from 'antd';
import { Uploader } from '../../../../components';

const FormatTicketSoftware = (props) => {
    const { saveSubmit } = props

    return (
        <Form layout='vertical' onFinish={(v) => saveSubmit(v)}>
            <Form.Item label="Complain/Problem" name="complain" required rules={[{ required: true }]}>
                <Input placeholder='Complain/Problem' />
            </Form.Item>
            <Form.Item label="Description" name="description" required rules={[{ required: true }]}>
                <Input.TextArea rows={5} placeholder='Description...' />
            </Form.Item>
            <Form.Item label="Attachment">
                <Uploader folder="attachment-ticket-software" onUploaded={(v) => props.changeFileUploaded(v)} isUploading={(v) => props.changeSetUploading(v)} view={true} />
            </Form.Item>
            <Row justify='end'>
                <Col>
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>Save</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default FormatTicketSoftware