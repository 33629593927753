import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Select, DatePicker } from 'antd';
import { SubmitButton, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import moment from 'moment'

const { Option } = Select;
const { RangePicker } = DatePicker

const RequestForm = (props) => {
    const [recForm] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    const [divisions, setDivisions] = useState([]);
    const [positions, setPositions] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [educationLevels, setEducationLevels] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [selectedEducation, setSelectedEducation] = useState('ETS1');

    const fetchDivisions = () => {
        SentralModel.list('Division', { orderBy: "division_name", order: "asc" }).then(res => {
            setDivisions(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchPositions = () => {
        SentralModel.list('Position', { orderBy: "position_name", order: "asc", filter: [['is_active', '1']] }).then(res => {
            setPositions(res.data)
        }).finally(() => {
            // setLoaded(true)
        })
    }

    const fetchProvinces = () => {
        SentralModel.list('Province', { orderBy: "province_name", order: "asc" }).then(res => {
            setProvinces(res.data)
        }).finally(() => {
            // setLoaded(true)
        })
    }

    const fetchEducationLevels = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ET'], ['detail_code', '!=', 'ETBS'], ['detail_code', '!=', 'ETSD'], ['detail_code', '!=', 'ETSLTP']], orderBy: "order_key", order: "asc" }).then(res => {
            setEducationLevels(res.data)
        }).finally(() => {
            // setLoaded(true)
        })
    }

    useEffect(() => {
        fetchDivisions()
        fetchPositions()
        fetchProvinces()
        fetchEducationLevels()
    }, []);

    const changeDivision = e => {
        setSelectedDivision(e)
    }

    const changePosition = e => {
        setSelectedPosition(e)
    }

    const changeProvince = e => {
        setSelectedProvince(e)
    }

    const changeEducation = e => {
        setSelectedEducation(e)
        console.log(e)
    }

    const onChangeExp = e => {
        const { value } = e.target;
        if (isNaN(value)) {
            recForm.setFieldsValue({ min_work_experience: value.replace(/[^0-9]+/g, "") })
        }
    }

    const onChangeAge = e => {
        const { value } = e.target;
        if (isNaN(value)) {
            recForm.setFieldsValue({ age_limit: value.replace(/[^0-9]+/g, "") })
        }
    }

    const onChangeQuota = e => {
        const { value } = e.target;
        if (isNaN(value)) {
            recForm.setFieldsValue({ quota: value.replace(/[^0-9]+/g, "") })
        }
    }

    const selectBefore = (
        <Select placeholder="Select" defaultValue={selectedEducation} onChange={changeEducation}>
            {
                educationLevels.map((el, key) => (
                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                ))
            }
        </Select>
    );

    const submitRecruitment = (v) => {
        let submittedData = {
            division_id: selectedDivision,
            position_id: selectedPosition,
            subject: v.subject,
            from_date: moment(v.date[0], 'DD MMMM YYYY').format('YYYY-MM-DD'),
            to_date: moment(v.date[1], 'DD MMMM YYYY').format('YYYY-MM-DD'),
            min_work_experience: v.min_work_experience,
            education_level: selectedEducation,
            min_education: v.min_education,
            age_limit: v.age_limit,
            quota: v.quota,
            work_location: selectedProvince,
            requirement: v.requirement,
            job_description: v.job_description
        }
        props.onSubmit(submittedData)
    }


    return (
        loaded ?
            <Form size="middle" form={recForm} layout="vertical" onFinish={submitRecruitment}>
                <Form.Item name="rec_vacancy_id" hidden>
                    <Input placeholder="Vacancy ID" />
                </Form.Item>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Division" name="division_id" rules={[{ required: true }]}>
                            <Select showSearch style={{ width: "100%" }} placeholder="Select Division" optionFilterProp="children" onChange={changeDivision} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {
                                    divisions.map((el, key) => (
                                        <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Position" name="position_id" rules={[{ required: true }]}>
                            <Select showSearch style={{ width: "100%" }} placeholder="Select Position" optionFilterProp="children" onChange={changePosition} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {
                                    positions.map((el, key) => (
                                        <Option key={key} value={el.position_id}>{el.position_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Subject" name="subject" rules={[{ required: true }]}>
                            <Input placeholder="Subject" />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Work Experience" name="min_work_experience" rules={[{ required: true }]}>
                            <Input addonBefore="Min" addonAfter="Year(s)" onChange={onChangeExp} placeholder="Work Experience" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Work Location" name="work_location" rules={[{ required: true }]}>
                            <Select showSearch style={{ width: "100%" }} placeholder="Select Location" optionFilterProp="children" onChange={changeProvince} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {
                                    provinces.map((el, key) => (
                                        <Option key={key} value={el.province_id}>{el.province_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Age Limit" name="age_limit" rules={[{ required: true }]}>
                            <Input onChange={onChangeAge} placeholder="Age Limit" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Recruitment Date" name="date" rules={[{ required: true }]}>
                            <RangePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Quota" name="quota" rules={[{ required: true }]}>
                            <Input onChange={onChangeQuota} placeholder="Quota" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Minimum Education" name="min_education" rules={[{ required: true }]}>
                            <Input addonBefore={selectBefore} placeholder="Education" />
                        </Form.Item>
                    </Col>
                    <Col span={11}></Col>
                </Row>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Requirement" name="requirement" rules={[{ required: true }]}>
                            <Input.TextArea rows={4} placeholder="Requirement" />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Job Description" name="job_description" rules={[{ required: true }]}>
                            <Input.TextArea rows={4} placeholder="Job Description" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={11} />
                    <Col span={11}>
                        <SubmitButton right loading={props.saving} />
                    </Col>
                </Row>
            </Form>
            : <Spinner />
    );
}

export default RequestForm;
