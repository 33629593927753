import React,{useState, useEffect} from 'react';
import {Card, Row, Col, Tabs, Typography, Descriptions, Modal, Timeline} from 'antd'
import {CalendarOutlined} from '@ant-design/icons'
import { NewButton, PageTitle, Spinner} from '../../../components'
import OvertimeRequest from './OvertimeRequest'
import RealizationRequest from './RealizationRequest'
import authData from '../../../helpers/authData'
import {SentralModel} from '../../../models/SentralModel'
import moment from 'moment'

const {TabPane} = Tabs;
const {Text} = Typography
const Index = () => {
    const [overtimes, setOvertimes] = useState([])
    const [overtimeInfo, setOvertimeInfo] = useState(null)
    const [instruction, setInstruction] = useState('')
    const [showLogs, setShowLogs] = useState(false)
    const [logsData, setLogsData] = useState([])
    const [available, setAvailable] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const fetchOvertimes = () => {
        setLoaded(false)
        let payload={
            filter: [["employee_code", authData.employee_code]]
        }
        SentralModel.list('SubmissionOvertime', payload).then(res=>{
            setOvertimes(res.data)
            setLoaded(true)
        })
    }

    const fetchEmployeeOvertime = () => {
        SentralModel.action('Submission','getOvertimeThisMonth', null, null).then((res)=>{
            setOvertimeInfo(res.data)
        })
    }
    const fetchInstruction = () => {
        SentralModel.list('Instruction',{filter:[['code','SBOV']]}, null).then((res)=>{
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission','checkSchema',{category: 'OVERTIME', division_id:authData.division_id}).then((res)=>{
            setAvailable(res.message)
        })
    }

    useEffect(() => {
        fetchOvertimes()
        fetchEmployeeOvertime()
        checkApprovalSchema()
        fetchInstruction()
    }, []);
    
    const showRequestLog = (v) =>{
        let x=[]
        x.push({color:'green', date:v.created_at, description: 'Request Submitted', remarks:null, status:'success'})
        v.approver.forEach(el => {
            if(el.status==="APPROVED"){
                x.push({color:'green', date:el.submitted_at, description: <span>{el.employee.name}  Approved your request</span>, remarks: el.remarks,  status:'success'})
            }else if(el.status==="REJECTED"){
                x.push({color:'red', date:el.submitted_at, description: <span>{el.employee.name}  Rejected your request</span>, remarks: el.remarks,  status:'danger'})
            }else{
                x.push({color:'gray', date:null, description: 'Waiting approval '+el.employee.name, remarks:null, status:"default"})
            }
        });
        setLogsData(x)
        setShowLogs(true)
    }

    return (
        <Card className="content-container">
            <PageTitle title="Overtime" breadcrumbs={[['Overtime']]}></PageTitle>

            <Card title={
                <div className="full-width">
                    <Text strong style={{float:'left'}}>Pengajuan Lembur</Text>
                    <NewButton disabled={!available} right to="/overtime/create" title={(available) ? "Apply for overtime" : 'Approval not available'}/>
                </div>
            }>
                {
                    (loaded) ? 
                    <Row justify="space-between">
                        <Col span={4}>
                            <Descriptions size="middle" column={1} bordered layout="vertical">
                                <Descriptions.Item label={<Text strong>Overtime This Month</Text>}>
                                    {
                                        (overtimeInfo) ? overtimeInfo.total_overtime : 0
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label={<Text strong>Total Hours</Text>}>
                                    {
                                        (overtimeInfo) ? overtimeInfo.total_hour : 0
                                    } hours
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={19} className="instruction-box">
                            <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                        </Col>
                    </Row> : <Spinner/>
                }
            </Card>
            <br/>
            {
                (loaded) ? 
                <Tabs defaultActiveKey="request" type="card">
                    <TabPane tab={<span><CalendarOutlined style={{color:'#4ca3ff'}}/> Overtime Request</span>} key="request">
                        <OvertimeRequest data={overtimes.filter(el => el.status_realization === null || el.status_realization === 'REJECTED')} showRequestLog={(v)=> showRequestLog(v)} />
                    </TabPane>
                    <TabPane tab={<span><CalendarOutlined style={{color:'green'}}/> Realization Request</span>} key="realization">
                        <RealizationRequest data={overtimes.filter(el => el.status_realization !== null)} showRequestLog={(v)=> showRequestLog(v)} />
                    </TabPane>
                </Tabs> : null
            }
            
            {
                (showLogs) ?
                <Modal
                    title="Request Status"
                    visible={showLogs}
                    footer={null}
                    onCancel={() => setShowLogs(false)}>
                    <Timeline>
                        {
                            logsData.map((el, key) => (
                                <Timeline.Item key={key} color={el.color}>
                                    {
                                        (el.status==="success" || el.status==="danger") ?
                                        <div>
                                            <span style={{fontWeight:'bold'}}>{moment(el.date).format('D MMMM YYYY, h:mm a')}</span><br/>
                                            <span>{el.description}</span><br/>
                                            <span>{el.remarks}</span>
                                        </div> : 
                                        <div>
                                            <span className="text-muted" style={{fontWeight:'bold'}}>{el.description}</span>
                                        </div>
                                    }
                                </Timeline.Item>
                            ))
                        }
                    </Timeline>
                </Modal>
                : null
            }
        </Card>
    );
}

export default Index;
