import React, { useEffect, useState } from 'react';
import { Card, Typography, Space, Button, Select, Empty, Tabs, Row, Col, Statistic } from 'antd'
import { Link } from 'react-router-dom'
import { BasicModal, PageTitle, Spinner, DataTable } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { UsergroupAddOutlined, FormOutlined, ZoomInOutlined, BarsOutlined, SettingOutlined, CalendarOutlined, BarChartOutlined } from '@ant-design/icons'
import authData from '../../../helpers/authData'
import { decrypt } from '../../../helpers/crypto'
import { setSessionStorage, getSessionStorage } from '../../../helpers/sessionStorage';

const { Text } = Typography
const { Option } = Select
const { TabPane } = Tabs;

const Index = () => {
    const [appraisals, setAppraisals] = useState([])
    const [evaluators, setEvaluators] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [loadedEvaluator, setLoadedEvaluator] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [activeYear, setActiveYear] = useState('')
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [divisions, setDivisions] = useState([])
    const [roles, setRoles] = useState([])
    const [employeeNotAssessed, setEmployeeNotAssessed] = useState([])
    const [assessed, setAssessed] = useState(0)
    const [notAssessed, setNotAssessed] = useState(0)

    const initialDivision = () => {
        if (getSessionStorage('appraisalDivision')) {
            return parseInt(getSessionStorage('appraisalDivision'))
        }
        return null
    }

    const fetchAppraisals = (divisionId = 0) => {
        const role = localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : []
        setLoaded(false)
        SentralModel.action('Appraisal', 'getAppraisal', { division_id: divisionId, role: (role.includes('HR') || role.includes('SUPERADMIN')) ? 'HR' : 'USER' }).then(res => {
            const v = res.data.employees
            setAppraisals(res.data.employees)
            setActiveYear(res.data.year)
            setRoles(role)

            let assessed = 0
            let notAssessed = 0
            let listEmployeeNotAssed = []
            v.forEach((el, key) => {
                if (el.appraisal !== null) {
                    el.appraisal.evaluators.forEach((item) => {
                        if (item.evaluator_code === authData.employee_code && item.kpi_value !== null) {
                            return assessed += 1;
                        } else if (item.evaluator_code === authData.employee_code && item.kpi_value === null) {
                            listEmployeeNotAssed.push(el)
                            return notAssessed += 1;
                        }
                    })
                }
            })

            setAssessed(assessed)
            setNotAssessed(notAssessed)
            setEmployeeNotAssessed(listEmployeeNotAssed)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchEvaluators = () => {
        setLoadedEvaluator(false)
        SentralModel.action('Appraisal', 'checkEvaluator', {}).then(res => {
            setEvaluators(res.data)
        }).finally(() => {
            setLoadedEvaluator(true)
        })
    }

    const fetchDivisions = () => {
        SentralModel.list('Division', { fields: "division_id,division_name", orderBy: "division_name", order: "ASC" }).then((res) => {
            setDivisions(res.data)
        })
    }

    useEffect(() => {
        let divisionId = sessionStorage.getItem('appraisalDivision') || 0
        if (divisionId) {
            setSelectedDivision(parseInt(divisionId))
        } else {
            setSelectedDivision(0)
        }
        fetchDivisions()
        // const defaultDivision = getSessionStorage('appraisalDivision')
        // if(defaultDivision){
        //     fetchAppraisals(defaultDivision)
        //     setSessionStorage('appraisalDivision', defaultDivision)
        //     setSelectedDivision(defaultDivision)
        // }

        fetchAppraisals(divisionId)
        fetchEvaluators()
        // eslint-disable-next-line
    }, []);

    const changeDivision = (v) => {
        setLoaded(false)
        fetchAppraisals(v)
        setSessionStorage('appraisalDivision', v)
        setSelectedDivision(v)
        // sessionStorage.setItem('selectedAppraisalDivision',v)
    }

    const onShowModal = () => {
        setShowModal(true)
        setModalTitle('Total Assessed')
    }

    const columns = [
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name'
        }
    ]

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/kpiResult')
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title={`Appraisal ${activeYear} (Jan - Des ${activeYear - 1})`} breadcrumbs={[['Appraisal']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Appraisal List</Text>
                        {
                            roles.includes('HR') || roles.includes("SUPERADMIN") || roles.includes('HEAD') ?
                                <Space style={{ float: 'right' }}>
                                    <Button type='primary' onClick={exportReport}>Export Result</Button>
                                    {
                                        roles.includes('HR') || roles.includes("SUPERADMIN") ?
                                            <Link to="/management/appraisal/set-period">
                                                <Button type="primary" icon={<CalendarOutlined />}>
                                                    Setup Periode
                                                </Button>
                                            </Link>
                                            : null
                                    }

                                    {
                                        authData.employee_code === 'SMI20100628033' || authData.employee_code === 'SMI20200622202' || authData.employee_code === 'SMI20150601108' || authData.employee_code === 'SMI20180322139' || authData.employee_code === 'SMI20000101001' || authData.employee_code === 'SMI20000101003' || authData.employee_code === 'SMI20000101002' ?
                                            <Link to="/management/appraisal/setup">
                                                <Button type="primary" icon={<SettingOutlined />}>
                                                    Akumulasi
                                                </Button>
                                            </Link>
                                            : null
                                    }

                                    {
                                        loadedEvaluator ?
                                            (evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0) ?
                                                <Link to="/management/appraisal/ranking-evaluator">
                                                    <Button type="primary" icon={<BarChartOutlined />}>
                                                        Ranking Evaluator
                                                    </Button>
                                                </Link>
                                                : null
                                            : null
                                    }

                                    {
                                        authData.employee_code === 'SMI20100628033' || authData.employee_code === 'SMI20200622202' || authData.employee_code === 'SMI20150601108' || authData.employee_code === 'SMI20180322139' || authData.employee_code === 'SMI20000101001' || authData.employee_code === 'SMI20000101003' || authData.employee_code === 'SMI20000101002' ?
                                            <Link to="/management/appraisal/ranking">
                                                <Button type="primary" icon={<BarChartOutlined />}>
                                                    Ranking Keseluruhan
                                                </Button>
                                            </Link>
                                            : null
                                    }
                                </Space>
                                : null
                        }
                    </div>}>

                    <Row>
                        <div className="full-width">
                            <Row className="mb-3" justify='space-between'>
                                <Col>
                                    <Button type='primary' onClick={onShowModal}>Show Total Assessed</Button>
                                </Col>
                                <Col>
                                    <Space>
                                        <Select placeholder="Select Division" defaultValue={initialDivision} style={{ width: 250 }} onChange={changeDivision} loading={divisions.length === 0}>
                                            <Option key="division_all" value={0}>Semua Divisi</Option>
                                            {
                                                divisions.map((el, key) => (
                                                    <Option key={'division_' + key} value={el.division_id}>{el.division_name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                    </Row>

                    {
                        (loaded) ?
                            <div>
                                <Tabs defaultActiveKey="appraisal" type="card">
                                    <TabPane tab={<span>Permanent</span>} key="permanent">
                                        <table className="table-collapse table-default" style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Employee Name</th>
                                                    <th>Division Name</th>
                                                    {
                                                        (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                            <th>Evaluator</th>
                                                            : null
                                                    }
                                                    <th className="text-center">KPI</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    appraisals.filter(el => el.employee_status === 'ESPR').map((el, i) => (
                                                        <tr key={i}>
                                                            <td style={{ verticalAlign: 'top', textAlign: 'center' }}>{i + 1}</td>
                                                            <td style={{ verticalAlign: 'top' }}>{el.name}</td>
                                                            <td style={{ verticalAlign: 'top' }}>{(el.division) ? el.division.division_name : ''}</td>
                                                            {
                                                                (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                    <td style={{ padding: '0px' }}>
                                                                        {
                                                                            (el.appraisal) ?
                                                                                <table className="table-noborder" style={{ width: '100%', margin: '0px' }}>
                                                                                    <tbody>
                                                                                        {
                                                                                            el.appraisal.evaluators.map((ev, x) => (
                                                                                                <tr key={x}>
                                                                                                    <td>{ev.employee.name}</td>
                                                                                                    <td className="text-center" style={{ width: '70px' }}>{ev.kpi_value}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                                : null
                                                                        }
                                                                    </td>
                                                                    : null
                                                            }
                                                            <td className="text-center" style={{ verticalAlign: 'top' }}>
                                                                {
                                                                    (el.appraisal) ?
                                                                        (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                            el.appraisal.kpi_value?.toFixed(2) : el.appraisal.evaluators[0].kpi_value?.toFixed(2)
                                                                        : ''
                                                                }
                                                            </td>
                                                            <td style={{ width: '15%', verticalAlign: 'top', padding: 0, paddingTop: '5px' }}>
                                                                <Space>
                                                                    {
                                                                        (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                            (!el.appraisal) ?
                                                                                <div>
                                                                                    <Link to={'/management/appraisal/set-item/' + el.employee_code}>
                                                                                        <Button type="link" icon={<BarsOutlined />} />
                                                                                    </Link>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {
                                                                                        (!el.appraisal.kpi_value) ?
                                                                                            <div>
                                                                                                <Link to={'/management/appraisal/set-item/' + el.employee_code}>
                                                                                                    <Button type="link" icon={<BarsOutlined />} />
                                                                                                </Link>
                                                                                                <Link to={'/management/appraisal/set-evaluator/' + el.appraisal.kpi_appraisal_id}>
                                                                                                    <Button type="link" icon={<UsergroupAddOutlined />} />
                                                                                                </Link>
                                                                                                {
                                                                                                    (el.appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0) ?
                                                                                                        <Link to={'/management/appraisal/form-appraisal/' + el.appraisal.kpi_appraisal_id}>
                                                                                                            <Button type="link" icon={<FormOutlined />} />
                                                                                                        </Link>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (el.appraisal.evaluators.length > 0) ?
                                                                                                        <Link to={'/management/appraisal/detail?division=' + selectedDivision + '&appraisalId=' + el.appraisal.kpi_appraisal_id}>
                                                                                                            <Button type="link" icon={<ZoomInOutlined />} />
                                                                                                        </Link>
                                                                                                        : null
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            (el.appraisal.evaluators.length > 0) ?
                                                                                                <Link to={'/management/appraisal/detail?division=' + selectedDivision + '&appraisalId=' + el.appraisal.kpi_appraisal_id}>
                                                                                                    <Button type="link" icon={<ZoomInOutlined />} />
                                                                                                </Link>
                                                                                                : null
                                                                                    }
                                                                                </div>
                                                                            :
                                                                            (el.appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0 && !el.appraisal.kpi_value) ?
                                                                                <Link to={'/management/appraisal/form-appraisal/' + el.appraisal.kpi_appraisal_id}>
                                                                                    <Button type="link" icon={<FormOutlined />} />
                                                                                </Link>
                                                                                : <span className="text-muted" style={{ paddingLeft: 5 }}>Penilaian sudah dikunci</span>
                                                                    }
                                                                </Space>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                {
                                                    (appraisals.length === 0) ?
                                                        <tr><td colSpan={5}><Empty /></td></tr> : null
                                                }
                                            </tbody>

                                        </table>
                                    </TabPane>

                                    <TabPane tab={<span>Probation</span>} key="probation">
                                        <table className="table-collapse table-default" style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Employee Name</th>
                                                    <th>Division Name</th>
                                                    {
                                                        (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                            <th>Evaluator</th>
                                                            : null
                                                    }
                                                    <th className="text-center">KPI</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    appraisals.filter(el => el.employee_status === 'ESPB').map((el, i) => (
                                                        <tr key={i}>
                                                            <td style={{ verticalAlign: 'top', textAlign: 'center' }}>{i + 1}</td>
                                                            <td style={{ verticalAlign: 'top' }}>{el.name}</td>
                                                            <td style={{ verticalAlign: 'top' }}>{(el.division) ? el.division.division_name : ''}</td>
                                                            {
                                                                (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                    <td style={{ padding: '0px' }}>
                                                                        {
                                                                            (el.appraisal) ?
                                                                                <table className="table-noborder" style={{ width: '100%', margin: '0px' }}>
                                                                                    <tbody>
                                                                                        {
                                                                                            el.appraisal.evaluators.map((ev, x) => (
                                                                                                <tr key={x}>
                                                                                                    <td>{ev.employee.name}</td>
                                                                                                    <td className="text-center" style={{ width: '70px' }}>{ev.kpi_value}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                                : null
                                                                        }
                                                                    </td>
                                                                    : null
                                                            }
                                                            <td className="text-center" style={{ verticalAlign: 'top' }}>
                                                                {
                                                                    (el.appraisal) ?
                                                                        (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                            el.appraisal.kpi_value?.toFixed(2) : el.appraisal.evaluators[0].kpi_value?.toFixed(2)
                                                                        : ''
                                                                }
                                                            </td>
                                                            <td style={{ width: '15%', verticalAlign: 'top', padding: 0, paddingTop: '5px' }}>
                                                                <Space>
                                                                    {
                                                                        (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                            (!el.appraisal) ?
                                                                                <div>
                                                                                    <Link to={'/management/appraisal/set-item/' + el.employee_code}>
                                                                                        <Button type="link" icon={<BarsOutlined />} />
                                                                                    </Link>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {
                                                                                        (!el.appraisal.kpi_value) ?
                                                                                            <div>
                                                                                                <Link to={'/management/appraisal/set-item/' + el.employee_code}>
                                                                                                    <Button type="link" icon={<BarsOutlined />} />
                                                                                                </Link>
                                                                                                <Link to={'/management/appraisal/set-evaluator/' + el.appraisal.kpi_appraisal_id}>
                                                                                                    <Button type="link" icon={<UsergroupAddOutlined />} />
                                                                                                </Link>
                                                                                                {
                                                                                                    (el.appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0) ?
                                                                                                        <Link to={'/management/appraisal/form-appraisal/' + el.appraisal.kpi_appraisal_id}>
                                                                                                            <Button type="link" icon={<FormOutlined />} />
                                                                                                        </Link>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (el.appraisal.evaluators.length > 0) ?
                                                                                                        <Link to={'/management/appraisal/detail?division=' + selectedDivision + '&appraisalId=' + el.appraisal.kpi_appraisal_id}>
                                                                                                            <Button type="link" icon={<ZoomInOutlined />} />
                                                                                                        </Link>
                                                                                                        : null
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            (el.appraisal.evaluators.length > 0) ?
                                                                                                <Link to={'/management/appraisal/detail?division=' + selectedDivision + '&appraisalId=' + el.appraisal.kpi_appraisal_id}>
                                                                                                    <Button type="link" icon={<ZoomInOutlined />} />
                                                                                                </Link>
                                                                                                : null
                                                                                    }
                                                                                </div>
                                                                            :
                                                                            (el.appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0 && !el.appraisal.kpi_value) ?
                                                                                <Link to={'/management/appraisal/form-appraisal/' + el.appraisal.kpi_appraisal_id}>
                                                                                    <Button type="link" icon={<FormOutlined />} />
                                                                                </Link>
                                                                                : <span className="text-muted" style={{ paddingLeft: 5 }}>Penilaian sudah dikunci</span>
                                                                    }
                                                                </Space>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                {
                                                    (appraisals.length === 0) ?
                                                        <tr><td colSpan={5}><Empty /></td></tr> : null
                                                }
                                            </tbody>

                                        </table>
                                    </TabPane>

                                    <TabPane tab={<span>Contract</span>} key="contract">
                                        <table className="table-collapse table-default" style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Employee Name</th>
                                                    <th>Division Name</th>
                                                    {
                                                        (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                            <th>Evaluator</th>
                                                            : null
                                                    }
                                                    <th className="text-center">KPI</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    appraisals.filter(el => el.employee_status === 'ESKN').map((el, i) => (
                                                        <tr key={i}>
                                                            <td style={{ verticalAlign: 'top', textAlign: 'center' }}>{i + 1}</td>
                                                            <td style={{ verticalAlign: 'top' }}>{el.name}</td>
                                                            <td style={{ verticalAlign: 'top' }}>{(el.division) ? el.division.division_name : ''}</td>
                                                            {
                                                                (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                    <td style={{ padding: '0px' }}>
                                                                        {
                                                                            (el.appraisal) ?
                                                                                <table className="table-noborder" style={{ width: '100%', margin: '0px' }}>
                                                                                    <tbody>
                                                                                        {
                                                                                            el.appraisal.evaluators.map((ev, x) => (
                                                                                                <tr key={x}>
                                                                                                    <td>{ev.employee.name}</td>
                                                                                                    <td className="text-center" style={{ width: '70px' }}>{ev.kpi_value}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                                : null
                                                                        }
                                                                    </td>
                                                                    : null
                                                            }
                                                            <td className="text-center" style={{ verticalAlign: 'top' }}>
                                                                {
                                                                    (el.appraisal) ?
                                                                        (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                            el.appraisal.kpi_value?.toFixed(2) : el.appraisal.evaluators[0].kpi_value?.toFixed(2)
                                                                        : ''
                                                                }
                                                            </td>
                                                            <td style={{ width: '15%', verticalAlign: 'top', padding: 0, paddingTop: '5px' }}>
                                                                <Space>
                                                                    {
                                                                        (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                                            (!el.appraisal) ?
                                                                                <div>
                                                                                    <Link to={'/management/appraisal/set-item/' + el.employee_code}>
                                                                                        <Button type="link" icon={<BarsOutlined />} />
                                                                                    </Link>
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {
                                                                                        (!el.appraisal.kpi_value) ?
                                                                                            <div>
                                                                                                <Link to={'/management/appraisal/set-item/' + el.employee_code}>
                                                                                                    <Button type="link" icon={<BarsOutlined />} />
                                                                                                </Link>
                                                                                                <Link to={'/management/appraisal/set-evaluator/' + el.appraisal.kpi_appraisal_id}>
                                                                                                    <Button type="link" icon={<UsergroupAddOutlined />} />
                                                                                                </Link>
                                                                                                {
                                                                                                    (el.appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0) ?
                                                                                                        <Link to={'/management/appraisal/form-appraisal/' + el.appraisal.kpi_appraisal_id}>
                                                                                                            <Button type="link" icon={<FormOutlined />} />
                                                                                                        </Link>
                                                                                                        : null
                                                                                                }
                                                                                                {
                                                                                                    (el.appraisal.evaluators.length > 0) ?
                                                                                                        <Link to={'/management/appraisal/detail?division=' + selectedDivision + '&appraisalId=' + el.appraisal.kpi_appraisal_id}>
                                                                                                            <Button type="link" icon={<ZoomInOutlined />} />
                                                                                                        </Link>
                                                                                                        : null
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            (el.appraisal.evaluators.length > 0) ?
                                                                                                <Link to={'/management/appraisal/detail?division=' + selectedDivision + '&appraisalId=' + el.appraisal.kpi_appraisal_id}>
                                                                                                    <Button type="link" icon={<ZoomInOutlined />} />
                                                                                                </Link>
                                                                                                : null
                                                                                    }
                                                                                </div>
                                                                            :
                                                                            (el.appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0 && !el.appraisal.kpi_value) ?
                                                                                <Link to={'/management/appraisal/form-appraisal/' + el.appraisal.kpi_appraisal_id}>
                                                                                    <Button type="link" icon={<FormOutlined />} />
                                                                                </Link>
                                                                                : <span className="text-muted" style={{ paddingLeft: 5 }}>Penilaian sudah dikunci</span>
                                                                    }
                                                                </Space>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                {
                                                    (appraisals.length === 0) ?
                                                        <tr><td colSpan={5}><Empty /></td></tr> : null
                                                }
                                            </tbody>

                                        </table>
                                    </TabPane>
                                </Tabs>
                            </div>
                            :
                            <Spinner />
                    }
                </Card>
            </Card>

            {
                <BasicModal title={modalTitle} width={600} showModal={showModal} onCloseModal={() => setShowModal(false)}>
                    <Row className='mb-3' justify='space-between'>
                        <Col>
                            <Card style={{ width: 250 }}>
                                <Statistic title="Total Assessed" value={assessed} />
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: 250 }}>
                                <Statistic title="Total Not Assessed" value={notAssessed} />
                            </Card>
                        </Col>
                    </Row>
                    <Card title="List Employee Not Assessed">
                        <DataTable dataSource={employeeNotAssessed} columns={columns} search={false} pagination={false} />
                    </Card>
                </BasicModal>
            }
        </div>
    );
}

export default Index;
