import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Select, DatePicker, Button } from 'antd';
import { BasicModal, DataTable, DeleteButton, SubmitButton } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
// import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link,
} from "suneditor/src/plugins"

const RequestForm = (props) => {
    // const {pData=null} = props
    const [pForm] = Form.useForm()
    const [employees, setEmployees] = useState([])
    const [searching, setSearching] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState("")
    const [time, setTime] = useState(0)
    const [content, setContent] = useState('')
    const [punishmentTypes, setPunishmentTypes] = useState([])
    const [punishmentReference, setPunishmentReference] = useState([])
    const [reference, setReference] = useState([])

    const setDefaultFormValue = (data, pForm) => {
        let arr = []
        pForm.setFieldsValue({
            date: moment(data.date),
            information: data.description,
            punishment_type: data.punishment_type,
            employee_code: data.employee_code
        })

        data.punishment_reference.forEach((el) => {
            arr.push({ emp_reward_punishment_id: el?.emp_reward_punishment_id, description: el.list_complain?.description })
        })

        fetchPunishmentReference(data.employee_code)
        setReference(arr)
        setContent(data.description)
    }

    const fetchPunishmentType = () => {
        let payload = {
            filter: [["general_code", "PNM"]],
            orderBy: 'order_key',
            order: 'asc'
        }
        SentralModel.list('GeneralDetail', payload).then((res) => {
            setPunishmentTypes(res.data)
        })
    }

    const fetchPunishmentReference = (v) => {
        let payload = {
            employee_code: v,
            category: "PUNISHMENT"
        }

        SentralModel.action("EmployeeRewardPunishment", "getReferenceData", payload, 0).then((res) => {
            setPunishmentReference(res.data)
        })
    }

    const showPunishmentList = () => {
        setShowModal(true)
        setModalTitle("List Punishment")
    }

    const searchEmployee = (v) => {
        if (time) clearTimeout(time)
        if (v) {
            setSearching(true)
            setTime(setTimeout(() => {
                SentralModel.list('Employee', { filter: [['name', 'like', '%' + v + '%']], fields: 'employee_code,name,division_id' }).then((res) => {
                    setEmployees(res.data)
                    setSearching(false)
                })
            }, 500))
        } else {
            setEmployees([])
        }
    }

    useEffect(() => {
        fetchPunishmentType()
        if (props.data) {
            setEmployees([{ name: props.data.employee.name, employee_code: props.data.employee_code, division: { division_name: props.data.employee.division.division_name } }])
            setDefaultFormValue(props.data, pForm)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, pForm]);

    const submitPunishment = (v) => {
        let data = {
            employee_code: v.employee_code,
            date: moment(v.date).format('YYYY-MM-DD'),
            description: content,
            punishment_type: v.punishment_type,
            references: reference
        }
        props.onSubmit(data)
    }

    const columns = [
        {
            title: "No",
            dataIndex: "no",
            key: "no",
            width: "5%"
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Action",
            render: (row) => (
                reference.find((el) => el.emp_reward_punishment_id === row.emp_reward_punishment_id) === undefined ?
                    <Button type='primary' shape='circle' onClick={() => addReference(row)}>+</Button>
                    :
                    <Button type='primary' shape='circle' danger onClick={() => removeReference(row.emp_reward_punishment_id)}>-</Button>
            ),
            key: "action",
            align: "center",
        }
    ]

    const columnsReference = [
        {
            title: "No",
            dataIndex: "no",
            key: "no",
            width: "5%",
            align: "center"
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Action",
            render: (row) => (
                <DeleteButton onConfirm={() => removeReference(row.emp_reward_punishment_id)} />
            ),
            width: "5%",
            align: "center"
        }
    ]

    const addReference = (v) => {
        const newObj = []
        newObj.push({ emp_reward_punishment_id: v.emp_reward_punishment_id, description: v.description })
        const obj = reference.concat(newObj)
        setReference(obj)
    }

    const removeReference = (v) => {
        const obj = reference
        setReference(
            obj.filter(a =>
                a.emp_reward_punishment_id !== v
            )
        );
    }

    return (
        <Form form={pForm} size="middle" layout="vertical" onFinish={submitPunishment}>
            <Row>
                <Col span={24}>
                    <Form.Item name="employee_code" label="Employee" rules={[{ required: true }]}>
                        <Select
                            onChange={(v) => fetchPunishmentReference(v)}
                            showSearch
                            placeholder="Ketik untuk mencari"
                            defaultActiveFirstOption={false}
                            showArrow={true}
                            loading={searching}
                            filterOption={false}
                            onSearch={searchEmployee}
                            notFoundContent={<span>Employee Not Found</span>}>
                            {
                                employees.map((el, i) => (
                                    <Select.Option key={i} value={el.employee_code}>{el.name} | {el.division.division_name}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' onClick={showPunishmentList}>Show List Punishment</Button>
                    </Form.Item>
                    {
                        reference.length > 0 ?
                            <div className='mb-3'>
                                <DataTable size="small" columns={columnsReference} dataSource={reference} bordered pagination={false} search={false} />
                            </div>
                            : null
                    }
                    <Form.Item name="punishment_type" label="Punishment Type" rules={[{ required: true }]}>
                        <Select placeholder="Select Punishment Type">
                            {
                                punishmentTypes.map((el, key) => (
                                    <Select.Option key={key} value={el.detail_code}>{el.description}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="date" label="Punishment Date" rules={[{ required: true }]}>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item label="Information" rules={[{ required: true }]}>
                        <SunEditor setOptions={{
                            showPathLabel: false,
                            minHeight: "100vh",
                            maxHeight: "100vh",
                            placeholder: "Enter your text here!!!",
                            plugins: [
                                align,
                                font,
                                fontColor,
                                fontSize,
                                formatBlock,
                                hiliteColor,
                                horizontalRule,
                                lineHeight,
                                list,
                                paragraphStyle,
                                table,
                                template,
                                textStyle,
                                image,
                                link
                            ],
                            buttonList: [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock"],
                                ["paragraphStyle"],
                                [
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "subscript",
                                    "superscript"
                                ],
                                ["fontColor", "hiliteColor"],
                                ["removeFormat"],
                                "/", // Line break
                                ["outdent", "indent"],
                                ["align", "horizontalRule", "list", "lineHeight"],
                                ["table", "link", "image"]
                            ],
                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                            font: [
                                "Arial",
                                "Calibri",
                                "Comic Sans",
                                "Courier",
                                "Garamond",
                                "Georgia",
                                "Impact",
                                "Lucida Console",
                                "Palatino Linotype",
                                "Segoe UI",
                                "Tahoma",
                                "Times New Roman",
                                "Trebuchet MS"
                            ]
                        }} height={500} setContents={content} onChange={(value) => setContent(value)} />

                        {/* <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                            init={{
                                height: 300,
                                menubar: true,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor autosave',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                            }}
                            value={content}
                            onInit={(evt, editor) => {
                                setContent(editor.getContent({format: 'text'}))
                            }}
                            onEditorChange={(newValue, editor) => {
                                setContent(editor.getContent({format: 'text'}))
                            }}
                        /> */}
                    </Form.Item>
                </Col>
                <Col span={24} className="text-right">
                    <SubmitButton loading={props.saving} />
                </Col>
            </Row>

            {
                showModal ?
                    <BasicModal showModal={showModal} onCloseModal={() => setShowModal(false)} title={modalTitle} width={800}>
                        <DataTable size="small" columns={columns} dataSource={punishmentReference} bordered pagination={false} search={false} />
                    </BasicModal>
                    : null
            }
        </Form>
    );
}

export default RequestForm;
