import { Button, Form, Input, DatePicker, Select } from 'antd';
import { Uploader } from '../../../../components';

const { Option } = Select;

const FormatTicketGeneral = (props) => {
    const { saveSubmit, onChangeEmployee, employees, divisionId, disabled } = props
    return (
        <Form layout='vertical' onFinish={(v) => saveSubmit(v)}>
            <Form.Item label="Assign To" name="task_to" rules={[{ required: true }]}>
                <Select mode='multiple' showSearch allowClear placeholder="Select employee" optionFilterProp="children" style={{ width: "100%" }} onChange={onChangeEmployee} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {
                        divisionId === 0 ?
                            employees.map((el, key) => (
                                <Option key={key} value={el.employee_code}>{el.name}</Option>
                            ))
                            :
                            employees.filter((el) => el.division_id === divisionId).map((el, key) => (
                                <Option key={key} value={el.employee_code}>{el.name}</Option>
                            ))
                    }
                </Select>
            </Form.Item>
            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                <Input.TextArea rows={4} placeholder="Description" disabled={disabled} />
            </Form.Item>
            <Form.Item label="Deadline" name="deadline" rules={[{ required: true }]}>
                <DatePicker format="DD MMMM YYYY" style={{ width: '100%' }} disabled={disabled} />
            </Form.Item>
            <Form.Item label="Upload Attachment">
                <Uploader folder="attachment-daily-activity" onUploaded={(v) => props.changeFileUploaded(v)} isUploading={(v) => props.changeSetUploading(v)} view={true} />
            </Form.Item>
            <div style={{ float: 'right' }}>
                {
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>Save</Button>
                    </Form.Item>
                }
            </div>
        </Form>
    )
}

export default FormatTicketGeneral