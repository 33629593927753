import React, {useEffect, useState} from 'react';
import {Card, Button, Col, Row, DatePicker, Tabs, Select, /*Space*/} from 'antd';
import {FileExcelOutlined, BarChartOutlined, CalendarOutlined} from '@ant-design/icons'
import { PageTitle, DataTable, Spinner, CustomButton, BasicModal} from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import {toIdr} from '../../../utils/currencyHandler';
import moment from 'moment';
import {Bar} from 'react-chartjs-2'

const {TabPane} = Tabs;
const {Option} = Select;

const Budget = () => {
    const [loaded, setLoaded] = useState(true)
    const [modalLoaded, setModalLoaded] = useState(true)
    const [chartLoaded, setChartLoaded] = useState(true)
    const [detailLoaded, setDetailLoaded] = useState(true)
    const [year, setYear] = useState(moment())
    const [chartYear, setChartYear] = useState(moment())
    const [detailYear, setDetailYear] = useState(moment())
    const [budgets, setBudgets] = useState([])
    const [coaBudgets, setCoaBudgets] = useState([])
    const [monthBudgets, setMonthBudgets] = useState([])
    const [detailBudgets, setDetailBudgets] = useState([])
    const [showModalCoa, setShowModalCoa] = useState(false)
    const [showModalMonth, setShowModalMonth] = useState(false)
    const [divisions, setDivisions] = useState([])
    const [selectedDivision, setSelectedDivision] = useState('all')
    const [activeTab, setActiveTab] = useState(1);
    const [totalBudget, setTotalBudget] = useState([])
    
    const fetchBudget = (year) => {
        setLoaded(false)
        SentralModel.action('Report', 'budget', {year: year}, 0).then(res => {
            setBudgets(res.data)
            setLoaded(true)
        })
    }

    const fetchBudgetDivisionCoa = (division_id, year) => {
        setModalLoaded(false)
        let payload = {
            division_id: division_id,
            year: year
        }
        SentralModel.action('Budget', 'getBudgetDivisionCoa', payload, 0).then(res => {
            setCoaBudgets(res.data)
            setModalLoaded(true)
        })
    }

    const fetchBudgetDivisionMonth = (division_id, year) => {
        setModalLoaded(false)
        let payload = {
            division_id: division_id,
            year: year
        }
        SentralModel.action('Budget', 'getBudgetDivisionMonth', payload, 0).then(res => {
            setMonthBudgets(res.data)
            setModalLoaded(true)
        })
    }

    const fetchDetailBudget = (division_id, year) => {
        setDetailLoaded(false)
        let payload = {
            division_id: division_id,
            year: year
        }
        SentralModel.action('Budget', 'getDetailBudget', payload, 0).then(res => {
            setDetailBudgets(res.data)
            setDetailLoaded(true)
        })
    }

    const fetchTotalBudget = (year) => {
        setChartLoaded(false)
        let payload = {
            year: year
        }
        SentralModel.action('Budget', 'getBudgetTotalMonth', payload, 0).then(res => {
            setTotalBudget(res.data.map((el) => el.total_amount))
            setChartLoaded(true)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', {orderBy:'division_name', order:'ASC'}, 0).then((res)=>{
            setDivisions(res.data);
        })
    }

    useEffect(() => {
        fetchBudget(year)
        fetchDetailBudget(selectedDivision, detailYear)
        fetchTotalBudget(chartYear)
        fetchDivision()
    }, [selectedDivision, year, chartYear, detailYear]);

    const dataCoaSource = [
        ...coaBudgets.map( el => {
            return {
                coa_code: el.coa_code,
                coa_name: el.coa === null ? '-' : el.coa.coa_name,
                description: el.description,
                amount: el.amount,
                used: el.used,
            }
        })
    ];

    const dataCoaColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            className: 'text-top',
            width: '1%'
        },
        {
            title: 'COA',
            dataIndex: 'coa_name',
            key: 'coa_name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text) => (
                <div className="text-left">
                    {text === '0.00' ? '' : toIdr(text)}
                </div>
            )
        },
        {
            title: 'Used',
            dataIndex: 'used',
            key: 'used',
            render: (text) => (
                <div className="text-left">
                    {text === '0.00' ? '' : toIdr(text)}
                </div>
            )
        }
    ];

    const labels = moment.monthsShort();
    const data = {
        labels: labels,
        datasets: [{
            label: 'BUDGET',
            data: totalBudget,
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 99, 132, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                'rgba(255, 205, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(153, 102, 255, 0.2)'
            ],
            borderColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)'
            ],
            borderWidth: 1
        }]
    };

    const usageCoa = (v) => {
        fetchBudgetDivisionCoa(v, year)
        setShowModalCoa(true)
    }

    const usageYear = (v) => {
        fetchBudgetDivisionMonth(v, year)
        setShowModalMonth(true)
    }

    const onChangeYear = (v) => {
        setYear(v)
    }

    const onChangeChartYear = (v) => {
        setChartYear(v)
    }

    const onChangeDetailYear = (v) => {
        setDetailYear(v)
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL +`/report/budget?year=${year.format('YYYY')}`)
    }

    const exportReportDetail = () => {
        window.open(process.env.REACT_APP_API_URL +`/report/detailBudget?year=${year.format('YYYY')}&division_id=${selectedDivision}`)
    }

    const changeTab = e => {
        setActiveTab(e)
    }

    const changeDivision = e => {
        setSelectedDivision(e)
        fetchDetailBudget(e, year)
    }
    
    return (
        <div>
            <Card style={{borderRadius: '15px'}}>
                <PageTitle title="Budget Report" breadcrumbs={[['Budget Report']]}></PageTitle>

                <Row justify="start" className="mb-3">
                    <Col span={24} >
                        <span style={{float:'right'}}>
                            <DatePicker picker="year" placeholder="Select Year" defaultValue={year} style={{ marginRight: 10 }} onChange={onChangeYear}/>
                            <Button type="primary" onClick={() => exportReport()}  color="#ededed" icon={<FileExcelOutlined />}>
                                Export
                            </Button>
                        </span>
                    </Col>
                </Row>
                {
                    (loaded) ?
                        <table className="table-collapse table-default" style={{width:'100%'}}>
                            <thead>
                                <tr>
                                    <th style={{width:'5%'}}>No</th>
                                    <th>Division</th>
                                    <th style={{width:'10%'}}>Percentage</th>
                                    <th>Total Amount</th>
                                    <th>Total Used</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    budgets.map((row, index) => (
                                        <tr key={index} style={{verticalAlign: "top"}}>
                                            <td className="text-center" width="5%"> {index + 1} </td>
                                            <td> {row.division_name} </td>
                                            <td className="text-right"> {(row.total_amount / budgets.reduce((totalAmount, budget) => totalAmount + budget.total_amount, 0) * 100).toFixed(2)} % </td>
                                            <td> {row.total_amount === '0' ? '' : toIdr(row.total_amount)} </td>
                                            <td> {row.total_used === '0' ? '' : toIdr(row.total_used)} </td>
                                            <td className="text-center" width="10%">
                                                <CustomButton icon={<BarChartOutlined />} onEdit={() => usageCoa(row.division_id)}/>
                                                <CustomButton icon={<CalendarOutlined />} onEdit={() => usageYear(row.division_id)}/>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th id="total" colSpan={3}>Total</th>
                                    <th style={{ textAlign: 'left' }}>{toIdr(budgets.reduce((totalAmount, budget) => totalAmount + budget.total_amount, 0))}</th>
                                    <th style={{ textAlign: 'left' }}>{toIdr(budgets.reduce((totalUsed, budget) => totalUsed + budget.total_used, 0))}</th>
                                </tr>
                            </tfoot>
                        </table>
                    :<Spinner/>
                }
            </Card>

            {/* Chart budget */}
            <Card style={{borderRadius: '15px', marginTop: '20px'}}>
                <PageTitle title="Budget / Month"></PageTitle>
                <Row justify="start" className="mb-3">
                    <Col span={24} >
                        <span style={{float:'right'}}>
                            <DatePicker picker="year" placeholder="Select Year" defaultValue={chartYear} style={{ marginRight: 10 }} onChange={onChangeChartYear}/>
                        </span>
                    </Col>
                </Row>
                {
                    chartLoaded ?
                        <Card>
                            <Bar data={data} width='100%' height={320} options={{ maintainAspectRatio: false }}/>
                        </Card>
                    : <Spinner />
                }
            </Card>

            {/* Detail budget */}
            <Card style={{borderRadius: '15px', marginTop: '20px'}}>
                <PageTitle title="Detail Budget"></PageTitle>

                <Row justify="start" className="mb-3">
                    <Col span={24} >
                        <span style={{float:'right'}}>
                            <DatePicker picker="year" placeholder="Select Year" defaultValue={detailYear} style={{ marginRight: 10 }} onChange={onChangeDetailYear}/>
                            <Button type="primary" onClick={() => exportReportDetail()}  color="#ededed" icon={<FileExcelOutlined />}>
                                Export
                            </Button>
                        </span>
                        <span style={{float:'right', marginRight: '10px', width: '25%'}}>
                            <Select showSearch defaultValue='all' allowClear placeholder="Select division" style={{ width: "100%" }} onChange={changeDivision} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {
                                    <>
                                    <Option key='all' value='all'>All Division</Option>
                                    {
                                        divisions.map((el, key) => (
                                            <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                        ))
                                    }
                                    </>
                                }
                            </Select>
                        </span>
                    </Col>
                </Row>
                {
                    (detailLoaded) ?
                        <Tabs defaultActiveKey={activeTab} type="card" onChange={changeTab}>
                            {
                                detailBudgets.map(det => (
                                    <TabPane tab={<span>{det.name}</span>} key={det.month}>
                                        {
                                            <table className="table-collapse table-default" style={{width:'100%'}}>
                                                <thead>
                                                    <tr>
                                                        <th style={{width:'5%'}}>No</th>
                                                        <th>Description</th>
                                                        <th>Division</th>
                                                        <th>Author</th>
                                                        <th>Amount</th>
                                                        <th>Used</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        det.budgets.map((row, index) => (
                                                            <tr key={index} style={{verticalAlign: "top"}}>
                                                                <td className="text-center" width="5%"> {index + 1} </td>
                                                                <td> {row.description} </td>
                                                                <td> {row.division?.division_name ?? ''} </td>
                                                                <td> {row.employee?.name ?? ''} </td>
                                                                <td> {row.amount === '0' ? '' : toIdr(row.amount)} </td>
                                                                <td> {row.used === '0' ? '' : toIdr(row.used)} </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th id="total" colSpan={4}>Total</th>
                                                        <th style={{ textAlign: 'left' }}>{toIdr(det.budgets.reduce((totalAmount, bg) => totalAmount + bg.amount, 0))}</th>
                                                        <th style={{ textAlign: 'left' }}>{toIdr(det.budgets.reduce((totalUsed, bg) => totalUsed + bg.used, 0))}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        }
                                    </TabPane>
                                ))
                            }
                        </Tabs>
                    :<Spinner/>
                }
            </Card>

            {/* Modal budget / coa */}
            <BasicModal title="Usage / COA" width={1200} showModal={showModalCoa} onCloseModal={() => setShowModalCoa(false)}>
                {
                    modalLoaded ?
                        <DataTable pagination={false} size="small" columns={dataCoaColumn} dataSource={dataCoaSource} bordered/>
                    : <Spinner/>
                }
            </BasicModal>

            {/* Modal budget / month */}
            <BasicModal title="Usage / Month" width={1200} showModal={showModalMonth} onCloseModal={() => setShowModalMonth(false)}>
                {
                    modalLoaded ?
                        <table className="table-collapse table-default" style={{width:'100%'}}>
                            <thead>
                                <tr>
                                    <th style={{width:'5%'}}>No</th>
                                    <th>Description</th>
                                    <th style={{width:'10%'}}>Percentage</th>
                                    <th>Total Amount</th>
                                    <th>Total Used</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    monthBudgets.map((row, index) => (
                                        <tr key={index} style={{verticalAlign: "top"}}>
                                            <td className="text-center" width="5%"> {index + 1} </td>
                                            <td> {row.month} </td>
                                            <td className="text-right"> {(row.total_amount / monthBudgets.reduce((totalAmount, budget) => totalAmount + budget.total_amount, 0) * 100).toFixed(2)} % </td>
                                            <td> {row.total_amount === '0' ? '' : toIdr(row.total_amount)} </td>
                                            <td> {row.total_used === '0' ? '' : toIdr(row.total_used)} </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    : <Spinner/>
                }
            </BasicModal>
        </div>
    );
}

export default Budget;
