import { AuthModel } from '../models/AuthModel'
import {message} from 'antd'
import {decrypt} from '../helpers/crypto'

export function authHeader() {
    // return authorization header with jwt token
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
        return { 
            'Authorization': `Bearer ${decrypt(userToken)}`,
            'Content-Type': `application/json`
        };
    } else {
        return {};
    }
}

export function uploadHeader() {
    // return authorization header with jwt token
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
        return { 
            'Authorization': `Bearer ${decrypt(userToken)}`,
            // 'Content-Type': 'multipart/form-data',
            // 'Content-Disposition': 'form-data'
        };
    } else {
        return {};
    }
}

export function handleResponse(response) {
    return response.json().then(res => {
        if(response.ok && res.status < 300){
            return res
        }else{
            if (res.status === 401 || res.status === 403) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                // message.loading('invalid credential, please login',2)
                // setTimeout(() => {
                    AuthModel.logout();
                    window.location.reload(true);
                // }, 2);
            }else{
                const error = (res && res.message) || response.statusText;
                message.error(error,2)
                return Promise.reject();
            }
        }
    });
}



