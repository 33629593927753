import React, {useEffect, useState} from 'react'
import {Row, Typography, Divider, Empty, Form, Input, message, DatePicker} from 'antd'
import ProfileItem from './ProfileItem';
import ActionButton from './ActionButton';
import {AddButton, FormModal, Spinner} from '../../components';
import {SentralModel} from '../../models/SentralModel'
import moment from 'moment'
import { toFullDate } from '../../utils/dateHandler';

const {Title} = Typography
const Probation = (props) => {
    const [empProbations, setEmpProbations] = useState(props.data);
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle]= useState(null);

    const fetchEmpProbations = (empCode) =>{
        setLoaded(false)
        SentralModel.list('EmployeeProbation', {filter: [['employee_code', empCode]],order:'asc',orderBy:'probation_start'}).then(res=>{
            setEmpProbations(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchEmpProbations(props.emp_code)
    }, [props]);

    const addProbation=()=>{
        setModalTitle('Add Probation')
        setDefaultValues({
            employee_probation_id: 0
        })
        setFormModal(true)
    }

    const editProbation = (v) =>{
        setModalTitle('Edit Probation')
        setDefaultValues({
            employee_probation_id: v.employee_probation_id,
            probation_start: moment(v.probation_start, 'YYYY-MM-DD'),
            probation_end: moment(v.probation_end, 'YYYY-MM-DD'),
        })
        setFormModal(true)
    }

    const saveProbation = (v) => {
        setSaving(true)
        let payload={
            employee_code: props.emp_code,
            probation_start: moment(v.probation_start).format('YYYY-MM-DD'),
            probation_end: moment(v.probation_end).format('YYYY-MM-DD'),
        }
        SentralModel.save('EmployeeProbation', payload, v.employee_probation_id).then(()=>{
            setFormModal(false)
            fetchEmpProbations(props.emp_code)
            message.success('Probation saved', 2);
            setSaving(false)
        })
    }

    const deleteProbation = (id) =>{
        message.loading('deleting probation')
        SentralModel.deleteById('EmployeeProbation', id).then((res)=>{
            message.destroy()
            message.success('Probation deleted', 2)
            let tmp = empProbations
            let index = tmp.findIndex(el => el.employee_probation_id===id)
            tmp.splice(index, 1);
            setEmpProbations([])
            setEmpProbations(tmp)
        })
    }
    return (
        <div>
            {
                (props.isHr) ? 
                    <div className="text-right">
                        <AddButton onAdd={addProbation}>Add data</AddButton>
                    </div>
                : null
            }
            {
                loaded ?
                    (empProbations.length > 0) ?
                        empProbations.map((el, key) => (
                            <div key={key}>
                                <Divider orientation="left"><Title level={4}>Kontrak {key+1}.</Title></Divider>
                                {
                                    (props.isHr) ? 
                                    <ActionButton editable deleteable onEdit={() => editProbation(el)} onDelete={() => deleteProbation(el.employee_probation_id)}/>
                                    : null
                                }
                                <Row>
                                    <ProfileItem title="Probation Start">{toFullDate(el.probation_start)}</ProfileItem>
                                    <ProfileItem title="Probation End">{toFullDate(el.probation_end)}</ProfileItem>
                                </Row>
                            </div>
                        ))
                    : <Empty />
                : <Spinner />
            }
            {
                (formModal) ?
                <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveProbation(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                    <Form.Item name="employee_probation_id" hidden>
                        <Input placeholder="input placeholder" />
                    </Form.Item>
                    <Form.Item label="Probation Start" name="probation_start" rules={[{required:true}]}>
                        <DatePicker className="full-width" placeholder="Probation start" />
                    </Form.Item>
                    <Form.Item label="Probation End" name="probation_end" rules={[{required:true}]}>
                        <DatePicker className="full-width" placeholder="Probation end" />
                    </Form.Item>
                </FormModal> :
                null
            }
        </div>
    );
}

export default Probation;
