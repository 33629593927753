import { useState, useEffect } from 'react';
import { SentralModel } from '../../../models/SentralModel';
import { Row, Col, Card, Divider, DatePicker, Select } from 'antd';
import authData from '../../../helpers/authData';
import GaugeChart from 'react-gauge-chart';
import moment from 'moment';
import { loadedState, scoreCardState } from '../../../recoil-state/scoreCard.state';
import { useRecoilState } from 'recoil';
import { Spinner } from '../../../components';

const { Option } = Select

const ScoreCard = () => {
    const [loaded, setLoaded] = useRecoilState(loadedState)
    const [percentage, setPercentage] = useRecoilState(scoreCardState)
    const [periods, setPeriods] = useState([])

    const fetchScoreCard = (v) => {
        setLoaded(false)
        let payload = {
            division_id: authData.division_id,
            payroll_period_id: v
        }

        SentralModel.action("Task", "getScoreCard", payload, null).then((res) => {
            setPercentage(res.data)
            setLoaded(true)
        })
    }

    const fetchPeriods = (year) => {
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, { orderBy: 'from_date', order: 'desc' }).then(res => {
            setPeriods(res.data)
        })
    }

    useEffect(() => {
        // fetchScoreCard(null)

    }, [])

    const onChangeYear = (v) => {
        let year = moment(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    const changePeriod = (v) => {
        fetchScoreCard(v)
    }

    const disabledDate = (current) => {
        let currentMonth = moment().format('MM');
        let addYear = 1;

        if (currentMonth === '12') {
            addYear = 2;
        }

        let customDate = moment(new Date()).add(addYear, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");
    };

    return (
        <div>
            <Row hidden justify="end" className="mb-4">
                <Col span={24} >
                    <span style={{ float: 'right' }}>
                        <Select placeholder="Select Period" style={{ width: 200, marginRight: 10 }} onChange={changePeriod}>
                            {
                                periods.map((el, key) => (
                                    <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                ))
                            }
                        </Select>
                    </span>
                    <span style={{ float: 'right', marginRight: '20px' }}>
                        <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year" />
                    </span>
                </Col>
            </Row>
            <Row justify='space-between'>
                <Col span={7}>
                    <Card title="Daily Attendance">
                        {
                            loaded ?
                                <div className='text-center'>
                                    <GaugeChart
                                        id="gauge-chart3"
                                        nrOfLevels={4}
                                        percent={(percentage.percentage_attendance) / 100}
                                        colors={["red", "orange", "#F9D949", "green"]}
                                        arcWidth={0.3}
                                        hideText={true}
                                    />
                                    <p style={{ fontSize: 24, fontWeight: "bold" }}>
                                        {percentage.percentage_attendance !== 0 ? ((percentage?.percentage_attendance) * 100).toFixed(2) : 0}%
                                    </p>
                                </div>
                                : <Spinner />
                        }
                        <Divider orientation='center' />
                        <Row justify='center'>
                            <div className="list">
                                <table className="table-collapse table-default" >
                                    <tbody>
                                        <tr>
                                            <th style={{ paddingRight: 15 }}>Total Attendance</th>
                                            <td>{percentage?.total_attendance}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ paddingRight: 15 }}>On Time</th>
                                            <td>{percentage?.total_attendance - (percentage?.attendance_alpha + percentage?.attendance_late)}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ paddingRight: 15 }}>Alpha</th>
                                            <td>{percentage?.attendance_alpha}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ paddingRight: 15 }}>Late</th>
                                            <td>{percentage?.attendance_late}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Row>
                    </Card>
                </Col>
                <Col span={7}>
                    <Card title="Daily Activity">
                        {
                            loaded ?
                                <div className='text-center'>
                                    <GaugeChart
                                        id="gauge-chart3"
                                        nrOfLevels={4}
                                        percent={(percentage?.percentage_daily_activity) / 100}
                                        colors={["red", "orange", "#F9D949", "green"]}
                                        arcWidth={0.3}
                                        hideText={true}
                                    />
                                    <p style={{ fontSize: 24, fontWeight: "bold" }}>
                                        {percentage?.percentage_daily_activity !== 0 ? ((percentage?.percentage_daily_activity) * 100).toFixed(2) : 0}%
                                    </p>
                                </div>
                                : <Spinner />
                        }
                        <Divider orientation='center' />
                        <Row justify='center'>
                            <div className="list">
                                <table className="table-collapse table-default" >
                                    <tbody>
                                        <tr>
                                            <th style={{ paddingRight: 15 }}>Total Daily Activity</th>
                                            <td>{percentage?.total_daily_activity ?? 0}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ paddingRight: 15 }}>{`Finished Daily > 5`}</th>
                                            <td>{percentage?.daily_finished_morethan5 ?? 0}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ paddingRight: 15 }}>{`Finished Daily < 5`}</th>
                                            <td>{percentage?.daily_finished_lessthan5 ?? 0}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Row>
                    </Card>
                </Col>
                <Col span={7}>
                    <Card title="Daily Task">
                        {
                            loaded ?
                                <div className='text-center'>
                                    <GaugeChart
                                        id="gauge-chart3"
                                        nrOfLevels={4}
                                        percent={(percentage?.percentage_finished_task) / 100}
                                        colors={["red", "orange", "#F9D949", "green"]}
                                        arcWidth={0.3}
                                        hideText={true}
                                    />
                                    <p style={{ fontSize: 24, fontWeight: "bold" }}>
                                        {percentage?.percentage_finished_task !== 0 ? ((percentage?.percentage_finished_task) * 100).toFixed(2) : 0}%
                                    </p>
                                </div> : <Spinner />
                        }
                        <Divider orientation='center' />
                        <Row justify='center'>
                            <div className="list">
                                <table className="table-collapse table-default" >
                                    <tbody>
                                        <tr>
                                            <th style={{ paddingRight: 15 }}>Total Finish Task</th>
                                            <td>{percentage?.finished_task}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ paddingRight: 15 }}>Total In Progress Task</th>
                                            <td>{percentage?.inprogress_task}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ paddingRight: 15 }}>Overdue Task</th>
                                            <td>{percentage?.overdue_task}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ paddingRight: 15 }}>On Time Task</th>
                                            <td>{percentage?.ontime_task}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ScoreCard