import React, {useState, useEffect} from 'react';
import {Calendar, Tag, Typography} from 'antd'
import {BasicModal} from '../../components'
import { SentralModel } from '../../models/SentralModel';
import moment from 'moment'
const {Title, Text} = Typography

const MeetingSchedule = () => {
    const [showModal, setShowModal] = useState(false)
    const [events, setEvents] = useState([])
    const [value, setValue] = useState(moment())
    const [selectedDateEvents,setSelectedDateEvents] = useState([])

    const fetchImportantDate = () =>{
        SentralModel.action('ImportantDate','getImportantDate',{},0).then((res) => {
            setEvents(res.data);
        })
    }

    useEffect(() => {
        fetchImportantDate()
    }, []);

    const getEvent =(date) => {
        let x=[];
        events.forEach((el, key)=>{
            if(el.repeat_yearly){
                if(el.date.substring(5,10) === date.substring(5,10)){
                    let color="red";
                    if(el.category==='OFFICE EVENT'){
                        color="green"
                    }
                    if(el.category==='OTHER'){
                        color="cyan"
                    }
                    x.push(<Tag key={'event_'+Math.random()} onClick={() => showDateDetail(date)} style={{cursor:'pointer'}} color={color}>{el.description}</Tag>)
                }
            }
            if(!el.repeat_yearly && el.date === date){
                let color="red";
                if(el.category==='OFFICE EVENT'){
                    color="green"
                }
                if(el.category==='OTHER'){
                    color="cyan"
                }
                x.push(<Tag key={'event_'+Math.random()} onClick={() => showDateDetail(date)} style={{cursor:'pointer'}} color={color}>{el.description}</Tag>)
            }
        })
        return x
    }
      
    function dateCellRender(value) {
        return (
            getEvent(moment(value).format('YYYY-MM-DD'))
        )
    }

    const showDateDetail = (v) =>{
        setValue(moment(v))
        let x=[]
        events.forEach((el)=>{
            if(el.repeat_yearly){
                if(el.date.substring(5,10) === v.substring(5,10)){
                    x.push(el)
                }
            }
            if(!el.repeat_yearly && el.date === moment(v).format('YYYY-MM-DD')){
                x.push(el)
            }
        })
        setSelectedDateEvents(x)
        setShowModal(true)
    }

    const changePanel = (v) =>{
        setValue(v)
    }
    
    return (
        <div style={{border:'1px solid #ededed', padding:20, borderRadius:10}}>
            <Title level={3}>Office Calendar</Title>
            <Calendar value={value} dateCellRender={dateCellRender} onPanelChange={changePanel}  />
            <BasicModal title={<Title style={{marginBottom:0}} level={4}>{moment(value).format('LL')}</Title>} width={500} showModal={showModal} onCloseModal={() => setShowModal(false)}>
                {
                    selectedDateEvents.map((el, key) =>(
                        <div key={key}>
                            <Text strong>{el.description}</Text><br/>
                            <span className="text-muted">{el.category}</span>
                        </div>
                    ))
                }
                {
                    (selectedDateEvents.length < 1) ?
                        <div className="text-center text-muted">no event</div> 
                    : null
                }
            </BasicModal>
        </div>
    );
}

export default MeetingSchedule;
