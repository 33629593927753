import React, { useEffect, useState } from 'react'
import { Button, Card, Row, Typography, Col, Select, DatePicker, Tag, Space, message } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { SentralModel } from '../../../models/SentralModel'
import DashboardItem from '../DashboardItem'
import { BasicModal, DataTable, Spinner } from '../../../components'
import { BsFingerprint } from 'react-icons/bs'
import moment from 'moment'

const { Title } = Typography

const DashboardWorkingLocation = () => {
    const history = useHistory()
    const [periodId, setPeriodId] = useState(0)
    const [selectedDivision, setSelectedDivsion] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [tenOfAlpha, setTenOfAlpha] = useState([])
    const [division, setDivision] = useState([])
    const [alphaData, setAlphaData] = useState([])
    const [leaveData, setLeaveData] = useState([])
    const [halfdayData, setHalfdayData] = useState([])
    const [fulldayData, setFulldayData] = useState([])
    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState("")

    const counterStyle = {
        fontSize: '40px',
        color: '#000',
        fontWeight: 'bold'
    }

    const fetchWorkingLocationData = (v) => {
        setLoaded(false)
        SentralModel.dashboardData('WorkingLocation', 'dashboardWorkingLocation', v, null)
            .then((res) => {
                const v = res.data.data_dashboard
                const alphaList = res.data.data_alpha?.sort((a, b) => b.alpha - a.alpha)

                setPeriodId(res.data?.period?.payroll_period_id)
                setTenOfAlpha(alphaList.slice(0, 10))

                let arrAlpha = []
                let arrLeave = []
                let arrHalfday = []
                let arrFullday = []

                v?.forEach((el) => {
                    if (el.is_alpha === true) {
                        arrAlpha.push(el)
                    }
                    if (el.is_leave === true) {
                        arrLeave.push(el)
                    }
                    if (el.is_halfday === true) {
                        arrHalfday.push(el)
                    }
                    if (el.is_fullday === true) {
                        arrFullday.push(el)
                    }
                })

                setAlphaData(arrAlpha)
                setLeaveData(arrLeave)
                setHalfdayData(arrHalfday)
                setFulldayData(arrFullday)
                setLoaded(true)
            })
    }

    const sync = () => {
        setLoaded(false)
        SentralModel.dashboardData("WorkingLocation", "scynData", { date: selectedDate }, null).then(() => {
            fetchWorkingLocationData({ division_id: selectedDivision, date: selectedDate })
            message.success("Sync Success")
        }).catch((err) => {
            message.warning("Sync Failed")
        })
    }

    const fetchDivision = () => {
        SentralModel.dashboardData('Division', 'getDivision', null, null).then((res) => {
            const v = res.data
            let arr = []

            arr.push({ value: 99, label: "All IT Bizdev" })
            v.forEach((el) => {
                arr.push({ value: el.division_id, label: el.division_name })
            })

            setDivision(arr)
        })
    }

    const onChangeDivision = (v) => {
        setSelectedDivsion(v)
        fetchWorkingLocationData({ division_id: v, date: selectedDate })
    }

    const onChangeDate = (v) => {
        setSelectedDate(moment(v).format("YYYY-MM-DD"))
        fetchWorkingLocationData({ division_id: selectedDivision, date: v })
    }

    useEffect(() => {
        fetchDivision()
        fetchWorkingLocationData({ division_id: selectedDivision, date: selectedDate })

    }, [selectedDivision, selectedDate])

    const onClickCard = (v) => {
        setShowModal(true)
        setModalTitle(v.title)
        setData(v.data)
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            width: '5%',
            align: 'center'
        },
        {
            title: 'Employee Name',
            render: (row) => (
                row?.employee?.name
            ),
            key: 'employee_name'
        },
        {
            title: 'Working Location',
            render: (row) => (
                <table className='table-sub'>
                    <thead>
                        <tr>
                            <th style={{ width: "300px" }}>Company</th>
                            <th>Date</th>
                            <th style={{ textAlign: "center" }}>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            row?.working_location.map((el, key) => (
                                <tr key={key}>
                                    <td style={{ width: "300px" }}>
                                        {
                                            el?.description === "Alpha" || el?.description === "Leave" ?
                                                <Tag color="red">
                                                    {el?.description}
                                                </Tag>
                                                :
                                                el?.absence_description === "Machine" ?
                                                    <Space>
                                                        {el?.company?.company_name}
                                                        <BsFingerprint color='#1987e8' />
                                                    </Space>
                                                    :
                                                    el?.company?.company_name
                                        }
                                    </td>
                                    <td>{moment(el?.date).format("DD/MM/YYYY")}</td>
                                    <td style={{ textAlign: "center" }}>{el?.time_start} - {el?.time_end}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            ),
            key: 'working_location'
        }
    ]

    const columnAlpha = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            width: '5%',
            align: 'center'
        },
        {
            title: 'Employee Name',
            render: (row) => (
                row?.employee?.name
            ),
            key: 'employee_name'
        },
        {
            title: 'Description',
            render: (row) => (
                <Tag color='red'>
                    Alpha
                </Tag>
            ),
            align: "center"
        },
        {
            title: 'Total Alpha',
            dataIndex: 'alpha',
            key: 'alpha',
            align: 'center',
            width: '10%'
        }
    ]

    const exportReport = () => {
        window.open(`${process.env.REACT_APP_API_URL}/report/dashboardWorkingLocation?period_id=${periodId}&division_id=${selectedDivision}`)
    }

    return (
        <Card style={{ margin: '15px 10px 0 10px' }} className="content-container">
            <div style={{ marginBottom: 15 }}>
                <div>
                    <Row justify='space-between'>
                        <Col span={8}>
                            <Card>
                                <div style={{ marginBottom: 30 }}>
                                    <Title level={4}>DASHBOARD WORKING LOCATION</Title>
                                </div>
                                <div style={{ marginBottom: 10 }}>
                                    <Row justify='space-between'>
                                        <Col span={11}>
                                            <Select
                                                placeholder="Select Division"
                                                options={division}
                                                style={{ width: "100%" }}
                                                onChange={(v) => onChangeDivision(v)}
                                            />
                                        </Col>
                                        <Col span={11}>
                                            <DatePicker onChange={(v) => onChangeDate(v)} style={{ width: "100%" }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row justify='start'>
                                        <Col span={11}>
                                            <Button onClick={exportReport} disabled={periodId === 0 ? true : false} style={{ width: "100%" }} type='primary'><FileExcelOutlined /> Export Data</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Row style={{ marginBottom: 10 }}>
                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/action-plan-home')}>ACTION PLAN</Button>
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                    <Button disabled style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => null}>DAILY ACTIVITY</Button>
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/working-location-dashboard')}>WORKING LOCATION</Button>
                                </Row>
                                <Row>
                                    <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/digitalisasi-home')}>DIGITALISASI DOKUMEN</Button>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <Card title={
                <Row justify='end'>
                    <Button onClick={sync} type='primary'>Sync Data</Button>
                </Row>
            } style={{ marginBottom: 20 }}>
                {
                    loaded ?
                        <Row justify='space-between'>
                            <Col span={5}>
                                <DashboardItem onClick={() => onClickCard({ title: "Data Alpha", data: alphaData })} title="Data Alpha" height={50}>
                                    <span style={counterStyle}>{alphaData?.length ?? 0}</span>
                                    <span style={{ fontSize: '15px' }}> Karyawan</span>
                                </DashboardItem>
                            </Col>
                            <Col span={5}>
                                <DashboardItem onClick={() => onClickCard({ title: "Data Leave", data: leaveData })} title="Data Leave" height={50}>
                                    <span style={counterStyle}>{leaveData?.length ?? 0}</span>
                                    <span style={{ fontSize: '15px' }}> Karyawan</span>
                                </DashboardItem>
                            </Col>
                            <Col span={5}>
                                <DashboardItem onClick={() => onClickCard({ title: "Data Halfday", data: halfdayData })} title="Data Halfday" height={50}>
                                    <span style={counterStyle}>{halfdayData?.length ?? 0}</span>
                                    <span style={{ fontSize: '15px' }}> Karyawan</span>
                                </DashboardItem>
                            </Col>
                            <Col span={5}>
                                <DashboardItem onClick={() => onClickCard({ title: "Data Fullday", data: fulldayData })} title="Data Fullday" height={50}>
                                    <span style={counterStyle}>{fulldayData?.length ?? 0}</span>
                                    <span style={{ fontSize: '15px' }}> Karyawan</span>
                                </DashboardItem>
                            </Col>
                        </Row>
                        : <Spinner />
                }
            </Card>

            <Card title="Top 10 Alpha">
                {
                    loaded ?
                        <DataTable dataSource={tenOfAlpha} columns={columnAlpha} bordered size="small" search={false} pagination={false} />
                        :
                        <Spinner />
                }
            </Card>

            {
                showModal ?
                    <BasicModal width={800} title={modalTitle} showModal={showModal} onCloseModal={() => setShowModal(false)}>
                        <DataTable dataSource={data} columns={columns} bordered size="small" />
                    </BasicModal>
                    : null
            }
        </Card >
    )
}

export default DashboardWorkingLocation