import React, { useState, useEffect } from 'react';
import { Card, Select, DatePicker, Form, Button, Drawer, Space, Tag } from 'antd';
import { FilterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components';
import { toFullDate } from '../../../utils/dateHandler'
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment'

const { RangePicker } = DatePicker
const { Option } = Select

const Leave = () => {
    const [loaded, setLoaded] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [leaveData, setLeaveData] = useState([])
    const [divisions, setDivisions] = useState([])
    const [leaveTypes, setLeaveTypes] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedDateRange, setSelectedDateRange] = useState([])
    const [selectedType, setSelectedCategory] = useState([])

    const fetchReport = (ft) => {
        setLoaded(false)
        SentralModel.action('Report', 'leave', ft, 0).then((res) => {
            setLeaveData(res.data)
            setLoaded(true)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', { orderBy: 'division_name', order: 'ASC' }, 0).then((res) => {
            setDivisions(res.data);
        })
    }

    const fetchLeaveType = () => {
        SentralModel.list('Leave', null, 0).then((res) => {
            setLeaveTypes(res.data);
        })
    }

    useEffect(() => {
        fetchDivision()
        fetchLeaveType()
    }, []);

    const filterData = (v) => {
        let ft = {
            division_id: v.division_id,
            leave_id: v.leave_type,
            date_range: (v.date_range) ? [moment(v.date_range[0]).format('YYYY-MM-DD'), moment(v.date_range[1]).format('YYYY-MM-DD')] : []
        }
        fetchReport(ft)
        setShowFilter(false)
    }

    const leaveColumn = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Employee Name',
        dataIndex: 'name',
        key: 'name',
    }, {
        title: 'Division',
        dataIndex: 'division_name',
        key: 'division_name',
    }, {
        title: 'Leave Type',
        dataIndex: 'leave_name',
        key: 'leave_name',
    }, {
        title: 'Necessity',
        dataIndex: 'reason',
        key: 'reason'
    }, {
        title: 'Date',
        render: (row) => (
            (row.total_days > 1) ? toFullDate(row.from_date) + '-' + toFullDate(row.to_date) : toFullDate(row.from_date)
        )
    }, {
        title: 'Todal Days',
        align: 'center',
        dataIndex: 'total_days',
        key: 'total_days'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 50,
        render: (text) => (
            <div className="text-center">
                <Tag color={(text === 'APPROVED') ? "green" : (text === 'REJECTED') ? "red" : "yellow"}>{text ? text : 'WAITING'}</Tag>
            </div>
        )
    },
    ];

    const changeRangeDate = (v) => {
        if (v) {
            setSelectedDateRange([moment(v[0]).format('YYYY-MM-DD'), moment(v[1]).format('YYYY-MM-DD')])
        } else {
            setSelectedDateRange([])
        }
    }

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/leave?division_id=' + selectedDivision + '&leave_id=' + selectedType.toString() + '&from=' + selectedDateRange[0] + '&to=' + selectedDateRange[1])
    }

    return (
        <Card className="content-container">
            <PageTitle title="Leave Report" breadcrumbs={[['Leave Report']]}></PageTitle>

            <div className="text-right mb-2">
                <Space>
                    <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                </Space>
            </div>
            {
                (loaded) ?
                    <DataTable size="small" columns={leaveColumn} dataSource={leaveData} bordered />
                    : <Spinner />
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}
            >
                <Form layout="vertical" onFinish={filterData}>
                    <Form.Item name="division_id" label="Division">
                        <Select
                            style={{ width: '100%' }}
                            onChange={
                                (v) => {
                                    setSelectedDivision(v)
                                }
                            }
                            placeholder="Select Division" allowClear>
                            {
                                divisions.map((el, key) => (
                                    <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="leave_type" label="Leave Type">
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Leave Category"
                            mode="multiple"
                            onChange={(v) => {
                                setSelectedCategory(v)
                            }} allowClear>
                            {
                                leaveTypes.map((el, key) => (
                                    <Option key={key} value={el.leave_id}>{el.description}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="date_range" label="Date Range">
                        <RangePicker onChange={(v) => changeRangeDate(v)} />
                    </Form.Item>
                    <Form.Item >
                        <div className="text-right">
                            <Button type="primary" htmlType="submit" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    );
}

export default Leave;
